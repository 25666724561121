function allConditionOfGoodsCommit(state, payload) {
  state.allConditionGoods = []

  for (let i = 0; i < payload.length; i++) {
    payload[i].index = i + 1
  }
  state.allConditionGoods = [...payload]
}
export default {
  allConditionOfGoodsCommit
}
