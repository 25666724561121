import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
  state: {
    allBasicCost: [],
    allCostCategories: [],
    allCostSubCategories: [],
    allGeneralInformation: [],
    loading: false
  },
  mutations,
  actions,
  getters
}
