import axios from 'axios'

async function getDriverZayavkas({ commit }) {
  await axios
    .get('drivers/zayavkas/')
    .then((res) => {
      commit('setDriverZayavkas', res.data)
    })
    .catch((err) => console.log(err))
}
async function getDriverZayavkasFilter({ commit }, payload) {
  console.log(payload)
  await axios
    .post('drivers/zayavkas/search/', payload)
    .then((res) => {
      console.log(res.data)
      commit('setDriverZayavkas', res.data)
    })
    .catch((err) => console.log(err))
}

export default {
  getDriverZayavkas,
  getDriverZayavkasFilter
}
