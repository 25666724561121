import axios from 'axios'

async function getClientPageContract({ commit }) {
  await axios
    .get('contracts/client_page/contracts/list/')
    .then((res) => {
      commit('setClientPageContract', res.data)
    })
    .catch((err) => console.log(err))
}
async function getClientPageContractDetails({ commit }, payload) {
  await axios
    .get('contracts/client_page/contracts/list/' + payload)
    .then((res) => {
      commit('setClientPageContractDetails', res.data)
    })
    .catch((err) => console.log(err))
}
async function getClientPageZayavka({ commit }) {
  await axios
    .get('zayavkas/list/client_page/')
    .then((res) => {
      commit('setClientPageZayavka', res.data)
    })
    .catch((err) => console.log(err))
}

async function getClientPageFactures({ commit }) {
  await axios
    .get('contracts/client_page/facturas/list/')
    .then((res) => {
      commit('setClientPageFactures', res.data)
    })
    .catch((err) => console.log(err))
}
async function getClientPageFacturesDetails({ commit }, payload) {
  await axios
    .get('contracts/client_page/facturas/list/' + payload)
    .then((res) => {
      commit('setClientPageFacturesDetails', res.data)
    })
    .catch((err) => console.log(err))
}
async function getClientPageFacturesDetailsTable({ commit }, payload) {
  await axios
    .get('contracts/client_page/facturas/factures_display/' + payload)
    .then((res) => {
      commit('setClientPageFacturesDetailsTable', res.data)
    })
    .catch((err) => console.log(err))
}

async function getClientPageFacturesCompletedWorks({ commit }, payload) {
  await axios
    .get('contracts/client_page/factures/done_jobs_display/' + payload)
    .then((res) => {
      commit('setClientPageFacturesCompletedWorks', res.data)
    })
    .catch((err) => console.log(err))
}

export default {
  getClientPageContract,
  getClientPageContractDetails,
  getClientPageZayavka,
  getClientPageFactures,
  getClientPageFacturesDetails,
  getClientPageFacturesDetailsTable,
  getClientPageFacturesCompletedWorks
}
