import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/uz-UZ'
import App from './App.vue'
import Vuelidate from 'vuelidate'
import router from './router'
import store from './core/services/store'
import VueCurrencyInput from 'vue-currency-input'
import ApiService from './core/services/api.service'
import { VERIFY_AUTH } from './core/services/store/auth.module'
import { RESET_LAYOUT_CONFIG } from '@/core/services/store/config.module'
import CreateAnnual from '@/view/layout/CreateAnnual'
Vue.component('create-annual', CreateAnnual)
import VueLazyload from 'vue-lazyload'

import vueDebounce from 'vue-debounce'
import VueSingleSelect from 'vue-single-select'
import numberToWordsRu from 'number-to-words-ru' // ES6
import { gsap, CSSPlugin } from 'gsap/all'
gsap.registerPlugin(CSSPlugin)
Vue.use(ElementUI, {
  locale
})
Vue.use(vueDebounce)
Vue.use(VueLazyload)
Vue.mixin({
  methods: {
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}-${month}-${year}`
    },
    parseDate(date) {
      if (!date) return null
      const [month, day, year] = date
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    }
  }
})
Vue.filter('formatDate', function(val) {
  if (val) {
    return val
      .split('-')
      .reverse()
      .join('-')
  }
})

Vue.filter('dataFilter', function(val) {
  if (val) {
    return val
      .split('-')
      .reverse()
      .join('-')
  } else return val
})
Vue.filter('dateFilter', function(val) {
  if (val) {
    return val
      .split('-')
      .reverse()
      .join('-')
  } else return val
})

Vue.filter('formatMoney', function numberWithSpaces(x) {
  return x && x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
})

// Global 3rd party plugins
import 'popper.js'
import 'tooltip.js'
import PerfectScrollbar from 'perfect-scrollbar'

window.PerfectScrollbar = PerfectScrollbar
import ClipboardJS from 'clipboard'
window.ClipboardJS = ClipboardJS
Vue.config.productionTip = false
// Vue 3rd party plugins
import i18n from './core/plugins/vue-i18n'
import vuetify from './core/plugins/vuetify'
import './core/plugins/portal-vue'
import './core/plugins/bootstrap-vue'
import './core/plugins/perfect-scrollbar'
import './core/plugins/highlight-js'
import './core/plugins/inline-svg'
import './core/plugins/apexcharts'
import './core/plugins/metronic'
import '@mdi/font/css/materialdesignicons.css'
import VueTheMask from 'vue-the-mask'
import money from 'v-money'

Vue.use(money)

import dayjs from 'dayjs'
import 'dayjs/locale/uz-latn' // load on demand
dayjs.locale('uz-latn') // use Spanish locale globally

Vue.prototype.$dayjs = dayjs
// store.$dayjs = dayjs

// API service init
ApiService.init()

router.beforeEach((to, from, next) => {
  store.dispatch(RESET_LAYOUT_CONFIG)
  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(next)

  // bugfix "navigate canceled error"
  next()
})

Vue.use(VueTheMask)
Vue.use(VueCurrencyInput)
Vue.use(Vuelidate)
Vue.use(VueSingleSelect)
Vue.use(numberToWordsRu)

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
