import axios from 'axios'
import Swal from 'sweetalert2'
// condition of goods
async function allConditionOfGoods({ commit }) {
  await axios
    .get('warehouse/product_condition/')
    .then((res) => {
      commit('allConditionOfGoodsCommit', res.data)
    })
    .catch((err) => console.log(err))
}
// create conditon of goods
function conditionOfGoodsCreate({ dispatch }, data) {
  return new Promise((resolve, reject) => {
    axios
      .post('warehouse/product_condition/', data)
      .then(() => {
        // router.push("/servicecard");
        Swal.fire({
          title: '',
          text: "Ma'lumotlar yuborildi!",
          icon: 'success',
          confirmButtonClass: 'btn btn-secondary'
        })
        dispatch('allConditionOfGoods')
        resolve()
      })
      .catch((err) => {
        Swal.fire({
          title: '',
          text: err.response.data.username,
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
        reject(err)
      })
  })
}
// update
function conditionOfGoodsUpdate({ dispatch }, payload) {
  axios
    .put('warehouse/product_condition/' + payload.id.id + '/', payload.data)
    .then(() => {
      // router.push("/servicecard");
      Swal.fire({
        title: '',
        text: "Ma'lumotlar yuborildi!",
        icon: 'success',
        confirmButtonClass: 'btn btn-secondary'
      })
      dispatch('allConditionOfGoods')
    })
    .catch((err) => {
      Swal.fire({
        title: '',
        text: err.response.data.username,
        icon: 'error',
        confirmButtonClass: 'btn btn-secondary'
      })
    })
}
// delete
function conditionOfGoodsDelete({ dispatch }, payload) {
  axios
    .delete('warehouse/product_condition/' + payload)
    .then(() => {
      // router.push("/servicecard");
      Swal.fire({
        title: '',
        text: "Ma'lumotlar o'chirildi!",
        icon: 'success',
        confirmButtonClass: 'btn btn-secondary'
      })
      dispatch('allConditionOfGoods')
    })
    .catch((err) => {
      Swal.fire({
        title: '',
        text: err.response.data.detail,
        icon: 'error',
        confirmButtonClass: 'btn btn-secondary'
      })
    })
}

export default {
  // get section
  allConditionOfGoods,
  // create section
  conditionOfGoodsCreate,
  // update section
  conditionOfGoodsUpdate,
  // delete section
  conditionOfGoodsDelete
}
