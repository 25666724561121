import axios from 'axios'

const actions = {
  getTruckNormativeList(_, params) {
    return axios.get('general/truck_normatives/list/', {
      params
    })
  }
}

export { actions }
