function allBasicCostCommit(state, payload) {
  state.allBasicCost = []
  for (let i = 0; i < payload.length; i++) {
    payload[i].index = i + 1
  }
  state.allBasicCost = [...payload]
}
function allCostCategoriesCommit(state, payload) {
  state.allCostCategories = []
  state.allCostCategories = [...payload]
  state.allCostCategories.forEach((element, index) => {
    element.index = index + 1
  })
}
function setIsLoading(state, payload) {
  state.isLoading = payload
}
function allCostSubCategoriesCommit(state, payload) {
  state.allCostSubCategories = []

  state.allCostSubCategories = [...payload]
  state.allCostSubCategories.forEach((element, index) => {
    element.index = index + 1
  })
}
function allGeneralInformationCommit(state, payload) {
  state.allGeneralInformation = []
  for (let i = 0; i < payload.length; i++) {
    payload[i].index = i + 1
  }

  state.allGeneralInformation = [...payload]
}
export default {
  setIsLoading,
  allCostSubCategoriesCommit,
  allGeneralInformationCommit,
  allCostCategoriesCommit,
  allBasicCostCommit
}
