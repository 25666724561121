<template>
  <v-app>
    <transition name="fade" appear mode="out-in">
      <router-view/>
    </transition>

    <!-- <pre class="fixed-pre">{{ $store.getters.filteredReport5 }}</pre> -->
  </v-app>
</template>
<style>
.v-application .fixed-header {
  height: 500px !important;
  overflow-y: scroll !important;
}

.fixed-header.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  background-color: #fff;
  position: sticky !important;
  top: -1px !important;
}

#app .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
#app .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
#app .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
}

#app .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  vertical-align: baseline;
}

.row-pointer tbody tr :hover {
  cursor: pointer;
}

.v-data-table /deep/ .v-data-table__wrapper tbody tr.v-data-table__selected {
  background-color: #e8e8e8 !important;
}

.container {
  max-width: 100% !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 1s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

.fade-enter-to, .fade-leave /* .fade-leave-active below version 2.1.8 */
{
  opacity: 1;
  /* transform: scale(1); */
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}

.v-data-table >>> .elevation-1 >>> .theme--light {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.v-application /deep/ .elevation-1 {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  /* font-size: 1rem !important; */
  color: #000 !important;
  font-weight: 500 !important;
}

table {
  border-collapse: collapse;
  width: 100%;
}

.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  /* font-size: 1rem !important; */
  color: #000 !important;
  height: 60px !important;
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  min-height: 300px !important;
  white-space: nowrap !important;
}
</style>
<style lang="scss">
// 3rd party plugins css
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import '~socicon/css/socicon.css';
@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~line-awesome/dist/line-awesome/css/line-awesome.css';
@import 'assets/plugins/flaticon/flaticon.css';
@import 'assets/plugins/flaticon2/flaticon.css';
@import 'assets/plugins/keenthemes-icons/font/ki.css';

// Main demo style scss
@import 'assets/sass/style.vue';

// Check documentation for RTL css
/*@import "assets/css/style.vue.rtl";*/
.custom-select {
  box-shadow: none;
}

// Tab style
.v-application ul,
.v-application ol {
  padding-left: 0 !important;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}

.v-application .wizard-step {
  background-color: #fff !important;
}

.v-tabs-bar.primary .v-tab {
  color: #3699ff;
}

.theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: #b5b5c3;
}

.wizard-step {
  padding: 20px 10px;
}

.v-tab--active.v-tab:not(:focus)::before {
  border-radius: 50px;
}

.basil {
  background-color: #fffbe6 !important;
}

.basil--text {
  color: #356859 !important;
}

.theme--light
.v-text-field--outlined:not(.v-input--is-focused).v-input--is-disabled
> .v-input__control
> .v-input__slot
fieldset {
  color: #000 !important;
}

.theme--light .v-label--is-disabled {
  color: #000 !important;
}

.theme--light .v-input--is-disabled input {
  color: #000 !important;
}

.v-data-table tbody tr.v-data-table__expanded__content {
  box-shadow: #333 !important;
}
</style>

<script>
import {OVERRIDE_LAYOUT_CONFIG} from '@/core/services/store/config.module'
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module'

// import { LOGIN } from "@/core/services/store/auth.module";
export default {
  name: 'MetronicVue',
  // created() {
  //   // this.$store.dispatch(SET_BREADCRUMB, [])
  //   this.$store.dispatch('test_uchun')
  // },
  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG)

    // this.$nextTick(() => {
    //   setInterval(() => {
    //     this.$router.push({
    //       path:
    //         '/' + (this.$route.name == 'trucklists' ? 'corcards' : 'trucklists')
    //     })

    //     // clearInterval(timer)
    //   }, 30000)
    // })
  },

  updated() {
    this.$store.dispatch(SET_BREADCRUMB, [])
  }
}
</script>
