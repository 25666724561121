import axios from 'axios'
function percentageToDigit(option, val, index) {
  const item_percent = (option && option.data && option.data[index]) || 0

  const unsignedPercent = item_percent < 0 ? item_percent * -1 : item_percent

  const valPercent = (val * parseFloat(unsignedPercent)) / 100.0
  const valPercentNegative = valPercent > 0 ? valPercent * -1 : valPercent

  return valPercentNegative
}

function getFinancialData({ commit }, params) {
  return axios
    .get('dashboard/financial_datas/', {
      params
    })
    .then((res) => {
      commit('SET_FINANCIAL_DATA', (res && res.data && res.data.results) || [])
      return res || {}
    })
    .catch((err) => {
      console.error(err)
      return []
    })
}

function getOrdersWeightCounts({ commit }, params) {
  return axios
    .get('dashboard/orders_weight_counts/', {
      params
    })
    .then((res) => {
      commit(
        'SET_ORDERS_WEIGHT_COUNTS',
        (res && res.data && res.data.results) || []
      )
      return res || {}
    })
    .catch((err) => {
      console.error(err)
      return []
    })
}

function getEmptyWayRatio({ commit }, params) {
  return axios
    .get('dashboard/empty_way_ratio/', {
      params
    })
    .then((res) => {
      const t =
        ((res && res.data && res.data.results) || []).slice().sort((a) => {
          if (a.name && a.name.indexOf('client') != -1) return -1

          return 0
        }) || []

      const real_distance =
        t.find((item) => item.name == 'real' && item.type == 'column') || {}

      const ratio =
        t.find((item) => item.name == 'ratio' && item.type == 'line') || {}

      // console.log('real_distance', real_distance)
      // console.log('ratio', ratio)

      const ratio_percentage = {
        data: ((ratio || {}).data || []).map((val, index) =>
          percentageToDigit(real_distance, val, index)
        ),
        name: 'ratio_percent',
        type: 'line'
      }

      commit('SET_EMPTY_WAY_RATIO', [...t, ratio_percentage])
      return res || {}
    })
    .catch((err) => {
      console.error(err)
      return []
    })
}

function getOrdersEfficiency({ commit }, params) {
  return axios
    .get('dashboard/orders_efficiency/', {
      params
    })
    .then((res) => {
      const t = (res && res.data && res.data.results) || []
      const single_reys_efficiency_props =
        t.find(
          (item) => item.name == 'single_reys_efficiency' && item.type == 'line'
        ) || {}

      const mean_expenditure =
        t.find(
          (item) => item.name == 'mean_expenditure' && item.type == 'column'
        ) || {}

      const single_reys_efficiency_percent = {
        data: ((mean_expenditure || {}).data || []).map((val, index) =>
          percentageToDigit(single_reys_efficiency_props, val, index)
        ),
        name: 'mean_expenditure_percent_amount',
        type: 'line'
      }

      commit('SET_ORDERS_EFFICIENCY', [...t, single_reys_efficiency_percent])
      return res || {}
    })
    .catch((err) => {
      console.error(err)
      return []
    })
}

function getWeightEfficiency({ commit }, params) {
  return axios
    .get('dashboard/weight_efficiency/', {
      params
    })
    .then((res) => {
      const t = (res && res.data && res.data.results) || []
      const single_reys_efficiency_props =
        t.find(
          (item) => item.name == 'single_reys_efficiency' && item.type == 'line'
        ) || {}

      const mean_expenditure =
        t.find(
          (item) => item.name == 'mean_expenditure' && item.type == 'column'
        ) || {}

      const single_reys_efficiency_percent = {
        data: ((mean_expenditure || {}).data || []).map((val, index) =>
          percentageToDigit(single_reys_efficiency_props, val, index)
        ),
        name: 'mean_expenditure_percent_amount',
        type: 'line'
      }

      commit('SET_WEIGHT_EFFICIENCY', [...t, single_reys_efficiency_percent])
      return res || {}
      // commit(
      //   'SET_WEIGHT_EFFICIENCY',
      //   (res && res.data && res.data.results) || []
      // )
    })
    .catch((err) => {
      console.error(err)
      return []
    })
}

function getClientsDebetCredit({ commit }, params) {
  return axios
    .get('dashboard/clients_debet_credit/', {
      params
    })
    .then((res) => {
      commit(
        'SET_CLIENTS_DEBET_CREDIT',
        (res && res.data && res.data.results) || []
      )
      return res || {}
    })
    .catch((err) => {
      console.error(err)
      return []
    })
}

function getProvidersDebetCredit({ commit }, params) {
  return axios
    .get('dashboard/providers_debet_credit/', {
      params
    })
    .then((res) => {
      commit(
        'SET_PROVIDERS_DEBET_CREDIT',
        (res && res.data && res.data.results) || []
      )
      return res || {}
    })
    .catch((err) => {
      console.error(err)
      return []
    })
}

function getTopDrivers({ commit }, params) {
  return axios
    .get('dashboard/top_drivers/', {
      params
    })
    .then((res) => {
      commit('SET_TOP_DRIVERS', (res && res.data) || [])
      return res || {}
    })
    .catch((err) => {
      console.error(err)
      return []
    })
}

function getTopTrucks({ commit }, params) {
  return axios
    .get('dashboard/top_trucks/', {
      params
    })
    .then((res) => {
      commit('SET_TOP_TRUCKS', (res && res.data) || [])
      return res || {}
    })
    .catch((err) => {
      console.error(err)
      return []
    })
}

function getTopLoaners({ commit }, params) {
  return axios
    .get('dashboard/top_5_loaners/', {
      params
    })
    .then((res) => {
      commit('SET_TOP_LOANERS', (res && res.data && res.data.results) || [])
      return res || {}
    })
    .catch((err) => {
      console.error(err)
      return []
    })
}

export default {
  getFinancialData,
  getOrdersWeightCounts,
  getEmptyWayRatio,
  getOrdersEfficiency,
  getWeightEfficiency,
  getClientsDebetCredit,
  getProvidersDebetCredit,
  getTopDrivers,
  getTopTrucks,
  getTopLoaners
}
