function SET_REPORTS(state, reports) {
  state.reportsData = reports
}

function SET_LOADER(state, loading) {
  state.table_loading = loading
}

function SET_QUERY_PARAM(state, param) {
  state.queryParams[param.name] = param.value
}

function SET_TOTAL(state, total) {
  state.total = total
}

function FILTER_DIALOG_TOGGLE(state, toggle) {
  state.filterDialog = toggle
}

function SET_FILTER(state, { filter, num }) {
  state['filters' + num] = filter
}

export default {
  SET_REPORTS,
  SET_LOADER,
  SET_QUERY_PARAM,
  SET_TOTAL,
  FILTER_DIALOG_TOGGLE,
  SET_FILTER
}
