import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import JwtService from '@/core/services/jwt.service'
import router from '@/router'
import store from './store'
import Swal from 'sweetalert2'

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
    let isRefreshing = false
    let subscribers = []

    axios.interceptors.response.use(
      (response) => {
        return response
      },
      (err) => {
        try {
          const {
            config,
            response: { status, data }
          } = err
          const originalRequest = config
          if (originalRequest.url.includes('token')) {
            // console.log(-1)
            // Swal.fire({
            //   icon: 'error',
            //   title: 'Xato',
            //   text: "Login yoki parol noto'g'ri",
            //   showClass: {
            //     popup: 'swal2-noanimation',
            //     backdrop: 'swal2-noanimation'
            //   },
            //   showConfirmButton: true
            // })
            if (router.currentRoute.path !== '/login')
              router.push({ name: 'login' })
            return Promise.reject(err)
          }
          if (status == 401 && data.code === 'user_not_found') {
            // console.log(-2)
            if (router.currentRoute.path !== '/login')
              router.push({ name: 'login' })
            return Promise.reject(err)
          }
          if (
            (status == 403 || status == 401) &&
            data.code === 'token_not_valid'
          ) {
            // console.log(-3, isRefreshing)

            if (!isRefreshing) {
              // console.log('!isRefreshing')
              isRefreshing = true
              store
                .dispatch('refreshToken')
                .then((res) => {
                  const resStatus = (res && res.status) || null
                  // console.log('resStatus ', resStatus)

                  if ([200, 201, 204].includes(resStatus)) {
                    isRefreshing = false
                    onRefreshed()
                  }
                  subscribers = []
                })
                .catch((error) => {
                  // console.warn('refresh token is out of date', error)
                  isRefreshing = false
                  store.commit('logOut')
                  return Promise.reject(error)
                })
            }
            if (router.currentRoute.path !== '/login' || !isRefreshing) {
              const requestSubscribers = new Promise((resolve) => {
                subscribeTokenRefresh(() => {
                  originalRequest.headers.Authorization =
                    'Bearer ' + JwtService.getToken()
                  resolve(axios(originalRequest))
                })
              })
              // onRefreshed()
              return requestSubscribers
            }

            return Promise.reject(err)
          }
          if (status !== 401 && status !== 403 && status !== 500) {
            // console.log(-4)
            Swal.fire({
              icon: 'error',
              title: 'Muammo',
              text: data.detail,
              showClass: {
                popup: 'swal2-noanimation',
                backdrop: 'swal2-noanimation'
              },
              showConfirmButton: true
            })
            return Promise.reject(err)
          }
          if (status == 500) {
            // console.log(-5)
            Swal.fire({
              title: 'Xato',
              text: 'Server bilan xatolik yuz berdi',
              icon: 'error'
            })
            return Promise.reject(err)
          }
          // if (data.detail === 'Authentication credentials were not provided.') {
          //   return Promise.reject('Authentication credentials were not provided.')
          // }
          if (data.detail === 'Token is invalid or expired') {
            isRefreshing = false
            // console.log(-6)
            if (!originalRequest.url.includes('login'))
              router.push({ name: 'login' })
            return Promise.reject(false)
          }
          if (originalRequest.url.includes('login')) {
            // console.log(-7, 'is login req')
            isRefreshing = false
            return Promise.reject(err)
          }
        } catch (error) {
          console.log('-0-', error)
          isRefreshing = false
          return error
          //
        }
        return err
      }
    )

    function subscribeTokenRefresh(cb) {
      subscribers.push(cb)
    }

    function onRefreshed() {
      subscribers.map((cb) => {
        cb()
      })
    }

    subscribers = []
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${JwtService.getToken()}`
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`)
    })
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = '') {
    return Vue.axios.get(`${resource}/${slug}`).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`)
    })
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params)
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params)
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params)
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`)
    })
  }
}

export default ApiService
