import mutations from './mutationMap'
import actions from './actionMap'

export default {
  state: {
    truckLocation: {}
  },
  mutations,
  actions
}
