import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
  state: {
    dashboardTruckListValue:'',
    allCountragentStartingPoint: {},
    allProviderStartingPoint: {},
    allStartingPoint: {},
    ZayavkaDriversList: [],
    mainAssetsRemarks: [],
    filterData: {},
    allUsers: {},
    allFacturas: {},
    allTruckBrands: [],
    totalSumm: 0,
    allfacturaTotalNum: 0,
    allfacturaTotalSumm: 0,
    dailyTotalSumm: 0,
    clientDetails: {},
    driverDetails: {},
    companyGeneralInfo: {},
    CurrentClientDetails: {},
    getCurrProviderDetails: [],
    staffEmployedList: [],
    positions: {},
    advertPermitsDetails: {},
    completedWorks: [],
    paymentStatements: {},
    payrolListDetails: {},
    staffEmployedListForMonth: [],
    financeBalance: {},
    allDriversRevenues: [],
    expenditureDashboard: [],
    transferSalaryList: [],
    insuranceTruckDetails: {},
    providerContractById: [],
    mainExSubCategorySpecialTotal: [],
    payrolList: {},
    gasEquipsDetails: {},
    loadingState: '',
    notLinkedTotruck: [],
    mainAssetDocDetails: [],
    clientStatus: [],
    mainAssets: {
      id: 13,
      get_doc_total: {
        total: 2000.0,
        nds_summa: 200.0,
        total_withnds_summa: 2200.0
      },
      details: [
        {
          id: 10,
          amount: 10.0,
          price: 200.0,
          total: 2000.0,
          nds: 10.0,
          nds_per_item: 20.0,
          nds_summa: 200.0,
          total_withnds_summa: 2200.0,
          import_group: {
            id: 1,
            name: 'Tovarlar',
            code: '0001'
          },
          import_category: {
            id: 8,
            name: 'Shkaf',
            code: '0011',
            group: 1,
            measurement: 1
          },
          product_condition: {
            id: 3,
            name: 'Oldin Foydalanilgan',
            code: '0002'
          }
        }
      ],
      contragent_name: 'Kontragent Company Mchj',
      contragent_contract_number: '322',
      oper_date: '2020-08-11',
      reg_number: '222',
      comment: 'comment',
      payment_source: 1,
      contragent: 1,
      contragent_contract: 2,
      responsible: 2,
      main_assets: null
    },
    totalSum: {},
    mainAssetsTaken: {},
    allProvidersContract: {},
    allProvidersWithoutPg: [],
    storedproductsForExport: [],
    productDetails: [],
    goodsServicesImport: {},
    productCondition: [],
    mainExpenditures: [],
    facturaDisplay: {},
    regions: [],
    tillDateList: [],
    mainassetsNotTaken: {},
    annualRemark: [],
    techMonitorDetails: {},
    allSkladList: [],
    countragentKontracts: {},
    kontragentContractAktSverkaID: {},
    categoryGroupNames: [],
    counteragentContracts: [],
    providerContractDeliveredZayavkas: [],
    countragentKontractsPayments: [],
    countragentKontractFactura: [],
    countragentKontractsSverka: {},
    kontragentContractAktSverka: {},
    countragentKontractsPayment: [],
    kontragentsContractsList: {},
    skladList: {},
    kontragetnsWithoutPg: [],
    CountZayavka: {},
    productsList: {},
    providerContract: [],
    categoryGroupData: [],
    measurements: [],
    clientPerPage: [],
    getmainassetsall: [],
    kontragentsList: {},
    mainassetsDetails: {},
    perpage: {},
    countragentKontractFacturas: [],
    i: {},
    CorpCardOperDetails: {},
    providerContractDetail: {},
    corpCardsOper: {},
    providerDetails: {
      id: '',
      providerphone: [],
      provider_bank_account: [],
      provider_contactpersonal: [],
      provider_director: [],
      status: {
        id: '',
        status: ''
      },
      ownership: '',
      adress_region: {
        id: '',
        name: '',
        oblast: ''
      },
      juridical_type: '',
      full_name: '',
      inn: '',
      oked: '',
      yur_address: '',
      pochta_address: '',
      passport_number: '',
      code_nds: '',
      photo: '',
      comments: '',
      user: '',
      adress_oblast: ''
    },
    licenceTypes: [],
    standartContAppen: {},
    provodkaList: {},
    balansAccountList: [],
    expenditureIncomeTotal: [],
    Cities: [],
    generalPaymentTypes: [],
    truckListId: {
      id: '',
      driver_name: {
        id: '',
        driver_phone: [
          {
            id: '',
            phone_number: '',
            driver: ''
          }
        ],
        first: '',
        last: '',
        date_birth: null,
        inn: null,
        passport_num: null,
        passport_address: null,
        user: 270
      },
      truck_type_description: {
        id: 7,
        brand: {
          id: 13,
          name: 'damas'
        },
        model: 'VAN',
        capacity_kg: 400.0,
        capacity_m3: 4.0
      },
      trucktechmonitor_set: [],
      truckgasequipmentpermit_set: [],
      truckadvertpermit_set: [],
      trucklisence_set: [
        {
          id: 16,
          reg_number: 'r-111',
          given: '2020-08-09',
          start_date: '2020-08-22',
          expires: '2020-08-21',
          given_by: 'ggggg',
          truck: 22
        },
        {
          id: 2,
          reg_number: 'r-11',
          given: '2020-08-03',
          start_date: '2020-08-27',
          expires: '2020-08-22',
          given_by: 'bgsss',
          truck: 22
        }
      ],
      truckinsurance_set: [],
      truckdrivepermit_set: [
        {
          id: 5,
          expires: '2020-08-09',
          truck: 22,
          driver: 18
        },
        {
          id: 6,
          expires: '2020-08-31',
          truck: 22,
          driver: 22
        },
        {
          id: 8,
          expires: '2020-08-10',
          truck: 22,
          driver: 19
        },
        {
          id: 11,
          expires: '2020-08-10',
          truck: 22,
          driver: 22
        },
        {
          id: 12,
          expires: '2020-08-19',
          truck: 22,
          driver: 22
        },
        {
          id: 17,
          expires: '2020-09-04',
          truck: 22,
          driver: 29
        },
        {
          id: 18,
          expires: '2020-08-25',
          truck: 22,
          driver: 24
        }
      ],
      reg_number_region: '',
      reg_number: '',
      kuzov_number: '',
      kuzov_type: '',
      bought_date: '',
      dvigatel_number: '',
      status: '',
      is_normal_truck_documents: '',
      driver: '',
      truck_type: '',
      main_assets: ''
    },
    setTruckListId: {
      id: 1,
      zayavka_owner: 'Sardor ALIYEV',
      direction_truck: {
        id: 1,
        truck_type: {
          id: 2,
          brand: {
            id: 2,
            name: 'ISUZU'
          },
          model: 'is-x2',
          capacity_kg: 11000.0,
          capacity_m3: 21.0
        },
        price: 20000000.0,
        nds: true,
        nds_amout: 3000000.0,
        price_with_nds: 23000000.0,
        direction: {
          id: 1,
          appendix: {
            id: 1,
            name: 'standart',
            standart: true
          },
          citydestination: [
            {
              id: 2,
              name: 'Toshkent'
            },
            {
              id: 5,
              name: 'Margilon'
            }
          ]
        }
      },
      zayavka_status: 'OTB',
      payment_type: 'CONT',
      deliver: 'BTS',
      price: 12.0,
      nds_summa: 1.0,
      price_with_nds: 13.0,
      start_date: '2020-07-06T06:45:22Z',
      end_date: '2020-07-06T06:45:22Z',
      post_kg: 1100.0,
      post_m3: 0.0,
      delivered_date: '2020-07-06T06:46:12Z',
      created_at: '2020-07-06T06:46:15.816656Z',
      updated_at: '2020-07-06T06:46:15.816666Z',
      contract: 6,
      truck: 1,
      created_by: 2,
      modified_by: 2
    },
    allOwners: [],
    allregions: [],
    ownership: [],
    allStaff: [],
    allStaffIllnesses: {},
    staffVacations: {},
    regionByOblast: [],
    allNonEmployedStaff: [],
    allMeasurements: [],
    allTransferListCash: [],
    allNomenklature: [],
    detailsSalaryPaymentStatements: {},
    nomenklaturaGroup: [],
    allNomenklatureAll: [],
    staffList: [],
    truckListallstore: {},
    allstafflist: {},
    allDriversPermits: {},
    driverPermitsDetails: {},
    allActRecon: {},
    allLicenses: {},
    ActRecontDetails: {},
    allBrands: [],
    providerContractAktSverka: {},
    allZayavka: [],
    typeOfAction: [],
    licensesById: {},
    providerContractAktSverkaID: {},
    allDriversById: {},
    ProviderContractPayment: [],
    insuarences: {},
    staffPositions: [],
    allOblast: [],
    allPersonal: {},
    staffDepartments: [],
    transferDetails: {},
    advertPermits: {},
    gasEquips: {},
    financeExpenditureList: {},
    techMonitor: {},
    allTransferList: [],
    serviceCash: {},
    zayavkaById: {},
    alltnn: {},
    setZayavById: [],
    testShow: true,
    alltnnid: {},
    currCompJob: [],
    allclientcontracts: [],
    allContracts: {},
    corpDetails: {},
    findSellMainAsset: [],
    mainProfitSubCategorySpecial: [],
    mainAssetRealizationFacturas: [],
    paymentOrderTypes: [],
    realizations: {},
    allTransactionsDaily: [],
    transactionsDailyDetails: {},
    deliveredOutfacture: [],
    servicePaymentSource: [],
    paymentSource: [],
    allDrivers: [],
    typeStateTransfers: [],
    allTruckList: [],
    facturaDetail: [],
    zayavkaModalId: '',
    operationsBycontract: [],
    allTruckTypes: [],
    allProviders: {},
    factureBycontract: [],
    allClientsName: [],
    driverbyid: [],
    providerContractZayavka: [],
    isLoading: false,
    allAppen: {},
    topClientDashboard: [],
    incomeDashboard: [],
    financePlanIncomeYearly: [],
    infoDriverDashboard: [],
    bankAccountLink: {},
    allFacturasByContract: [],
    allContractById: [],
    mainProfitSubCategorySpecialTotal: [],
    doneWorksCount: {},
    truckDetails: [],
    mainProfitSorted: [],
    allMfoBank: [],
    infoForDashboard: {},
    mainAssetTakenDetails: [],
    zayavkaContractDetail: {},
    ContractById: {},
    mainProfitCategory: [],
    mainProfitSubCategory: [],
    mainProfit: [],
    allMarshrut: [],
    incomesFromService: [],
    newMarshrut: [],
    oneClientContract: [],
    doneWorks: {},
    nds: '',
    nameOfContracts: {},
    dailyAmortizationUnCalc: [],
    dailyAmortizationMainAssets: [],
    incomeListDetails: {},
    // finance
    AllCorCards: [],
    incomeList: {},
    AllMainExp: [],
    corpCardsOperList: [],
    corpCardsDetails: {},
    operByCard: [],
    providerSearchResult: [],
    // adham's adds
    corpCardList: {}
  },
  mutations,
  actions,
  getters
}
