/* eslint-disable no-extra-boolean-cast */
function setAllUsers(state, payload) {
  state.allUsers = payload
  state.allUsers.results.forEach((element) => {
    if (element.status == null) {
      element.tatus = "Ma'lumot berilmagan"
    }
    if (element.juridical_type == 'YU') {
      element.juridical_type = 'Yuridik shaxs'
    } else if (element.juridical_type == 'JI') {
      element.juridical_type = 'Jismoniy shaxs'
    }

    if (element.status.status == 'Standart') {
      element.status_class = 'primary'
    } else if (element.status.status == 'Gold') {
      element.status_class = 'warning'
    } else if (element.status.status == 'Vip') {
      element.status_class = 'success'
    }
  })
}
function setMainAssetsDocDetails(state, payload) {
  state.mainAssetDocDetails = payload
}
function setMainAssetsRemarks(state, payload) {
  state.mainAssetsRemarks = payload
}
function truckListallCommit(state, payload) {
  state.truckListallstore = {}

  state.getmainassetsall.forEach((element) => {
    if (element.id == payload.main_assets) {
      payload.mainAssets_name =
        'Inventar nomi:' +
        element.inventar_number +
        ', Nomi:' +
        element.name +
        ' ' +
        'Summa:' +
        element.total_amount
    }
    if (payload.main_assets == null) {
      payload.mainAssets_name = 'Kiritilmagan'
    }
  })
  if (payload.driver !== null) {
    payload.driverfullname =
      payload.driver_name.staff_description.staffpersonal_description
        .first_name +
      ' ' +
      payload.driver_name.staff_description.staffpersonal_description.last_name
    // state.allDrivers.forEach(element => {
    //   for (let i = 0; i < payload.truckdrivepermit_set.length; i++) {
    //     if (payload.truckdrivepermit_set[i].driver == element.id) {
    //       payload.truckdrivepermit_set[i].driver_name =
    //         element.first + " " + element.last;
    //     }
    //   }
    // });
  }

  if (payload.status == 'PUS') {
    payload.status = 'Bo’sh'
  }
  if (payload.is_normal_truck_documents == true) {
    payload.is_normal_truck_documents = 'Hujjatlar Joyida'
  } else if (payload.is_normal_truck_documents == false) {
    payload.is_normal_truck_documents = 'Hujjatlar Joyida emas'
  }
  payload.truck_name =
    payload.truck_type_description.brand.name +
    ' ' +
    payload.truck_type_description.model +
    ' ' +
    payload.truck_type_description.capacity_kg +
    ' ' +
    payload.truck_type_description.capacity_m3
  state.truckListallstore = payload
}
function providerContractZayavkaCommit(state, payload) {
  state.providerContractZayavka = []
  for (let i = 0; i < payload.length; i++) {
    payload[i].index = i + 1
    if (payload[i].start_date !== null || payload[i].end_date !== null) {
      payload[i].start_date = payload[i].start_date.slice(0, 10)
      payload[i].end_date = payload[i].end_date.slice(0, 10)
    }
    if (payload[i].zayavka_status == 'OTB') {
      payload[i].zayavka_status = 'Buyurtmachida'
    }
    if (payload[i].deliver == 'POS') {
      payload[i].deliver = 'Postavshik'
    }
  }

  // for (let prop in payload) {
  //   for (let prop2 in payload[prop]) {
  //     if (payload[prop][prop2] > 0) {
  //       payload[prop][prop2] = payload[prop][prop2].toLocaleString('es-US')
  //     }
  //   }
  // }
  state.providerContractZayavka = payload
}
function getmainassetsCommit(state, payload) {
  payload.forEach((element) => {
    element.starting_amount_new = parseInt(
      element.starting_amount
    ).toLocaleString('es-US')
    element.daily_amortization_amount =
      element.daily_amortization_amount.toLocaleString('es-US')
    element.amortization_all = element.amortization_all.toLocaleString('es-US')
    element.remain_amount_overall =
      element.remain_amount_overall.toLocaleString('es-US')
    element.amortization_for_report_period =
      element.amortization_for_report_period.toLocaleString('es-US')
    element.total_amount = element.total_amount.toLocaleString('es-US')
    element.report_start_date_remain =
      element.report_start_date_remain.toLocaleString('es-US')
    element.mainAssets_name =
      'Inventar nomi:' +
      element.inventar_number +
      ', Nomi:' +
      element.name +
      ' ' +
      'Summa:' +
      element.total_amount
  })
  state.getmainassetsall = payload
  for (let i = 0; i < state.getmainassetsall.length; i++) {
    state.getmainassetsall[i].index = i + 1
  }
}
function mainassetsDetailsCommit(state, payload) {
  state.mainassetsDetails = {}
  state.mainassetsDetails = payload
}
function setCompletedWorks(state, payload) {
  state.completedWorks = []
  state.completedWorks = [...payload]
}
function mainAssetsCommit(state, payload) {
  state.mainAssets = {}
  state.mainAssets = payload

  state.mainAssets.results.forEach((element) => {
    for (let i = 0; i < state.paymentSource.length; i++) {
      if (element.payment_source == null) {
        element.payment_source_name = 'Kiritilmagan'
      } else if (state.paymentSource[i] == element.payment_source) {
        element.payment_source_name = state.paymentSource[i].name
      }
    }
    for (let prop in element.get_doc_total) {
      if (element.get_doc_total[prop] !== null) {
        if (element.get_doc_total[prop] >= 0) {
          element.get_doc_total[prop] =
            element.get_doc_total[prop].toLocaleString('es-US')
        }
      }
    }
    for (let prop2 in element.details) {
      if (element.details[prop2] !== null) {
        for (let prop3 in element.details[prop2])
          if (Number.isInteger(element.details[prop2][prop3])) {
            element.details[prop2][prop3] =
              element.details[prop2][prop3].toLocaleString('es-US')
          }
      }
    }
  })
}
function staffListCommit(state, payload) {
  state.allstafflist = payload
}
function setStaffEmployedList(state, payload) {
  payload.forEach((x, index) => {
    x.index = ++index
  })
  state.staffEmployedList = payload
}
function mainAssetsCommitTaken(state, payload) {
  state.mainAssetsTaken = {}
  state.mainAssetsTaken = payload
  state.mainAssetsTaken.results.forEach((element) => {
    for (let i = 0; i < state.paymentSource.length; i++) {
      if (element.payment_source == null) {
        element.payment_source_name = 'Kiritilmagan'
      } else if (state.paymentSource[i].id == element.payment_source) {
        element.payment_source_name = state.paymentSource[i].name
      }
    }
    if (state.allstafflist.results !== undefined) {
      for (let j = 0; j < state.allstafflist.results.length; j++) {
        if (state.allstafflist.results[j].id == element.responsible) {
          element.responsible_name =
            state.allstafflist.results[j].first_name +
            ' ' +
            state.allstafflist.results[j].last_name
        }
      }
    }
    for (let prop in element.get_doc_total) {
      if (element.get_doc_total[prop] !== null) {
        if (element.get_doc_total[prop] >= 0) {
          element.get_doc_total[prop] =
            element.get_doc_total[prop].toLocaleString('es-US')
        }
      }
    }
    for (let prop2 in element.details) {
      if (element.details[prop2] !== null) {
        for (let prop3 in element.details[prop2])
          if (Number.isInteger(element.details[prop2][prop3])) {
            element.details[prop2][prop3] =
              element.details[prop2][prop3].toLocaleString('es-US')
          }
      }
    }
  })
}
function setOneClientDetails(state, payload) {
  state.clientDetails = {}
  state.staffPositions.forEach((element) => {
    if (payload.contact_personal_position == element.id) {
      payload.contact_personal_position_name = element.name
    }
  })
  state.clientDetails = payload
}
function ShowTest(state, payload) {
  if (state.setOneClientDetails !== '') {
    state.testShow = payload
  }
}
function setClientPerPage(state, payload) {
  state.clientPerPage = []

  payload.forEach((element) => {
    if (element.status == null) {
      element.status = "Ma'lumot berilmagan"
    } else if (element.juridical_type == 'YU') {
      element.juridical_type = 'Yuridik shaxs'
    } else if (element.juridical_type == 'JI') {
      element.juridical_type = 'Jismoniy shaxs'
    }
    if (element.status.status == 'Standart') {
      element.status_class = 'primary'
    } else if (element.status.status == 'Gold') {
      element.status_class = 'warning'
    } else if (element.status.status == 'Vip') {
      element.status_class = 'success'
    }
    state.clientPerPage.push(element)
  })
}
function setPerPage(state, payload) {
  state.perpage = {}
  state.perpage = payload
  state.clientPerPage = []
  payload.results.forEach((element) => {
    if (element.status == null) {
      element.status = "Ma'lumot berilmagan"
    } else if (element.juridical_type == 'YU') {
      element.juridical_type = 'Yuridik shaxs'
    } else if (element.juridical_type == 'JI') {
      element.juridical_type = 'Jismoniy shaxs'
    }
    if (element.status.status == 'Standart') {
      element.status_class = 'primary'
    } else if (element.status.status == 'Gold') {
      element.status_class = 'warning'
    } else if (element.status.status == 'Vip') {
      element.status_class = 'success'
    }
    state.clientPerPage.push(element)
  })
}
function setCorpCardsOper(state, payload) {
  state.corpCardsOper = payload
}
function setPayrolList(state, payload) {
  if (payload.results !== undefined) {
    payload.results.forEach((x, index) => {
      x.index = index
    })
  }
  state.payrolList = payload
}
function setCorpCardsOperList(state, payload) {
  state.corpCardsOperList = {}
  state.corpCardsOperList = payload
  state.corpCardsOperList.results.forEach((element) => {
    if (element.operation_type == 'DEPO') {
      element.operation_type_name = 'Chiqim'
    } else if (element.operation_type == 'WITH') {
      element.operation_type_name = 'Kirim'
    }
    element.amount = element.amount.toLocaleString('es-US')
    element.card_number = element.card_number.replace(/(.{4})/g, '$1 ').trim()
  })
}
function setallCorpCardDetal(state, payload) {
  state.CorpCardOperDetails = {}
  if (payload.operation_type == 'DEPO') {
    payload.operation_type = 'Kirim'
  } else if (payload.operation_type == 'WITH') {
    payload.operation_type = 'Chiqish'
    // state.CorpCardOperDetails = payload.operation_type_color = "text-danger";
  }

  state.CorpCardOperDetails = payload
}
function setAllGroups(state, payload) {
  state.allGroups = payload
}
function setStandartContAppen(state, payload) {
  state.standartContAppen = payload
}

function setAllClientsName(state, payload) {
  state.allClientsName = {}
  payload.find((element) => {
    if (element.inn == null || element.inn == '') {
      element.inn = 'Kiritilmagan'
    }
    element.full_name1 = element.full_name + '  ' + 'INN:' + element.inn
  })
  state.allClientsName = payload
}

function setAllBrands(state, payload) {
  state.allBrands = payload
}

function setAllTrcukBrands(state, payload) {
  state.allTruckBrands = payload
}
function setAllProvidersWithoutPg(state, payload) {
  state.allProvidersWithoutPg = payload
}
function setProviderContract(state, payload) {
  state.providerContract = payload
}
function ProviderContractPaymentCommit(state, payload) {
  state.ProviderContractPayment = payload
}

function setKontragentsContractsList(state, payload) {
  state.kontragentsContractsList = {}

  payload.results.forEach((element) => {
    for (let i = 0; i < state.kontragetnsWithoutPg.length; i++) {
      if (state.kontragetnsWithoutPg[i].id == element.countragent) {
        element.countragent_name = state.kontragetnsWithoutPg[i].full_name
      }
    }
  })

  state.kontragentsContractsList = payload
}
function setPositions(state, payload) {
  state.positions = payload
}

function setClientStatus(state, payload) {
  state.clientStatus = payload
}

function setRegions(state, payload) {
  state.regions = payload
}

function setAllStaff(state, payload) {
  payload.forEach((element) => {
    element.full_name =
      element.staffpersonal_description.first_name +
      ' ' +
      element.staffpersonal_description.last_name
  })
  state.allStaff = payload
}
function setAllOwner(state, payload) {
  state.allOwners = payload
}
function setAllStaffIllnesses(state, payload) {
  state.allStaffIllnesses = {}
  state.allStaffIllnesses = payload
}
function setAllNonEmployedStaff(state, payload) {
  // state.allStaff = [...new Set([...state.allStaff, ...payload])];

  state.allNonEmployedStaff = []
  payload.forEach((element) => {
    element.full_name = element.first_name + ' ' + element.last_name
  })
  state.allNonEmployedStaff = [...payload]
}

function setAllRegions(state, payload) {
  state.allregions = payload
}

function setOwnership(state, payload) {
  if (payload.length !== 0) {
    state.ownership = payload
  }
}
function setAllContracts(state, payload) {
  state.allContracts = payload
  state.allContracts.results.forEach((element) => {
    if (element.contract_type == null) {
      element.status = "Ma'lumot berilmagan"
    }
    if (element.contract_type == 'SU') {
      element.contract_class_name = 'primary'
      element.contract_type = 'Summa'
      element.contract_type1 = 'SU'
    }
    if (element.contract_type == 'MS') {
      element.contract_class_name = 'success'
      element.contract_type = 'Muddatsiz'
      element.contract_type1 = 'MS'
    }
    if (element.contract_type == 'MU') {
      element.contract_class_name = 'warning'
      element.contract_type = 'Muddatli'
      element.contract_type1 = 'MU'
    }
    if (element.payment_type == 'CONT') {
      element.payment_type = 'Shartnoma'
      element.payment_type1 = 'CONT'
    }
    if (element.payment_type == 'NAQD') {
      element.payment_type = 'Kelishuv'
      element.payment_type1 = 'NAQD'
    }
    if (element.parent_contract == null) {
      element.parent_contract_color = 'text-success'
      element.parent_contract_label = 'label-success'
    } else {
      element.parent_contract_color = 'text-primary'
      element.parent_contract_label = 'label-primary'
    }
    if (element.is_active == true) {
      element.is_active_name = 'Faol'
      element.is_active1 = true
      element.is_active_color = 'success'
    } else if (element.is_active == false) {
      element.is_active_name = 'Faol emas'
      element.is_active1 = false
      element.is_active_color = 'danger'
    }
  })
}
function setProviderContractDetail(state, payload) {
  state.providerContractDetail = {}
  // payload.forEach(element => {
  if (payload.contract_type == null) {
    payload.status = "Ma'lumot berilmagan"
  }
  if (payload.contract_type == 'SU') {
    payload.contract_class_name = 'primary'
    payload.contract_type = 'Summa'
  } else if (payload.contract_type == 'MS') {
    payload.contract_class_name = 'success'
    payload.contract_type = 'Muddatsiz'
  } else if (payload.contract_type == 'MU') {
    payload.contract_class_name = 'warning'
    payload.contract_type = 'Muddatli'
  }
  if (payload.is_active == false) {
    payload.is_active_status = 'Foalmas'
  } else if (payload.is_active == true) {
    payload.is_active = payload.is_active_status = 'Faol'
  }
  state.providerContractDetail = payload
  // });
}
function setAllProvidersContract(state, payload) {
  state.allProvidersContract = {}
  payload.results.forEach((element) => {
    if (element.contract_type == null) {
      element.status = "Ma'lumot berilmagan"
    } else if (element.contract_type == 'SU') {
      element.contract_class_name = 'primary'
      element.contract_type = 'Summa'
    } else if (element.contract_type == 'MS') {
      element.contract_class_name = 'success'
      element.contract_type = 'Muddatsiz'
    } else if (element.contract_type == 'MU') {
      element.contract_class_name = 'warning'
      element.contract_type = 'Muddatli'
    }
  })
  state.allProvidersContract = payload
}
function setAllDrivers(state, payload) {
  state.allDrivers = payload
  console.log('payload--------', state.allDrivers[0])

  (state.allDrivers || []).forEach((element) => {
    if (
      element.staff_description !== undefined &&
      element.staff_description !== null
    )
      element.full__name =
        element.staff_description?.staffpersonal_description?.first_name +
        ' ' +
        element.staff_description?.staffpersonal_description?.last_name
  })
}

function setDriverDetails(state, payload) {
  state.driverDetails = payload

  if (
    state.driverDetails.staff_description !== undefined &&
    state.driverDetails.staff_description !== null
  )
    state.driverDetails.full__name =
      state.driverDetails.staff_description.staffpersonal_description
        .first_name +
      ' ' +
      state.driverDetails.staff_description.staffpersonal_description.last_name
}

function setZayavkaDetail(state, payload) {
  state.zayavkaContractDetail = payload
}

function setAllDriversRevenues(state, payload) {
  state.allDriversRevenues = payload
}

function setGasEquipsDetails(state, payload) {
  state.gasEquipsDetails = payload
}
function setAdvertPermitsDetails(state, payload) {
  state.advertPermitsDetails = payload
}
function setInsuranceTruckDetails(state, payload) {
  state.insuranceTruckDetails = payload
}
// function DriversbyId(state, payload) {
//   state.driverbyid = state.allDrivers.find(x => x.id == payload);
// }
function setAllTruckTypes(state, payload) {
  state.allTruckTypes = payload
}
function setAllFacturas(state, payload) {
  // state.allFacturas = {}
  state.allfacturaTotalNum = payload.count
  if (Boolean(payload.price_with_nds_total)) {
    state.allfacturaTotalSumm = payload.price_with_nds_total.toLocaleString('es-US')
  } else {
    state.allfacturaTotalSumm = 0
  }
  state.allFacturas = payload
}
function setFactureBycontract(state, payload) {
  state.allFacturasByContract = []
  state.allFacturasByContract = [...payload]
}

function setProviderDetails(state, payload) {
  state.providerDetails = {}
  state.providerDetails = payload
}

function setFacturaDetail(state, payload) {
  state.facturaDetail = payload
}
function setAlltransactionsDaily(state, payload) {
  state.allTransactionsDaily = [...payload]
  state.allTransactionsDaily.forEach((element) => {
    element.operations.forEach((element2) => {
      element2.amount__sum = element2.amount__sum.toLocaleString('zh-ZH')
    })
    element.ending_remain = element.ending_remain.toLocaleString('zh-ZH')

    element.daily_differ = element.daily_differ.toLocaleString('zh-ZH')

    element.starting_remain = element.starting_remain.toLocaleString('zh-ZH')
  })
}
function setTransactionsDailyDetails(state, payload) {
  state.transactionsDailyDetails = payload
}
function setFindSellMainAsset(state, payload) {
  state.findSellMainAsset = payload
}
function setRealizations(state, payload) {
  // if (payload.results !== undefined) {
  payload.results.forEach((x) => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (Boolean(x.get_doc_products_total)) {
      x.get_doc_products_total.nds_summa =
        x.get_doc_products_total.nds_summa.toLocaleString('es-US')
      x.get_doc_products_total.total =
        x.get_doc_products_total.total.toLocaleString('es-US')
      x.get_doc_products_total.total_withnds_summa =
        x.get_doc_products_total.total_withnds_summa.toLocaleString('es-US')
    }
  })
  // }
  state.realizations = payload
}
function setDeliveredOutfacture(state, payload) {
  payload.forEach((x) => {
    x.direction = ''
    // eslint-disable-next-line no-extra-boolean-cast
    if (Boolean(x.direction_truck)) {
      x.direction_truck.direction.citydestination.forEach((item, index) => {
        if (!index) x.direction = item.name
        else x.direction = x.direction + '-' + item.name
      })
    }
  })
  state.deliveredOutfacture = payload
}
function setProviderSearch(state, payload) {
  state.providerSearchResult = []
  payload.forEach((element) => {
    if (element.inn == '') {
      element.inn = 'Kiritilmagan'
    }
    element.full__name = element.full_name + ', INN:' + element.inn
  })
  state.providerSearchResult = payload
}
function setAllProviders(state, payload) {
  state.allProviders = {}
  state.allProviders = payload
  state.allProviders.results.forEach((element) => {
    if (element.juridical_type == 'YU') {
      element.juridical_type_name = 'Yuridik shaxs'
    } else if (element.juridical_type == 'JI') {
      element.juridical_type_name = 'Jismoniy shaxs'
    }
    if (element.status.status == 'Standart') {
      element.status_class_name = 'primary'
    } else if (element.status.status == 'Gold') {
      element.status_class_name = 'warning'
    } else if (element.status.status == 'Vip') {
      element.status_class_name = 'success'
    }
  })
}

function getProviderById(state, payload) {
  state.getCurrProviderDetails = state.allProviders.find((x) => x.id == payload)
}

function setPaymentSource(state, payload) {
  state.paymentSource = payload
}
function setPaymentOrderTypes(state, payload) {
  state.paymentOrderTypes = payload
}
function setTypeStateTransfers(state, payload) {
  payload.forEach((element) => {
    if (element.name == 'Providerlardan Tulovlar') {
      element.name = 'Postavshiklardan Tulovlar'
    }
  })
  state.typeStateTransfers = payload
}
function setContractDetails(state, payload) {
  if (payload.parent_contract == null) {
    payload.contract_type_name = 'Shartnoma'
  } else if (payload.parent_contract !== null) {
    payload.parent_contract = payload.contract_type_name = 'Dop soglasheniye'
  }
  if (payload.is_active == false) {
    payload.is_active_status = 'Foalmas'
    payload.is_active_status1 = false
  } else if (payload.is_active == true) {
    payload.is_active_status1 = true
    payload.is_active = payload.is_active_status = 'Faol'
  }
  if (payload.payment_type == 'CONT') {
    payload.payment_type = 'Shartnoma'
    payload.payment_type1 = 'CONT'
  } else if (payload.payment_type == 'NAQD') {
    payload.payment_type = 'Kelishuv'
    payload.payment_type1 = 'NAQD'
  }
  if (payload.contract_type == 'MS') {
    payload.contract_type = 'Muddatsiz'
    payload.contract_type1 = 'MS'
  } else if (payload.contract_type == 'MU') {
    payload.contract_type1 = 'MU'
    payload.contract_type = 'Muddatli'
  } else if (payload.contract_type == 'SU') {
    payload.contract_type1 = 'SU'
    payload.contract_type = 'Summa'
  }
  state.allContracts = payload
}
function setAllZayavka(state, payload) {
  state.allZayavka = []

  payload.find((element) => {
    if (element.zayavka_status == 'OTB') {
      element.status_style = 'danger'
      element.zayavka_status_name = 'Buyurtmachida'
    }
    if (element.start_date !== null) {
      element.start_date_new = element.start_date
        .split('')
        .splice(0, 10)
        .join('')
    }
    if (element.zayavka_status == 'OTW') {
      element.status_style = 'warning'
      element.zayavka_status_name = "Yo'lda"
    }
    if (element.zayavka_status == 'DEL') {
      element.status_style = 'success'
      element.zayavka_status_name = 'Yetkazilgan'
    }
    if (element.deliver == 'POS') {
      element.deliver_name = 'Postavshik'
    }
    if (element.payment_type == 'CONT') {
      element.payment_type_name = "Pul ko'chirish"
      element.payment_text_color = 'text-primary'
      element.payment_color_label = 'label-primary'
    }
    if (element.payment_type == 'NAQD') {
      element.payment_type_name = "Naqd to'lov"
      element.payment_text_color = 'text-danger'
      element.payment_color_label = 'label-danger'
    }
    element.price_with_nds = element.price_with_nds.toLocaleString('es-US')
    state.allZayavka.push(element)
  })
}

function setAllZayavkaDatas(state, payload) {
  if (Boolean(payload.price_with_nds_total)) {
    state.totalSumm = payload.price_with_nds_total.toLocaleString('es-US')
    // state.dailyTotalSumm = payload.results
    //   .map(x => x.price_with_nds)
    //   .reduce((a, b) => a + b, 0)
    //   .toLocaleString('es-US')
  } else {
    state.totalSumm = 0
    state.dailyTotalSumm = 0
  }
}

function setAllZayavkaPag(state, payload) {
  state.CountZayavka = payload.count
  state.allZayavka = []
  payload.results.forEach((element) => {
    if (element.zayavka_status == 'OTB') {
      element.zayavka_status_name = 'Buyurtmachida'
      element.status_style = 'red'
    }
    if (element.start_date !== null) {
      element.start_date_new = element.start_date
        .split('')
        .splice(0, 10)
        .join('')
    }
    if (element.zayavka_status == 'OTW') {
      element.status_style = 'warning'
      element.zayavka_status_name = "Yo'lda"
    }
    if (element.zayavka_status == 'DEL') {
      element.status_style = 'success'
      element.zayavka_status_name = 'Yetkazilgan'
    }
    if (element.deliver == 'POS') {
      element.deliver_name = 'Postavshik'
    }
    if (element.payment_type == 'CONT') {
      element.payment_type_name = "Pul ko'chirish"
      element.payment_text_color = 'text-primary'
      element.payment_color_label = 'label-primary'
    }
    if (element.payment_type == 'NAQD') {
      element.payment_type_name = "Naqd to'lov"
      element.payment_text_color = 'text-danger'
      element.payment_color_label = 'label-danger'
    }
  })
  state.allZayavka = payload.results
}


function setZayavkaItem(state, payload) {
  const f = state.allZayavka.findIndex( item => item.id == payload.id)

  if(f != -1){
    state.allZayavka = [
      ...state.allZayavka.slice(0, f),
      payload,
      ...state.allZayavka.slice(f + 1)
    ]
  }
}


function setIsLoading(state, payload) {
  state.isLoading = payload
}
function serivesPaymentSource(state, payload) {
  state.servicePaymentSource = payload
}
function setProvodkaList(state, payload) {
  state.provodkaList = payload
}
function setContract(state, payload) {
  state.allContractById = payload
}
function setProductCondition(state, payload) {
  state.productCondition = payload
}
function setStoredproductsForExport(state, payload) {
  state.storedproductsForExport = payload
}
function setGoodsServicesImport(state, payload) {
  state.goodsServicesImport = payload
}
function getContractById(state, payload) {
  state.ContractById = state.allContractById.find((x) => x.id == payload)
}
function setAllMarshrut(state, payload) {
  if (
    Boolean(payload.contract_appendix) &&
    payload.contract_appendix[0] !== undefined
  ) {
    state.allMarshrut = payload.contract_appendix[0]
    state.newMarshrut = [...payload.contract_appendix]
  } else {
    state.newMarshrut = []
    state.allMarshrut = []
  }
}
function setFinancePlanIncomeYearly(state, payload) {
  state.financePlanIncomeYearly = []
  // let sum =0;
  payload.forEach((element) => {
    element.month_revenues_plans.forEach((element2) => {
      element2.plan_amount = element2.plan_amount.toLocaleString('zh-ZH', {
        minimumFractionDigits: 2
      })
      let date = new Date(element2.plan_year_month)
      element2.month_name = date.toLocaleString('ru-RU', { month: 'long' })
    })
  })

  state.financePlanIncomeYearly = [...payload]
}
function setAllAdditionalContracts(state, payload) {
  state.allclientcontracts = payload
}
function setMainAssetTakenDetails(state, payload) {
  state.mainAssetTakenDetails = {}
  state.mainAssetTakenDetails = payload
}
function setAllZayavkaById(state, payload) {
  state.zayavkaById = {}
  if (Boolean(payload.start_date)) {
    payload.start_time = payload.start_date.split('T')[1]
    payload.start_time = payload.start_time.slice(0, 5)
    payload.start_date = payload.start_date.slice(0, 10)
    payload.start_date = payload.start_date.split('-').reverse().join('-')
  }
  if (Boolean(payload.delivered_date)) {
    payload.delivered_date = payload.delivered_date.slice(0, 10)
  }
  if (Boolean(payload.end_date)) {
    payload.end_time = payload.end_date.split('T')[1]
    payload.end_time = payload.end_time.slice(0, 5)
    payload.end_date = payload.end_date.slice(0, 10)
    payload.end_date = payload.end_date.split('-').reverse().join('-')
  }
  if (payload.delivered_date == null) {
    payload.delivered_date = ''
  }

  if (payload.zayavka_status == 'OTW') {
    payload.zayavka_status = "Yo'lda"
  }
  if (payload.zayavka_status == 'OTB') {
    payload.zayavka_status = 'Buyurtmachida'
  }
  if (payload.zayavka_status == 'DEL') {
    payload.zayavka_status = 'Yetkazilgan'
  }
  // if (payload.zayavka_status == "DEL") {
  //   payload.zayavka_status = "Yetkazilgan";
  // }
  if (payload.deliver == 'POS') {
    payload.deliver_name = 'Postavshik'
  }
  if (payload.payment_type == 'CONT') {
    payload.payment_type = "Pul ko'chirish"
  }
  if (payload.payment_type == 'NAQD') {
    payload.payment_type = "Naqd to'lov"
  }

  payload.nds_summa = payload.nds_summa.toLocaleString('es-US')
  payload.price_with_nds = payload.price_with_nds.toLocaleString('es-US')
  payload.price = payload.price.toLocaleString('es-US')
  state.zayavkaById = payload
}
function setTruckEmptyById(state, payload) {
  state.truckDetails = payload
}
function setNds(state, payload) {
  state.nds = payload
}
function setAllTnn(state, payload) {
  state.alltnn = payload
}
function setMainExpenditures(state, payload) {
  state.mainExpenditures = payload
}
function setMainProfit(state, payload) {
  state.mainProfit = []
  state.mainProfit = [...payload]
}
function setIncomesFromService(state, payload) {
  state.incomesFromService = []
  state.incomesFromService = [...payload]
}
function setBalansAccountLists(state, payload) {
  state.balansAccountList = []
  state.balansAccountList = [...payload]
}

function setMainProfitCategory(state, payload) {
  state.mainProfitCategory = []
  state.mainProfitCategory = [...payload]
}
function setFacturaDisplay(state, payload) {
  state.facturaDisplay = payload
}
function setMainProfitSubCategory(state, payload) {
  state.mainProfitSubCategory = []
  state.mainProfitSubCategory = [...payload]
}
function setMainProfitSubCategorySpecial(state, payload) {
  state.mainProfitSubCategorySpecial = []
  state.mainProfitSubCategorySpecial = [...payload]
}
function setCompanyGeneralInfo(state, payload) {
  state.companyGeneralInfo = {}
  state.companyGeneralInfo = payload
}
function setMainProfitSorted(state, payload) {
  state.mainProfitSorted = []
  state.mainProfitSorted = [...payload]
}
function setTransferSalaryList(state, payload) {
  state.transferSalaryList = []
  payload.forEach((element) => {
    if (element.comment == null) {
      element.comment = 'Kiritilmagan'
    }
  })
  state.transferSalaryList = [...payload]
}
function setTnnId(state, payload) {
  state.alltnnid = payload
}
function setTruckTypesId(state, payload) {
  state.truckListId = payload
}
function setFinanceBalance(state, payload) {
  state.financeBalance = payload
}
function setPaymentStatements(state, payload) {
  payload.results.forEach((element) => {
    element.total_elements.total =
      element.total_elements.total.toLocaleString('zh-ZH')
    if (element.payed_status == false) {
      element.payed_status_name = 'Neoplachen'
      element.payed_status_color = 'danger'
    } else if (element.payed_status == true) {
      element.payed_status_name = 'Oplachen'
      element.payed_status_color = 'success'
    }
  })
  state.paymentStatements = payload
}
function setPayrolListDetails(state, payload) {
  // if (
  //   payload.payrolcalcedelements_set !== undefined ||
  //   payload.payrolcalcedelements_set !== null
  // ) {
  //   payload.payrolcalcedelements_set.forEach((x, index) => {
  //     x.index = index
  //   })
  // }
  state.payrolListDetails = {}
  for (let prop in payload.payrolcalcedelements_set) {
    for (let prop2 in payload.payrolcalcedelements_set[prop]) {
      payload.payrolcalcedelements_set[prop][prop2] =
        payload.payrolcalcedelements_set[prop][prop2].toLocaleString('zh-ZH')
    }
  }
  state.payrolListDetails = payload
}
function setDetailsSalaryPaymentStatements(state, payload) {
  state.detailsSalaryPaymentStatements = {}
  state.detailsSalaryPaymentStatements = payload
}
function setGasEquips(state, payload) {
  state.gasEquips = payload
}
function setAdvertPermits(state, payload) {
  state.advertPermits = payload
}
function setInsuarences(state, payload) {
  state.insuarences = payload
}
function settNameOfContract(state, payload) {
  state.nameOfContracts = payload
}
function setTruckLists(state, payload) {
  state.allTruckList = []
  payload.find((element) => {
    if (element.status == 'PUS') {
      element.status_name = 'Bo’sh'
      element.status_color = 'success'
    }
    if (element.status == 'BRO') {
      element.status_name = 'Bronda'
      element.status_color = 'warning'
    }
    if (element.status == 'SER') {
      element.status_name = 'Texnik Servisda'
      element.status_color = 'red'
    }
    if (element.status == 'ORD') {
      element.status_name = 'Buyurtmada'
      element.status_color = 'primary'
    }
    if (element.is_normal_truck_documents == true) {
      element.is_normal_truck_documents_color = 'success'

      element.is_normal_truck_documents = 'Norma'
    } else if (element.is_normal_truck_documents == false) {
      element.is_normal_truck_documents_color = 'red'

      element.is_normal_truck_documents = 'Muammo'
    }
    if (element.main_assets == null) {
      element.main_assets_name = 'Ulanmagan'
      element.main_assets_color = 'text-danger'
      element.main_assets_label = 'label-danger'
    } else {
      element.main_assets_class = 'label-success'
      element.main_assets_name = 'Ulangan'
      element.main_assets_color = 'text-success'
      element.main_assets_label = 'label-success'
    }
    state.allTruckList.push(element)
  })

  payload.forEach((element) => {
    element.truck__name =
      element.truck_type.brand.name +
      ' ' +
      element.truck_type.model +
      ' ' +
      element.truck_type.capacity_kg +
      '-kg ' +
      element.truck_type.capacity_m3 +
      '-m3'
  })
}
function setAllCities(state, payload) {
  state.Cities = payload
}
function setNotLinkedTotruck(state, payload) {
  state.notLinkedTotruck = payload
}
function setOperationsBycontract(state, payload) {
  state.operationsBycontract = payload
}
function activateZayavkaModal(state, payload) {
  state.zayavkaModalId = payload
}
function setZaById(state, payload) {
  state.setZayavById = []
  payload.results.forEach((element) => {
    if (element.zayavka_status == 'OTW') {
      element.zayavka_status = "Yo'lda"
      element.status_style = 'warning'
    }
    if (element.zayavka_status == 'OTB') {
      element.zayavka_status = 'Buyurtmachida'
      element.status_style = 'red'
    }
    if (element.zayavka_status == 'DEL') {
      element.zayavka_status = 'Yetkazilgan'
      element.status_style = 'success'
    }
    if (element.deliver == 'POS') {
      element.deliver = 'Postavshik'
    }
    if (element.payment_type == 'CONT') {
      element.payment_type_name = "Pul ko'chirish"
      element.payment_text_color = 'text-primary'
      element.payment_color_label = 'label-primary'
    } else if (element.payment_type == 'NAQD') {
      element.payment_type_name = "Naqd to'lov"
      element.payment_text_color = 'text-danger'
      element.payment_color_label = 'label-danger'
    }
    element.price = element.price.toLocaleString('es-US')
  })
  state.setZayavById = payload.results
}
function setBankAccountLink(state, payload) {
  state.bankAccountLink = {}
  state.bankAccountLink = payload
}
function setInfoDriverDashboard(state, payload) {
  state.infoDriverDashboard = []
  state.infoDriverDashboard = [...payload]
}
function setTopClientDashboard(state, payload) {
  state.topClientDashboard = []
  state.topClientDashboard = [...payload]
}
function setExpenditureIncomeTotal(state, payload) {
  state.expenditureIncomeTotal = []
  state.expenditureIncomeTotal = [...payload]
}
// finance
function setAllCorCards(state, payload) {
  state.AllCorCards = []
  ;(payload || []).find((element) => {
    if (element.status == true) {
      element.status = 'Faol'
    }
    state.AllCorCards.push(element)
  })
}
function setAllMainExp(state, payload) {
  state.AllMainExp = payload
}
function setCorpCardDetails(state, payload) {
  console.log(payload, 'setCorp Mutation');
  state.corpDetails = payload
}
function setCorpCardsDetails(state, payload) {
  state.corpCardsDetails = {}
  payload.number_16_digit = payload.number_16_digit
    .replace(/(.{4})/g, '$1 ')
    .trim()
  if (payload.remain !== undefined) {
    payload.remain = payload.remain.toLocaleString('zh-ZH')
  }
  if (payload.amount !== undefined) {
    payload.amount = payload.amount.toLocaleString('zh-ZH')
  }

  state.corpCardsDetails = payload
}

function setOperByCard(state, payload) {
  state.operByCard = []
  payload.forEach((element) => {
    if (element.operation_type == 'WITH') {
      element.operation_type = 'Chiqim'
      element.operation_type_color = 'text-danger'
      element.operation_type_dot = 'label-danger'
    } else if (element.operation_type == 'DEPO') {
      element.operation_type = 'Kirim'
      element.operation_type_color = 'text-success'
      element.operation_type_dot = 'label-success'
    }
    element.card_number = element.card_number.replace(/(.{4})/g, '$1 ').trim()
    element.amount = element.amount.toLocaleString('es-US')
    if (element.operation_type == 'Kirim') {
      element.amountDebet = element.amount
    } else if (element.operation_type == 'Chiqim') {
      element.amountCredet = element.amount
    }
    state.operByCard.push(element)
  })
}
function setOneClientContract(state, payload) {
  state.oneClientContract = []

  payload.forEach((element) => {
    if (element.contract_type == null) {
      element.status = "Ma'lumot berilmagan"
    }
    if (element.contract_type == 'SU') {
      element.contract_class_name = 'primary'
      element.contract_type = 'Summa'
    } else if (element.contract_type == 'MS') {
      element.contract_class_name = 'success'
      element.contract_type = 'Muddatsiz'
    } else if (element.contract_type == 'MU') {
      element.contract_class_name = 'warning'
      element.contract_type = 'Muddatli'
    }
    if (element.parent_contract == null) {
      element.parent_contract = 'Dogovor'
    } else if (element.parent_contract !== null) {
      element.parent_contract = 'Dop soglashenie'
    }
    state.oneClientContract.push(element)
  })
}
function setDoneWorks(state, payload) {
  state.doneWorks = payload
  state.doneWorks.results.forEach((element) => {
    if (element.zayavka_status == 'OTB') {
      element.status_style = 'danger'
      element.zayavka_status_name = 'Buyurtmachida'
    }
    if (element.start_date !== null) {
      element.start_date_new = element.start_date
        .split('')
        .splice(0, 10)
        .join('')
    }
    if (element.zayavka_status == 'OTW') {
      element.status_style = 'warning'
      element.zayavka_status_name = "Yo'lda"
    }
    if (element.zayavka_status == 'DEL') {
      element.status_style = 'success'
      element.zayavka_status_name = 'Yetkazilgan'
    }
    if (element.deliver == 'POS') {
      element.deliver_name = 'Postavshik'
    }
    if (element.payment_type == 'CONT') {
      element.payment_type_name = "Pul ko'chirish"
      element.payment_text_color = 'text-primary'
      element.payment_color_label = 'label-primary'
    }
    if (element.payment_type == 'NAQD') {
      element.payment_type_name = "Naqd to'lov"
      element.payment_text_color = 'text-danger'
      element.payment_color_label = 'label-danger'
    }
    element.price_with_nds = element.price_with_nds
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  })
}
function setDoneWorksCount(state, payload) {
  state.doneWorksCount = {}
  state.doneWorksCount = payload
  state.doneWorksCount.results.forEach((element) => {
    if (element.zayavka_status == 'OTB') {
      element.status_style = 'danger'
      element.zayavka_status_name = 'Buyurtmachida'
    }
    if (element.start_date !== null) {
      element.start_date_new = element.start_date
        .split('')
        .splice(0, 10)
        .join('')
    }
    if (element.zayavka_status == 'OTW') {
      element.status_style = 'warning'
      element.zayavka_status_name = "Yo'lda"
    }
    if (element.zayavka_status == 'DEL') {
      element.status_style = 'success'
      element.zayavka_status_name = 'Yetkazilgan'
    }
    if (element.deliver == 'POS') {
      element.deliver_name = 'Postavshik'
    }
    if (element.payment_type == 'CONT') {
      element.payment_type_name = "Pul ko'chirish"
      element.payment_text_color = 'text-primary'
      element.payment_color_label = 'label-primary'
    }
    if (element.payment_type == 'NAQD') {
      element.payment_type_name = "Naqd to'lov"
      element.payment_text_color = 'text-danger'
      element.payment_color_label = 'label-danger'
    }
    element.price_with_nds = element.price_with_nds
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    state.doneWorksCount.results.push(element)
  })
}
function setIncomeDashboard(state, payload) {
  state.incomeDashboard = []
  state.incomeDashboard = [...payload]
}
function setExpenditureDashboard(state, payload) {
  state.expenditureDashboard = []
  state.expenditureDashboard = [...payload]
}
function setCurrCompJob(state, payload) {
  state.currCompJob = []
  state.currCompJob = payload

  // if (payload.truck !== null) {
  //   payload.find((element) => {
  //     if (element.deliver == 'POS') {
  //       element.deliver = 'Postavshik'
  //     }

  //     if (element.truck !== null) {
  //       if (element.truck.status == 'PUS') {
  //         element.truck.status = "Bo'sh"
  //         element.truck.status_color = 'green'
  //       } else if (element.truck.status == 'BRO') {
  //         element.truck.status = 'Bronda'
  //         element.truck.status_color = 'warning'
  //       } else if (element.truck.status == 'SER') {
  //         element.truck.status = 'Texnik Servisda'
  //         element.truck.status_color = 'danger'
  //       } else if (element.truck.status == 'ORD') {
  //         element.truck.status_color = 'primary'

  //         element.truck.status = 'Buyurtmada'
  //       }
  //       if (element.payment_type == 'CONT') {
  //         element.payment_type_name = "Pul ko'chirish"
  //         element.payment_text_color = 'success'
  //         element.payment_color_label = 'label-primary'
  //       }
  //       if (element.payment_type == 'NAQD') {
  //         element.payment_type_name = "Naqd to'lov"
  //         element.payment_text_color = 'text-danger'
  //         element.payment_color_label = 'label-danger'
  //       }
  //       if (element.delivered_date !== null) {
  //         element.delivered_date = element.delivered_date
  //           .split('')
  //           .splice(0, 10)
  //           .join('')
  //       }
  //     }
  //     state.currCompJob.push(element)
  //   })
  // }
}
// Transfer list
function setAllTransferList(state, payload) {
  state.allTransferList = []
  state.allTransferList = [...payload]
}
function setStaffEmployedListForMonth(state, payload) {
  state.staffEmployedListForMonth = []
  state.staffEmployedListForMonth = [...payload]
}
function setAllTransferListCash(state, payload) {
  state.allTransferListCash = []
  // payload.results.forEach(element => {
  //   element.amount = element.amount.toLocaleString('es-US')
  //   if (element.order_type_name == 'Kirim') {
  //     element.amountDebet = element.amount

  //     element.order_type_color = 'text-success'
  //   } else if (element.order_type_name == 'Chiqim') {
  //     element.order_type_color = 'text-danger'
  //     element.amountCredet = element.amount
  //   }
  // })
  state.allTransferListCash = [...payload]
}

function setTypeOfAction(state, payload) {
  state.typeOfAction = payload
}
function setSkladList(state, payload) {
  state.skladList = payload
}
function setStaffList(state, payload) {
  state.staffList = payload
}
function setFinanceExpenditureList(state, payload) {
  state.financeExpenditureList = {}
  state.financeExpenditureList = payload
}
function setProductsList(state, payload) {
  payload.results.forEach((x) => {
    if (x.placement_type == 'WH') {
      x.placement_type_name = 'Skladda'
    } else if (x.placement_type == 'EX') {
      x.placement_type_name = 'Xarajatlarga olingan'
    }
  })
  state.productsList = payload
}

function setKontragentsList(state, payload) {
  state.kontragentsList = payload
}
function setTechMonitorDetails(state, payload) {
  state.techMonitorDetails = payload
}
function countragentKontractFacturasCommit(state, payload) {
  state.countragentKontractFactura = payload
}
function setGeneralPaymentTypes(state, payload) {
  state.generalPaymentTypes = payload
  console.log('data', state.generalPaymentTypes, payload)
}
function countragentKontractsPaymentsCommit(state, payload) {
  state.countragentKontractsPayment = []

  payload.forEach((element) => {
    element.comment = 'lorem ipsum dolor sit amet'
    if (element.transfer_code == '01') {
      element.amountKredit = element.amount.toLocaleString('es-US')
    } else if (element.transfer_code == '02') {
      element.amountKredit = element.amount.toLocaleString('es-US')
    }
  })

  state.countragentKontractsPayment = payload
}
function countragentKontractsSverkaCommit(state, payload) {
  state.countragentKontractsSverka = payload
}

function getZayavkaDriversCommit(state, payload) {
  state.ZayavkaDriversList = payload
}

function kontragentContractAktSverkaCommit(state, payload) {
  for (let i = 0; i < payload.results.length; i++) {
    payload.results[i].index = i + 1
  }
  state.kontragentContractAktSverka = payload
}
function counteragentContractsCommit(state, payload) {
  payload.forEach((element) => {
    if (element.contract_type == 'MU') {
      element.contract_type_name = 'Muddatli'
    } else if (element.contract_type == 'SU') {
      element.contract_type_name = 'Summa'
    } else if (element.contract_type == 'MS') {
      element.contract_type_name = 'Muddatsiz'
    }
  })
  state.counteragentContracts = payload
}
function changeDashboardTruckListValue(state,payload){
  state.dashboardTruckListValue = payload
}
function providerContractDeliveredZayavkasCommit(state, payload) {
  for (let i = 0; i < payload.length; i++) {
    payload[i].index = i + 1
    if (payload[i].start_date !== null || payload[i].end_date !== null) {
      payload[i].start_date = payload[i].start_date.slice(0, 10)
      payload[i].end_date = payload[i].end_date.slice(0, 10)
    }
    if (payload[i].zayavka_status == 'OTB') {
      payload[i].zayavka_status = 'Buyurtmachida'
    }
    if (payload[i].deliver == 'POS') {
      payload[i].deliver = 'Postavshik'
    }
  }

  // for (let prop in payload) {
  //   for (let prop2 in payload[prop]) {
  //     if (payload[prop][prop2] > 0) {
  //       payload[prop][prop2] = payload[prop][prop2].toLocaleString('es-US')
  //     }
  //   }
  // }
  for (let i = 0; i < payload.length; i++) {
    payload[i].index = i + 1
  }
  state.providerContractDeliveredZayavkas = payload
}
function kontragentContractAktSverkaIDCommit(state, payload) {
  state.kontragentContractAktSverkaID = {}
  let sum_credit_company = 0
  let sum_debet_client = 0
  let sum_debet_end_saldo_company = 0
  let sum_credit_end_saldo_client = 0

  for (let i = 0; i < payload.akt_sverka_body.length; i++) {
    sum_credit_company =
      sum_credit_company + parseInt(payload.akt_sverka_body[i].credit_company)

    sum_debet_client =
      sum_debet_client + parseInt(payload.akt_sverka_body[i].debet_client)

    sum_debet_end_saldo_company =
      sum_debet_end_saldo_company +
      parseInt(payload.akt_sverka_body[i].debet_company)

    sum_credit_end_saldo_client =
      sum_credit_end_saldo_client +
      parseInt(payload.akt_sverka_body[i].credit_client)
  }

  payload.sum_credit_company = sum_credit_company
  payload.sum_debet_client = sum_debet_client

  payload.sum_debet_end_saldo_company = sum_debet_end_saldo_company
  payload.sum_credit_end_saldo_client = sum_credit_end_saldo_client
  state.kontragentContractAktSverkaID = payload
}
function setAllSkladList(state, payload) {
  state.allSkladList = payload
}
function setCategoryGroupNames(state, payload) {
  state.categoryGroupNames = payload
}
function setCategoryGroupData(state, payload) {
  state.categoryGroupData = payload
}
function setAllPersonal(state, payload) {
  state.allPersonal = {}
  state.allPersonal = payload
}
function setOblast(state, payload) {
  state.allOblast = []
  state.allOblast = [...payload]
}
function setDailyAmortizationUnCalc(state, payload) {
  state.dailyAmortizationUnCalc = []
  state.dailyAmortizationUnCalc = [...payload]
}
function setDailyAmortizationMainAssets(state, payload) {
  state.dailyAmortizationMainAssets = []
  payload.forEach((x) => {
    if (x.operation_amount !== undefined && x.operation_amount !== null)
      x.operation_amount = x.operation_amount.toLocaleString('es-US')
  })
  state.dailyAmortizationMainAssets = [...payload]
}
function setAllAppen(state, paylaod) {
  state.allAppen = {}
  paylaod.results.forEach((element) => {
    const date = new Date(element.created_at)
    const date2 = new Date(element.updated_at)
    let created_at_date = element.created_at.slice(0, 10)
    let updated_at_date = element.updated_at.slice(0, 10)
    let created_at_time = date.toLocaleTimeString([], {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit'
    })
    let updated_at_time = date2.toLocaleTimeString([], {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit'
    })
    element.createdfullDateTime = created_at_date + ' ' + created_at_time
    element.updatedfullDateTime = updated_at_date + ' ' + updated_at_time
  })
  state.allAppen = paylaod
}
function setRegionByOblast(state, payload) {
  state.regionByOblast = []
  state.regionByOblast = [...payload]
}
function setIncomeListDetails(state, payload) {
  payload.income_amount = payload.income_amount.toLocaleString('es-US')
  state.incomeListDetails = payload
}
function setMeasurements(state, payload) {
  for (let i = 0; i < payload.length; i++) {
    payload[i].index = i + 1
  }
  state.measurements = payload
}
function setStaffVacations(state, payload) {
  state.staffVacations = payload
}
function setProductDetails(state, payload) {
  state.productDetails = {}

  if (payload.placement_type == 'WH') {
    payload.placement_type_name = 'Skladda'
  } else if (payload.placement_type == 'EX') {
    payload.placement_type_name = 'Xarajatlarga olingan'
  }
  state.measurements?.forEach((element) => {
    for (let i = 0; i < payload.storedproductservice_set.length; i++) {
      if (
        payload.storedproductservice_set[i].import_category.measurement ==
        element.id
      ) {
        payload.storedproductservice_set[i].import_category.measurement_name =
          element.name
      }
    }
  })
  state.productDetails = payload
}
// import Swal from 'sweetalert2'
// import { isObject } from '@/utils'

function setTransferDetails(state, arg) {
  // if (!payload || !isObject(payload)) {
  //   Swal.fire({
  //     title: 'Xato yuz berdi',
  //     text: payload,
  //     icon: 'error'
  //   })
  //   return
  // }

  // console.log('payload', arg)
  let payload = arg.data
  payload.transfer_type_name = arg.type

  state.transferDetails = {}

  if (payload.operation_type == 'DEPO') {
    payload.operation_type = 'Kirim'
  } else if (payload.operation_type == 'WITH') {
    payload.operation_type = 'Chiqim'
  }
  if (payload.hasOwnProperty('card_number')) {
    payload.card_number = payload.card_number.replace(/(.{4})/g, '$1 ').trim()
  } else if (payload.hasOwnProperty('client_inn')) {
    payload.client_inn = payload.client_inn.replace(/(.{3})/g, '$1 ').trim()
  }
  payload.amount = payload.amount
    .toString()
    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')

  state.transferDetails = payload
}
function setAllActRecont(state, payload) {
  state.allActRecon = {}
  state.allActRecon = payload

  state.allActRecon.results.forEach((element) => {
    for (let prop in element) {
      if (element[prop] < 0) {
        element[prop] = (element[prop] * -1).toLocaleString('es-US')
      } else if (element[prop] > 0) {
        element[prop] = element[prop].toLocaleString('es-US')
      }
    }
  })
}
function staffDepartmentsCommit(state, payload) {
  if (state.staffDepartments.length !== payload.length) {
    state.staffDepartments = payload
    for (let i = 0; i < state.allStaff.length; i++) {
      if (state.allStaff[i].staffpersonal_description !== undefined) {
        state.allStaff[i].full_name =
          state.allStaff[i].staffpersonal_description.first_name +
          ' ' +
          state.allStaff[i].staffpersonal_description.last_name
        // if (state.allStaff[i].position_description.department == element.id) {
        // state.allStaff[i].department_name = element.name;
        state.allStaff[i].department_name = state.staffDepartments.find(
          (x) => x.id == state.allStaff[i].position_description.department
        ).name
        // }
      }
    }
  }
  // });
}
function staffPositionsCommit(state, payload) {
  state.staffPositions = payload
  payload.forEach((element) => {
    element.oklad = element.oklad.toLocaleString('USD', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })
  })
}
function setAllActRecontDetails(state, payload) {
  state.ActRecontDetails = {}
  let sum_credit_company = 0
  let sum_debet_client = 0
  let sum_debet_end_saldo_company = 0
  let sum_credit_end_saldo_client = 0

  for (let prop in payload.akt_sverka_body) {
    for (let prop2 in payload.akt_sverka_body[prop]) {
      if (payload.akt_sverka_body[prop][prop2] == null) {
        payload.akt_sverka_body[prop][prop2] = 0.0
      }
    }
    payload.akt_sverka_body[prop].reg_number = 'Kiritilmagan'
  }

  for (let i = 0; i < payload.akt_sverka_body.length; i++) {
    sum_credit_company =
      sum_credit_company + payload.akt_sverka_body[i].credit_company

    sum_debet_client =
      sum_debet_client + payload.akt_sverka_body[i].debet_client

    sum_debet_end_saldo_company =
      sum_debet_end_saldo_company + payload.akt_sverka_body[i].debet_company

    sum_credit_end_saldo_client =
      sum_credit_end_saldo_client + payload.akt_sverka_body[i].credit_client
  }

  payload.sum_credit_company = Math.floor(sum_credit_company * 100) / 100
  payload.sum_debet_client = Math.floor(sum_debet_client * 100) / 100

  payload.sum_debet_end_saldo_company =
    Math.floor(sum_debet_end_saldo_company * 100) / 100
  payload.sum_credit_end_saldo_client =
    Math.floor(sum_credit_end_saldo_client * 100) / 100
  state.ActRecontDetails = payload
}
function setTillDateList(state, payload) {
  state.tillDateList = payload
}
function setMainassetsNotTaken(state, payload) {
  state.mainassetsNotTaken = payload
}
function setAnnualRemark(state, payload) {
  state.annualRemark = payload
}
function setServiceCash(state, payload) {
  state.serviceCash = {}
  state.serviceCash = payload
  state.serviceCash.results.forEach((element) => {
    if (element.placement_type == 'WH') {
      element.placement_type_name = 'Skladda'
    } else if (element.placement_type == 'EX') {
      element.placement_type_name = 'Xarajatlarga olingan'
    }
  })
}
function setTechMonitor(state, payload) {
  state.techMonitor = {}
  state.techMonitor = payload
}
function setTotalSum(state, payload) {
  state.totalSum = {}

  payload.total_remain = payload.total_remain.toLocaleString('es-US')
  payload.today_remain = payload.today_remain.toLocaleString('es-US')

  payload.yesterday_remain = payload.yesterday_remain.toLocaleString('es-US')
  state.totalSum = payload
}
function setFilterData(state, payload) {
  state.filterData = {}
  state.filterData = payload
}
function setNomenklaturaAll(state, payload) {
  state.allNomenklatureAll = payload
}
function setInfoForDashboard(state, payload) {
  state.infoForDashboard = {}
  state.infoForDashboard = payload
}
function setIncomeList(state, payload) {
  state.incomeList = {}
  payload.results.forEach((x) => {
    x.income_amount = x.income_amount.toLocaleString('es-US')
  })
  state.incomeList = payload
}
function setMainAssetRealizationFacturas(state, payload) {
  state.mainAssetRealizationFacturas = []
  state.mainAssetRealizationFacturas = [...payload]
}
function setAllMfoBank(state, payload) {
  console.log(payload, 'payload')
  state.allMfoBank = []
  payload?.results?.forEach((element) => {
    element.full_name = element.code + ' | ' + element.name_branch
  })
  state.allMfoBank = [...payload?.results]
}
function setNomenklatura(state, payload) {
  for (let i = 0; i < payload.length; i++) {
    payload[i].index = i + 1
  }
  state.allNomenklature = payload
}
function setNomenklaturaGroup(state, payload) {
  for (let i = 0; i < payload.length; i++) {
    payload[i].index = i + 1
  }
  state.nomenklaturaGroup = payload.reverse()
}
function setLicenses(state, payload) {
  state.allLicenses = {}
  state.allLicenses = payload
}
function setDriverPermits(state, payload) {
  state.allDriversPermits = {}
  state.allDriversPermits = payload
}
function setDriverPermitsDetails(state, payload) {
  state.driverPermitsDetails = {}
  state.driverPermitsDetails = payload
}
function setLicensesById(state, payload) {
  state.licensesById = {}
  state.licensesById = payload
}
function providerContractAktSverkaCommit(state, payload) {
  state.providerContractAktSverka = {}
  state.providerContractAktSverka = payload
}
function setCountragentKontracts(state, payload) {
  state.countragentKontracts = payload
}
function providerContractAktSverkaIDCommit(state, payload) {
  state.providerContractAktSverkaID = {}
  let sum_credit_company = 0
  let sum_debet_client = 0
  let sum_debet_end_saldo_company = 0
  let sum_credit_end_saldo_client = 0

  for (let i = 0; i < payload.akt_sverka_body.length; i++) {
    sum_credit_company =
      sum_credit_company + parseInt(payload.akt_sverka_body[i].credit_company)

    sum_debet_client =
      sum_debet_client + parseInt(payload.akt_sverka_body[i].debet_client)

    sum_debet_end_saldo_company =
      sum_debet_end_saldo_company +
      parseInt(payload.akt_sverka_body[i].debet_company)

    sum_credit_end_saldo_client =
      sum_credit_end_saldo_client +
      parseInt(payload.akt_sverka_body[i].credit_client)
  }

  payload.sum_credit_company = sum_credit_company
  payload.sum_debet_client = sum_debet_client

  payload.sum_debet_end_saldo_company = sum_debet_end_saldo_company
  payload.sum_credit_end_saldo_client = sum_credit_end_saldo_client

  state.providerContractAktSverkaID = payload
}
function setCountragentKontractFacturas(state, payload) {
  state.countragentKontractFacturas = payload
}
function setKontragentsContractsListWithoutPg(state, payload) {
  payload.forEach((x) => {
    // for (let i=0; i<state.kontragentsList.re)

    // state.kontragentsList.forEach((element,index)=>{
    //   if (element.id == )
    // })

    if (x.inn !== null) {
      x.name = x.full_name + ' INN: ' + x.inn
    } else {
      x.name = x.full_name + ' INN: Kiritilinmagan'
    }
  })
  state.kontragetnsWithoutPg = payload
}
function setAllDriversById(state, payload) {
  state.allDriversById = payload
}

function setLicenceTypes(state, payload) {
  state.licenceTypes = payload
}

function setCountragentKontractsPayment(state, payload) {
  state.countragentKontractsPayments = payload
}

function setProviderContractById(state, payload) {
  for (let i = 0; i < payload.length; i++) {
    payload[i].index = i + 1
  }
  payload.forEach((element) => {
    if (element.contract_type == 'SU') {
      element.contract_class_name = 'primary'
      element.contract_type = 'Summa'
    } else if (element.contract_type == 'MS') {
      element.contract_class_name = 'success'
      element.contract_type = 'Muddatsiz'
    } else if (element.contract_type == 'MU') {
      element.contract_class_name = 'warning'
      element.contract_type = 'Muddatli'
    }
    if (element.is_active == true) {
      element.is_active = 'Foal'
      element.is_active_color = 'success'
    } else if (element.is_active == false) {
      element.is_active = 'Faolmas'
      element.is_active_color = 'red'
    }
    if (element.amount == null) {
      element.amount = 'Kiritmagan'
    } else {
      element.amount = element.amount.toLocaleString('es-US')
    }
  })
  state.providerContractById = payload
}
function setMainProfitSubCategorySpecialTotal(state, payload) {
  state.mainProfitSubCategorySpecialTotal = []
  state.mainProfitSubCategorySpecialTotal = [...payload]
}
function setMainExSubCategorySpecialTotal(state, payload) {
  state.mainExSubCategorySpecialTotal = []
  state.mainExSubCategorySpecialTotal = [...payload]
}
function loading(state, payload) {
  state.loadingState = false
  if (!Object.keys(state.zayavkaById)) {
    state.loadingState = payload
  }
}
function SET_STARTING_POINT(state, payload) {
  state.allStartingPoint = payload
}
function SET_COUNTRAGENT_STARTING_POINT(state, payload) {
  state.allCountragentStartingPoint = payload
}

function SET_PROVIDER_STARTING_POINT(state, payload) {
  state.allProviderStartingPoint = payload
}


function SET_COPR_CARD_LIST(state, payload) {
  state.corpCardList = payload
}

export default {
  /// adham's adds
  SET_COPR_CARD_LIST,
  /// adham's add end
  SET_COUNTRAGENT_STARTING_POINT,
  SET_PROVIDER_STARTING_POINT,
  SET_STARTING_POINT,
  setCompletedWorks,
  setCompanyGeneralInfo,
  setFinanceExpenditureList,
  setIncomeDashboard,
  setFinancePlanIncomeYearly,
  setTopClientDashboard,
  setInfoDriverDashboard,
  setBankAccountLink,
  setAllAppen,
  setDetailsSalaryPaymentStatements,
  loading,
  setMainProfitSubCategorySpecialTotal,
  setMainExSubCategorySpecialTotal,
  setInfoForDashboard,
  setTransferSalaryList,
  setMainAssetTakenDetails,
  setAllMfoBank,
  setMainProfitSorted,
  setMainProfitCategory,
  setMainProfit,
  ShowTest,
  setPayrolListDetails,
  setMainProfitSubCategory,
  setAllOwner,
  setAllStaffIllnesses,
  setStaffVacations,
  setRegionByOblast,
  setAllNonEmployedStaff,
  setAllStaff,
  setOblast,
  setIncomeList,
  setAllPersonal,
  staffPositionsCommit,
  staffDepartmentsCommit,
  ProviderContractPaymentCommit,
  providerContractAktSverkaIDCommit,
  providerContractAktSverkaCommit,
  providerContractDeliveredZayavkasCommit,
  changeDashboardTruckListValue,
  counteragentContractsCommit,
  setAllZayavkaDatas,
  kontragentContractAktSverkaIDCommit,
  kontragentContractAktSverkaCommit,
  getZayavkaDriversCommit,
  countragentKontractsSverkaCommit,
  countragentKontractsPaymentsCommit,
  countragentKontractFacturasCommit,
  setProviderSearch,
  providerContractZayavkaCommit,
  setProviderContractById,
  truckListallCommit,
  staffListCommit,
  setCountragentKontractFacturas,
  mainassetsDetailsCommit,
  setDeliveredOutfacture,
  setStandartContAppen,
  setDriverDetails,
  setAllDriversById,
  mainAssetsCommit,
  setDailyAmortizationMainAssets,
  setLicensesById,
  setInsuranceTruckDetails,
  getmainassetsCommit,
  setDriverPermits,
  setNomenklaturaGroup,
  setDriverPermitsDetails,
  setLicenses,
  setNomenklaturaAll,
  setAdvertPermits,
  setNomenklatura,
  setMeasurements,
  setGasEquipsDetails,
  setFilterData,
  setTotalSum,
  setIncomeListDetails,
  setAllActRecont,
  setAllActRecontDetails,
  setTransferDetails,
  setRealizations,
  setCountragentKontractsPayment,
  setAllDriversRevenues,
  setTypeOfAction,
  setAllZayavkaPag,
  setAllTransferList,
  setInsuarences,
  setClientPerPage,
  setMainAssetsDocDetails,
  setCountragentKontracts,
  setAllSkladList,
  setContract,
  setSkladList,
  setMainassetsNotTaken,
  setFactureBycontract,
  setCurrCompJob,
  setOneClientDetails,
  setExpenditureIncomeTotal,
  setDoneWorks,
  setAdvertPermitsDetails,
  setServiceCash,
  setFindSellMainAsset,
  setKontragentsContractsListWithoutPg,
  setOperByCard,
  setOneClientContract,
  setZaById,
  setGoodsServicesImport,
  setPayrolList,
  setGasEquips,
  setDailyAmortizationUnCalc,
  setAllFacturas,
  setTillDateList,
  setCorpCardsDetails,
  setTransactionsDailyDetails,
  setAllCorCards,
  setStaffEmployedList,
  setTnnId,
  setCorpCardDetails,
  setGeneralPaymentTypes,
  setAllCities,
  setAllTnn,
  activateZayavkaModal,
  setTruckTypesId,
  setAlltransactionsDaily,
  setAllZayavkaById,
  setPaymentSource,
  setProviderContract,
  setTruckLists,
  setFinanceBalance,
  setCategoryGroupData,
  setAllMainExp,
  setAllZayavka,
  getContractById,
  setAnnualRemark,
  setAllAdditionalContracts,
  setStaffList,
  setIncomesFromService,
  setAllProvidersWithoutPg,
  setAllMarshrut,
  setBalansAccountLists,
  setFacturaDisplay,
  setIsLoading,
  setZayavkaItem,
  setCategoryGroupNames,
  // setClientDetails,
  setProductCondition,
  setNds,
  setContractDetails,
  setAllDrivers,
  setAllProviders,
  setPaymentStatements,
  setAllTransferListCash,
  setExpenditureDashboard,
  setProductDetails,
  setKontragentsContractsList,
  setTruckEmptyById,
  setMainProfitSubCategorySpecial,
  setAllTruckTypes,
  setAllUsers,
  setProviderDetails,
  setZayavkaDetail,
  mainAssetsCommitTaken,
  setTechMonitor,
  setProvodkaList,
  setAllProvidersContract,
  setAllGroups,
  setAllContracts,
  setCorpCardsOper,
  setTypeStateTransfers,
  setFacturaDetail,
  setNotLinkedTotruck,
  setProductsList,
  setallCorpCardDetal,
  setKontragentsList,
  setCorpCardsOperList,
  getProviderById,
  setMainAssetsRemarks,
  setAllBrands,
  setRegions,
  serivesPaymentSource,
  setMainAssetRealizationFacturas,
  setOwnership,
  setStaffEmployedListForMonth,
  setClientStatus,
  settNameOfContract,
  setOperationsBycontract,
  setMainExpenditures,
  setProviderContractDetail,
  setStoredproductsForExport,
  setPaymentOrderTypes,
  setPositions,
  setLicenceTypes,
  setAllClientsName,
  setTechMonitorDetails,
  setAllTrcukBrands,
  setPerPage,
  setDoneWorksCount,
  setAllRegions
  // DriversbyId
}
