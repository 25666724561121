import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth.module'
import map from './map/index'
import htmlClass from './htmlclass.module'
import config from './config.module'
import breadcrumbs from './breadcrumbs.module'
import expenses from './expenses/index'
import requests from './requests/index'
import conditionOfGoods from './conditionOfGoods/index'
import accounts from './accounts/index'
import clientStore from './clientStore/index'
import driverStore from './driverStore/index'
import reportsStore from './reports/index'
import trucksStore from './trucks/index'
import generalStore from './general/index'
import dashboardStore from './dashboard'
import providerStore from './providers'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    map,
    auth,
    requests,
    accounts,
    expenses,
    conditionOfGoods,
    clientStore,
    driverStore,
    htmlClass,
    config,
    breadcrumbs,
    reportsStore,
    trucksStore,
    generalStore,
    dashboardStore,
    providerStore
  }
})
