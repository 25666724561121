// Uzbek
// Russian
export const locale = {
  ACTION: {
    DETAILS: 'Batafsil',
    DELETE: 'Ochirish',
    UPDATE: "O'zgartirish",
    CHANGE_STATUS: "Status o'zgartirish"
  },
  BUTTONS: {
    ADD_TOILOVA: 'Ilovaga qoshish',
    FILTER: "Filtr"
  },
  FORM: {
    SUBMIT: 'Saqlash',
    CANCEL: 'Bekor qilish'
  },
  INPUTS: {
    CLIENT_TYPE_JI: 'Jismoniy shaxs',
    CLIENT_TYPE_YU: 'Yuridik shaxs',
    CLIENT_TYPE: 'Mijoz turi',
    LOGIN: 'Username',
    PASSPORT: 'Parol',
    REPASSPORT: 'Parolni tasdiqland',
    JI_INFO: 'Jismoniy shaxs malumotlari',
    YU_INFO: 'Yuridik shaxs malumotlari',
    CLIENT_NAME: 'Mijoz nomi',
    STATUS: 'Status',
    PASSPORT_NUMBER: 'Pasport raqami',
    OBLAST: 'Oblast',
    REGION: 'Region',
    ADDRESS: 'Manzil',
    NDS_NUMBER: 'QQS',
    MFO: 'MFO',
    BANK_NUMBER: 'Bank hisob raqami',
    STREET: 'Kocha',
    DOP_ADDRESS: "Qo'shimcha manzil",
    CLIENT_FORM: 'Mulchilik shakli',
    FIO: 'FIO',
    PHONE_NUMBER: 'Telefon raqam',
    DIRECTOR: 'Direktor',
    RESPONSIBLE_STAFF: "Mas'ul xodim",
    POSITION: 'Lavozim',
    BANK_INFO: 'Bank malumotlari',
    CONTRACTS_INFO: 'Kontakt malumotlari',
    PAYMENT_TYPE: "To'lov turi",
    CONTRACT_AMOUNT: 'Shartnoma summasi',
    CONTRACT_ENDDATE: 'Amal qilish muddati',
    CITIES: 'Shaharlar',
    CITY: 'Shahar',
    SELECT_TRUCK_TYPE: 'Yuk mashinani turi',
    SUPPLIER: 'Yetkazib beruvchi',
    SELECT_TRUCKTYPE: 'Yukmashinani tanlang',
    MAIN_DRIVER: 'Asosiy haydovchi',
    MAIN_DRIVER_SECOND: 'Yordamchi haydovchi',
    KG_WEIGHT: "Yuk og'irligi - kg",
    m3_WEIGHT: 'Yuk hajmi - m3',
    SUPPLIER_POS: 'Yetqazib beruvchi: Boshqalar',
    SUPPLIER_POS_NUM: 'Postavshik mashina malumoti',
    PROVIDER_CONTRACTS: 'Postavshik kontraktlari',
    DRIVER_NAME: 'Haydovchi ismi',
    SHIPPING_DATE: 'Yuk ketish sanasi',
    SHIPPING_DATETIME: 'Vaqt',
    DELIVERY_DATE: 'Yuk yetib borish sanasi',
    ORDER_REGISTRATION: 'Buyurtma registratsiya',
    REPORT_START: 'Hisobot boshlanish',
    REPORT_END: 'Hisobot tugash'
  },
  AKT_SVERKA: {
    REPORT_DATE: 'Hisobot sanasi',
    REPORT_DATE_START: 'Hisobot boshi',
    REPORT_DATE_END: 'Hisobot oxiri',
    BTS_DEBET_SALDO_START: 'BTS debet saldo boshida',
    BTS_KREDIT_SALDO_START: 'BTS kredit saldo boshida',
    CLIENT_DEBET_SALDO_START: 'Mijoz debet saldo boshida',
    CLIENT_KREDIT_SALDO_START: 'Mijoz kredit saldo boshida',
    BTS_DEBET_SALDO_END: 'BTS debet saldo oxirida',
    BTS_KREDIT_SALDO_END: 'BTS kredit saldo oxiri',
    CLIENT_DEBET_SALDO_END: 'Mijoz debet saldo oxiri',
    CLIENT_KREDIT_SALDO_END: 'Mijoz kredit saldo oxiri',
    CONTRACT_NUMBER: 'Kontrakt raqam',
    CREATED: 'Yaratilgan sana'
  },
  TABLE_HEADER: {
    DATE: 'Sana',
    CLIENT: 'Mijoz',
    INN: 'INN',
    OKED: 'OKED',
    REGION: 'Region',
    PROVINCE: 'Oblast',
    TYPE: 'Turi',
    STATUS: 'Status',
    CONTRACT: 'Shartnoma',
    START_DATE: 'Boshlanish',
    CONTRACT_NUMBER: 'Shartnoma raqami',
    PROVIDER_CONTRACT_NUMBER: 'Postavshik shartnoma raqami',
    REG_NUMBER: 'Reg raqam',
    END_DATE: 'Tugash',
    AMOUNT: 'Summa',
    NUMBER: 'Raqami',
    CATEGORY: 'kategoriya',
    SHAPE: 'Shakli',
    NAME: 'Nomi',
    CREATE_DATE: 'Yatatilgan sana',
    UPDATE_DATE: "O'zgartirilgan sana",
    PROVIDER: 'Postavshik',
    PRICE: 'Narxi',
    ALLZAYAVKA_PRICE_WITH_NDS: 'BTS narx (QQS bilan)',
    ALLZAYAVKA_PRICE_WITH_NDS_POSTAVSHIK: 'POS narx(QQS bilan)',
    TRUCK_NUMBER: 'Mashina raqami',
    LOCATION: 'Lokatsiya',
    DIRECTION: "Yo'nalish",
    NDS: 'QQS',
    ITEM: 'Soni',
    PRICE_WITH_NDS: 'Summa(QQS)',
    CONTRAGENT: 'Kontragent',
    CON_CONTRAGENT: 'Kontragent Shartnoma',
    EXPENDITURE: 'Xarajat',
    NDSAMOUNT: 'QQS Summa ',
    NDS_AMOUNT: 'Summa(QQS)',
    SALER: 'Sotuvchi',
    CARD_NUMBER: 'Karta raqami',
    OPERATION_TYPE: 'Operatsiya turi',
    BRAND: 'Brand',
    MODEL: 'Model',
    BOUGHT_DATE: 'Sotib olingan',
    MAIN_ASSETS: 'Asosiy vosita',
    DOCS: 'Hujjat',
    PRICE_PER_KM_MAINDRIVER: 'Asosiy haydovchi uchun km',
    PRICE_PER_KM_ASSISTANT: 'Yordamchi uchun km',
    MONITORED_BY: 'Tekshiruvchi',
    EXPIRES: 'Amal qilish sanasi',
    MONITORED: 'Tekshiruv sanasi',
    LICENCE_TYPE: 'Sertifikat turi',
    DRIVER: 'haydovchi',
    COMMENT: 'Izoh',
    TOTAL: 'Jami',
    STORE: 'Sklad',
    YEAR: 'Yil',
    FIRSTNAME: 'Ism',
    SURNAME: 'Familiya',
    LASTNAME: 'Otasining ismi',
    PASSPORT_NUMBER: 'Telefon raqami',
    PHONE_NUMBER: 'Pasport raqami',
    INSP: 'INPS',
    PINFL: 'PINFL',
    MAIL_ADDRESS: 'Pochta manzili',
    STAFF_DEPARTMENT: 'Departament',
    EMPLOYED_FROM: 'Ishga qabul kilingan sana',
    TABEL_NUMBER: 'Tabel raqami',
    STAFF_OKLAD: 'Oklad',
    STAFF_POSITION: 'Lavozim',
    STAFF_NAME_SURNAME: 'Ism Familiya',
    PAYMENT_STATUS: "To'lov statusi",
    DELIVERED_ZAYAVKAS_AMOUNT: 'Tugallangan zavavkalar soni',
    DELIVERED_ZAYAVKAS_PREMIYE_TOTAL: 'Tugallangan zavavkalar premiyasi',
    AMOUNT_EXPENDITURE: 'Xarajat summa',
    EXPENDITURE_TYPE: 'Xarajat turi',
    PLAN_YEAR: 'Yil uchun daromad reja',
    JURIDIK_ADDRESS: 'Adres',
    CONTRACT_TYPE: 'Shartnoma turi',
    CODE: 'Kod',
    MEASUREMENTS_NAME: "O'lchov nomi",
    NOMENCLATURE_NAME: 'Nomenklatura nomi',
    RESPONSIBLE_NAME: "Mas'ul nomi",
    MAIN_INCOME: 'Asosiy daromad',
    MAIN_INCOME_CAT: 'Kategoriya Nomi',
    MAIN_INCOME_SUBCAT: 'SubKategoriya Nomi'
  },
  DASHBOARD: {
    TRUCKS: {
      FREE: 'Avtomashina',
      ORD: '',
      SER: '',
      BRO: ''
    }
  },
  PAGES: {
    HI: 'Salom',
    GOBACK: 'Orqaga',
    CHECK: 'Tekshirish',
    DATE: 'Sana'
  },
  CREATE: {
    CARD: 'Karta',
    TRUCK: 'Avtomashina'
  },
  BREADCRUMBS: {
    TO_EXCEL: 'Excel',
    BANK: 'Bank',
    CASH: 'Kassa',
    SOLD: 'Sotuv',
    HOME: 'Bosh sahifa',
    CLIENT: 'Mijoz',
    SEARCH: 'Izlash',
    CLIENTS: 'Mijozlar',
    CONTRACT: 'Shartnoma',
    CLEAN: 'Tozalash',
    CONTRACTS: 'Shartnomalar',
    ILOVA: 'Standart ilova',
    TTN: 'TTN',
    ORDER: 'Buyurtma',
    ACT_SVERKA: 'Akt sverka',
    ORDERS: 'Buyurtmalar',
    CLIENT_REG: 'Yangi mijozni ruyhatga olish',
    DONE_WORKS: 'Bajarilgan ishlar (Fakturasiz)',
    CONTRACT_REG: 'Shartnoma registratsiyasi'
  },
  REG_FORMS: {
    PASSWORD: 'Parol',
    REPEAT_PASS: 'Parolni tasdiqlang'
  },
  MENU: {
    HOME: 'Bosh sahifa',
    SALE: {
      TITLE: 'Sotuv',
      CLIENTS: 'Mijozlar',
      CONTRACTS: 'Shartnomalar',
      ILOVA_APP: 'Standart ilova',
      TTN: 'TTN',
      ORDERS: 'Buyurtmalar',
      COMPLETED_JOBS: 'Bajarilgan ishlar(Fakturasiz)',
      COMPLETED_JOBS_WITHOUT_FK: ' Bajarilgan ishlar(Fakturali) ',
      ACCOUNT_INVESTMENT: 'Hisob fakturalar(sotuv)',
      ACT_SVERKA: 'Akt sverkalar',
      DEBT: 'Qarzdorliklar'
    },
    PURCHASE: {
      TITLE: 'Sotib olish',
      RECEIPT_GOODS_SERVICES: {
        TITLE: 'Tovar va xizmatlar kirimi',
        BANK: 'TXK( bank)',
        KASSA: 'TXK(kassa)',
        CARD: 'TXK(korpartiv  karta)'
      },
      TAKEN: 'AV sotib olish(AV ga olinmagan)',
      NOTTAKEN: 'AV sotib olish(AV ga olingan)'
    },
    BANK_KASSA: {
      TITLE: 'Bank-kassa',
      VIPISKA: 'Vipiska',
      CARDS: {
        TITLE: 'Korporativ karta',
        CARD: 'Korporativ kartalar',
        CARD_OPER: 'Operatsiyalar'
      }
    },
    MAIN_ASSETS: {
      TITLE: 'Asosiy vositalar',
      TRANSPORT: {
        TITLE: 'Transport',
        ALL: 'Avtomashinalar',
        TYPES: 'Avtomashina turlari',
        BRAND: 'Mashina brendlari'
      },
      TRANSPORT_DOC: {
        TITLE: "Hujjat ma'lumotlari",
        TEXNIK_KORISH: "Texnik ko'rik",
        GBO: 'Gaz hujjatlari',
        ADVERTISIN_PERMISSION: 'Reklama ruxsatnomasi',
        LICENSE: 'Litsenziya',
        INSURANCE: "Sug'urta",
        CERTIFICATE: 'Ishonchnoma'
      },
      MAIN_ASSETS_TITLE: 'AV',
      REVALUATION_AV: 'AV qayta baholash'
    },
    STORE: {
      TITLE: 'Ombor',
      WRITE_OFF: 'Hisobdan chiqarish',
      TRANSFER: 'Transfer',
      REALIZATION: 'Sotish',
      SALE_MAIN_ASSETS: 'AV sotish'
    },
    STAFF: {
      TITLE: 'Xodimlar',
      JS: 'Jismoniy shaxslar',
      STAFF: 'Xodimlar',
      VACATION: "Mehnat ta'tili",
      ILNESS: 'Kasallik varaqasi',
      DRIVERS: 'Haydovchilar'
    },
    SALARY: {
      TITLE: 'Ish haqi',
      CALC: 'Hisob kitob qaydnomasi',
      PAY: "To'lov qaydnomasi",
      DRIVER_PREM: 'Haydovchilar premiyalari'
    },
    REPORT: {
      TITLE: 'Hisobotlar',
      FINANCE_REPORT: {
        TITLE: 'Moliyaviy hisobotlar',
        FINANCE_RESULT: 'Moliyaviy natijalar',
        FINANCE_RESULT_FORM30: 'Moliyaviy natijalar(form 30)',
        BALANS: 'Balans',
        LAST_MATERIALS: 'Oxirgi-Materiallar hisoboti',
        INCOMES: 'Daromadlar',
        EXPENDITURE: 'Xarajatlar',
        INCOMES_EXPENDITURE: 'Daromadlar va хarajatlar v2',
        PROVODKA: 'Provodkalar'
      },
      PLAN_VIEW: {
        TITLE: "Reja ko'rsatkichlari",
        SERVICE_PLAN: 'Daromad rejasi'
      }
    },
    ENTERPRICE: {
      TITLE: 'Korxona',
      CONTRAGENT: {
        TITLE: 'Kontragentlar',
        LIST: 'Kontragentlar',
        CONTRACTS: 'Kontragentlar kontrakti'
      },
      PROVIDERS: {
        TITLE: 'Postavshiklar',
        LIST: 'Postavshiklar',
        CONTRACTS: 'Postavshiklar shartnоmalari'
      },
      STORE: {
        TITLE: 'Ombor',
        PRODUCT_CONDITION: 'Tovarlar holati',
        MEASUREMENTS: "O'lchov birliklari",
        NOMENCLATURE: 'Nomenklatura',
        NOMENCLATURE_GROUP: 'Nomenklatura gruppalari'
      },
      EXPENDITURE: {
        TITLE: 'Xarajatlar',
        MAIN_ASSETS: 'Asosiy xarajatlar',
        CATEGORY: 'Xarajat kategoriyalari',
        SUBCATEGORY: 'Xarajat subkategoriyalari',
        GENERAL_INFO: "Umumiy ma'lumot",
        PAYMENT_TYPE: "To'lov turlari"
      },
      INCOMES: {
        TITLE: 'Daromadlar',
        MAIN: 'Asosiy daromadlar',
        CATEGORY: 'Daromad kategoriyalari',
        SUBCATEGORY: 'Daromad subkategoriyalari',
        GENERAL_INFO: "Umumiy ma'lumot"
      },
      STAFF: {
        TITLE: 'Xodimlar',
        POSITION: 'Хodimlar lavozimlari',
        DEPARTMENTS: "Korxona bo'limlari"
      },
      CLIENTS: {
        TITLE: 'Mijozlar',
        FORM: 'Mulkchilik shakli'
      },
      STORES: {
        TITLE: 'Omborlar'
      }
    },
    DASHBOARD: 'Главная',
    CLIENTS: 'Отдел клиентов',
    ALLCLIENTS: 'Клиенты',
    CONTRACTS: 'Отдел договоров',
    ALLCONTRACTS: 'Договора',
    STAFFDEPARTMENT: 'Кадры',
    PERSONAL: 'Физические лица',
    STANDART_APP: 'Стандарт приложение',
    ALLSTAFF: 'Сотрудники',
    ALLVACATION: 'Отпуск',
    ALLILLNESS: 'Больничный',
    ORDERS: {
      TITLE: 'Заявки',
      ALLORDERS: 'Заявки',
      ALLTTN: 'ТТН',
      DONEWORKS_WITHOUT_FACTURE: 'Выполненные работы(без сч-ф.)'
    },
    ACCOUNT_INVESTMENT: {
      TITLE: 'Счёт фактуры(реализация)',
      ALLACCOUNT_IMVERSTMENT: 'Счёт фактуры(реализация)',
      AKT_SVERKA: 'Акт сверки',
      ALLDEBT: 'Задолженности',
      ALLDONEWORK: 'Выполненные работы'
    },
    DRIVERDEPARTMENT: {
      TITLE: 'Водители',
      ALLDRIVERS: 'Водители',
      DRIVERSPREMIYA: 'Премия водителей'
    },
    TRANSPORT: {
      TITLE: 'Транспорт',
      ALLTRUCKS: 'Автомашины',
      TRUCKTYPES: 'Марки авто',
      TRUCKBRAND: 'Бренды авто',
      TRUCKDOCUMENTS: {
        TITLE: 'Документы автомашин',
        TECHNICAL_INSPECTION: 'Техосмотр',
        GAS_EQUIPMENT_DOCUMENTATION: 'Документы ГБО',
        ADVERTISIN_PERMISSION: 'Разрешение для рекламы',
        LICENSE: 'Лицензия',
        INSURANCE: 'Страхование',
        CERTIFICATE: 'Доверенность'
      }
    },
    FINANCE: {
      TITLE: 'Финансы',
      TAKEN: 'Покупка ОС(принято в ОС)',
      NOTTAKEN: 'Покупка ОС(не принято в ОС)',
      MAIN_ASSETS: 'Основные средства'
    },
    WAREHOUSE: {
      TITLE: 'Склад',
      ALLWAREHOUSE: 'Список складов',
      KIRIM: {
        TITLE: 'Поступление товаров и услуг (кирим)',
        BANK: '',
        CONT: '',
        CORCARDS: ''
      },
      CHIQIM: {
        TITLE: 'Поступление товаров и услуг (чиким)',
        WRITE_OFF: '',
        TRANSFER: '',
        REALIZATION: ''
      }
    },
    PROVIDERS: {
      TITLE: 'Поставщики',
      LIST: 'Список поставщиков',
      CONTRACTS: 'Контракт поставщиков',
      ACT_SVERKA: 'Акт сферка'
    },
    COINTERPARTY: {
      TITLE: 'Контрагент',
      LIST: 'Список контрагентов',
      CONTRACTS: 'Список контрактов',
      ACT_SVERKA: 'Акт сверка'
    },
    // REPORT: {
    //   TITLE: 'Отчеты',
    //   WAREHOUSE: {
    //     TITLE: 'Склад',
    //     MATERIAL_REPORT_LAST: 'Конец материального отчета'
    //   }
    // },
    GENERAL_SECTION: {
      TITLE: 'Общий раздел',
      WAREHOUSE: {
        TITLE: 'Склад',
        PRODUCT_CONDITION: 'Состояние товаров',
        MEASUREMENTS: 'Измерения',
        NOMENCLATURE: 'Номенклатура',
        NOMENCLATURE_GROUP: 'Номенклатура группа'
      },
      FINANCE: {
        TITLE: 'Финансы',
        COST: {
          TITLE: 'Расход',
          MAINCOST: 'Основной расход',
          CATEGORY: 'Категория',
          SUBCATEGORY: 'Суб-категория',
          GENERAL_INFO: 'Основная информация'
        },
        INCOME: {
          TITLE: 'Доход',
          MAIN_INCOME: 'Основной доход',
          CATEGORY: 'Категория',
          SUBCATEGORY: 'Суб-категория',
          ALL_INCOME: 'Весь доход'
        }
      },
      STAFF: {
        TITLE: 'Сотрудники',
        POSITION: 'Должности сотрудников',
        DEPARTMENT: 'Отдел кадров'
      },
      CLIENTS: {
        TITLE: 'Клиент',
        FORM_PROPERTY: 'Форма имущества'
      }
    }
  }
}
