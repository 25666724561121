import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: () => import('@/view/layout/Layout'),
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          component: () => import('@/view/pages/Dashboard')
        },
        {
          path: '/dashboard2',
          name: 'dashboard2',
          component: () => import('@/view/pages/Dashboard_chart')
        },
        // client router
        {
          path: '/clientcontracts',
          component: () => import('@/view/pages/clientPage/index')
        },
        {
          path: '/clientcontractdetails/:id',
          component: () => import('@/view/pages/clientPage/details')
        },
        {
          path: '/clientzayavkalist/',
          component: () => import('@/view/pages/clientPage/zayavkaList/index')
        },
        {
          path: '/clientzayavkadetails/:id',
          component: () => import('@/view/pages/clientPage/zayavkaList/details')
        },
        {
          path: '/clientfactures/',
          component: () => import('@/view/pages/clientPage/hisobfaktura/index')
        },
        {
          path: '/clientfacturedetails/:id',
          component: () =>
            import('@/view/pages/clientPage/hisobfaktura/details')
        },
        {
          path: '/clientfacturedetailtable/:id',
          component: () =>
            import(
              '@/view/pages/clientPage/hisobfaktura/faktura-korish/details'
            )
        },
        {
          path: '/clientcompletedworks/:id',
          component: () =>
            import(
              '@/view/pages/clientPage/hisobfaktura/completedworks/details'
            )
        },
        // driver routers
        {
          path: '/driverzayavkas/',
          name: 'driverzayavkas',
          component: () => import('@/view/pages/driverPage/index')
        },

        {
          path: '/table2',
          name: 'Test1234',
          component: () => import('@/view/pages/Test3Table')
        },
        {
          path: '/account_results',
          name: 'Test 123',
          component: () => import('@/view/pages/test2')
        },
        {
          path: '/salary_payrol',
          name: 'SalaryPayrol',
          component: () => import('@/view/pages/finance/SalaryPayrol/Index')
        },
        {
          path: '/payment_statements',
          name: 'paymentStatemts',
          component: () =>
            import('@/view/pages/finance/PaymentStatements/Index')
        },
        {
          path: '/payment_statements/create',
          name: 'paymentStatemtsCreate',
          component: () =>
            import('@/view/pages/finance/PaymentStatements/Create')
        },
        {
          path: '/salary_payrol/details/:id',
          name: 'SalaryPayrolDetails',
          component: () => import('@/view/pages/finance/SalaryPayrol/Details')
        },
        {
          path: 'payment_statements/Details/:id',
          name: 'paymentStatementsDetails/',
          component: () =>
            import('@/view/pages/finance/PaymentStatements/details')
        },
        {
          path: 'payment_statements/update/:id',
          name: 'Payment Statements Updates/',
          component: () =>
            import('@/view/pages/finance/PaymentStatements/Update')
        },
        {
          path: '/salary_payrol/create',
          name: 'SalaryPayrolCreate',
          component: () => import('@/view/pages/finance/SalaryPayrol/Create')
        },
        {
          path: '/balance',
          name: 'Test Table',
          component: () => import('@/view/pages/finance/Balance/Index')
        },
        // newBalanceV1
        {
          path: '/newbalancev1',
          name: 'newBalanceV1',
          component: () => import('@/view/pages/newBalanceV1')
        },
        {
          path: '/provodka',
          name: 'Provodka Table',
          component: () => import('@/view/pages/finance/Provodka/Index')
        },
        {
          path: '/provodka/create',
          name: 'Provodka Create',
          component: () => import('@/view/pages/finance/Provodka/Create')
        },
        // {
        //   path: '/groups',
        //   name: 'Groups',
        //   component: () => import('@/view/pages/groups/groups.vue')
        // },
        {
          path: '/clientreg',
          name: 'clientreg',
          component: () => import('@/view/pages/clients/clientreg.vue')
        },
        {
          path: '/allbrands',
          name: 'allbrand',
          component: () => import('@/view/pages/transport/Brand/Index')
        },
        {
          path: '/completedworks/:id',
          name: 'completedworks',
          component: () =>
            import('@/view/pages/clients/Accounting/completedWorks')
        },

        {
          path: '/staffvacations',
          name: 'staff_vacations',
          component: () => import('@/view/pages/staff/vacation/index.vue')
        },
        {
          path: '/staffillnesses',
          name: 'staff_ilnesses',
          component: () => import('@/view/pages/staff/staffIllnesses/index.vue')
        },
        {
          path: '/staffdepartments',
          name: 'staff_departments',
          component: () =>
            import(
              '@/view/pages/general-section/staffDepartments/staffDepartments.vue'
            )
        },
        {
          path: '/staffpositions',
          name: 'staff_positions',
          component: () =>
            import(
              '@/view/pages/general-section/staffPosition/staffPosition.vue'
            )
        },
        {
          path: '/allpersonal',
          name: 'all_personal',
          component: () =>
            import('@/view/pages/staff/allPersonal/allPersonal.vue')
        },
        {
          path: '/allpersonal/create',
          name: 'Personal Create',
          component: () => import('@/view/pages/staff/allPersonal/create')
        },
        {
          path: '/allstaff',
          name: 'all_staff',
          component: () => import('@/view/pages/staff/allStaff/allStaff.vue')
        },
        {
          path: '/alldrivers',
          name: 'all_driver',
          component: () => import('@/view/pages/staff/drivers/index.vue')
        },
        {
          path: '/alldrivers/revenues',
          name: 'all_driver_revenues',
          component: () => import('@/view/pages/staff/drivers/Ravenues/Index')
        },
        {
          path: '/counteragentdetails/:id',
          name: 'counteragent_details',
          props: true,
          component: () =>
            import('@/view/pages/kontragents/kontragentsList/details.vue')
        },
        {
          path: '/addbrand',
          name: 'addbrand',
          component: () => import('@/view/pages/transport/Brand/Create')
        },
        {
          path: '/driverdetails/:id',
          name: 'driver_details',
          props: true,
          component: () => import('@/view/pages/staff/drivers/details.vue')
        },
        {
          path: '/clientdetails/:id',
          name: 'clientdetails',
          component: () => import('@/view/pages/users/clientdetails.vue')
        },
        {
          path: '/client-update/:id',
          name: 'Clinet Update',
          component: () => import('@/view/pages/clients/ClinetUpdate')
        },

        {
          path: '/general/truckbrands/list/',
          name: 'truck_brands',
          component: () => import('@/view/pages/general/TruckBrands.vue')
        },

        {
          path: '/general/payment-types',
          name: 'Payment types',
          component: () => import('@/view/pages/general/PaymentTypes.vue')
        },

        {
          path: '/kontragents/contract/aktsverka/',
          name: 'Kontragent_contract_sverka',
          component: () => import('@/view/pages/kontragents/sverka/akt_sverka')
        },
        {
          path: '/providerscontractaktsverka/',
          name: 'providers_contract_aktsverka',
          component: () => import('@/view/pages/providers/akt-sverka/aktsverka')
        },
        {
          path: '/kontragents/contract/aktsverka/details/:id',
          name: 'Kontragent_contract_sverka_details',
          component: () => import('@/view/pages/kontragents/sverka/details')
        },
        {
          path: '/providers/contract/aktsverka/details/:id',
          name: 'Kontragent_contract_sverka_details_id',
          component: () => import('@/view/pages/providers/akt-sverka/details')
        },
        {
          path: '/allcontracts',
          redirect: '/allcontracts/1',
          name: 'Allcontracts',
          component: () =>
            import('@/view/pages/clients/contracts/allContracts.vue'),
          children: [
            {
              path: '/allcontracts/:id',
              name: 'Allcontracts Page',
              component: () =>
                import('@/view/pages/clients/contracts/allContractsPage.vue')
            }
          ]
        },
        {
          path: '/kontragents/contract',
          redirect: '/kontragents/contract/1',
          name: 'Kontragents Contract',
          component: () => import('@/view/pages/kontragents/Contracts/index'),
          children: [
            {
              path: '/kontragents/contract/:id',
              name: 'Kontragents Contract Page',
              component: () => import('@/view/pages/kontragents/Contracts/Page')
            }
          ]
        },
        {
          path: '/kontragents/contract/details/:id',
          name: 'Kontragents Contract Details',
          component: () => import('@/view/pages/kontragents/Contracts/Details')
        },
        {
          path: '/kontragents/contract/update/:id',
          name: 'Kontragents Contract Update',
          component: () => import('@/view/pages/kontragents/Contracts/Update')
        },
        {
          path: '/addZayavka',
          name: 'addZayavka',
          component: () => import('@/view/pages/zayavka/Create')
        },
        {
          path: '/driverreg',
          name: 'driverreg',
          component: () => import('@/view/pages/staff/addDrivers.vue')
        },
        {
          path: '/alltrucktypes',
          name: 'alltrucktypes',
          component: () => import('@/view/pages/transport/TruckType/index')
        },
        {
          path: '/truckdetails/:id',
          name: 'truckdetails',
          component: () => import('@/view/pages/transport/truckdetails.vue')
        },
        {
          path: '/truckupdate/:id',
          name: 'truckupdate',
          component: () => import('@/view/pages/transport/truckupdate.vue')
        },
        {
          path: '/allproviders',
          name: 'allproviders',
          component: () => import('@/view/pages/providers/allproviders.vue')
        },
        {
          path: '/addprovidercontract',
          name: 'addprovidercontract',
          component: () =>
            import('@/view/pages/providers/addProviderContract.vue')
        },
        {
          path: '/dopprovidercontract/:id',
          name: 'dopprovidercontract',
          component: () =>
            import('@/view/pages/providers/dopProviderContract.vue')
        },
        {
          path: '/providercontractdetail/:id',
          name: 'dopprovidercontractdetail',
          component: () =>
            import('@/view/pages/providers/providerContractDetail.vue')
        },
        {
          path: '/providercontractedit/:id',
          name: 'providercontractedit',
          component: () =>
            import('@/view/pages/providers/providerContractEdit.vue')
        },
        {
          path: '/contractreg',
          name: 'contractreg',
          component: () =>
            import('@/view/pages/clients/contracts/contractreg.vue')
        },
        {
          path: '/contractdetails/:id',
          name: 'contractdetails',
          component: () =>
            import('@/view/pages/clients/contracts/contractdetails.vue')
        },
        {
          path: '/addContract/:id',
          name: 'addContract',
          component: () =>
            import('@/view/pages/clients/contracts/addContract.vue')
        },
        {
          path: '/contractupdate/:id',
          name: 'contractupdate',
          component: () =>
            import('@/view/pages/clients/contracts/contractupdate.vue')
        },
        {
          path: '/allfactura',
          name: 'Allfactura',
          redirect: '/allfactura/1',
          component: () =>
            import('@/view/pages/clients/Accounting/allFactura.vue'),
          children: [
            {
              path: '/allfactura/:id',
              name: 'Allfactura Page',
              component: () =>
                import('@/view/pages/clients/Accounting/allFacturaPage.vue')
            }
          ]
        },
        {
          path: '/facturadetail/:id',
          name: 'Facturadetail',
          component: () =>
            import('@/view/pages/clients/Accounting/facturaDetail.vue')
        },
        {
          path: '/facturadetailtable/:id',
          name: 'facturadetail Page',
          component: () =>
            import('@/view/pages/clients/Accounting/facturaDetailTable.vue')
        },
        {
          path: '/zayavkareg',
          name: 'zayavkareg',
          component: () => import('@/view/pages/zayavka/Create.vue')
        },
        {
          path: '/driverdetails/:id',
          name: 'driverdetails',
          component: () => import('@/view/pages/staff/driverdetails.vue')
        },
        {
          path: '/contractprice/:id',
          name: 'contractprice',
          component: () =>
            import('@/view/pages/clients/contracts/contractPrice.vue')
        },
        {
          path: '/providerdetails/:id',
          name: 'providerdetails',
          component: () => import('@/view/pages/providers/providerdetails.vue')
        },
        {
          path: '/providerreg',
          name: 'providerreg',
          component: () => import('@/view/pages/providers/providerreg')
        },
        {
          path: '/providerscontract',
          name: 'Providerscontract',
          component: () => import('@/view/pages/providers/allProviderContracts')
        },
        {
          path: '/allzayavka',
          name: 'allzayavka',
          redirect: '/allzayavka/1',
          component: () => import('@/view/pages/zayavka/Index.vue'),
          children: [
            {
              path: '/allzayavka/:id',
              name: 'allzayavkaPAge',
              component: () => import('@/view/pages/zayavka/Page')
            }
          ]
        },
        {
          path: '/clientallzayavka',
          name: 'clientallzayavka',
          redirect: '/clientallzayavka/1',
          component: () => import('@/view/pages/zayavka/Index.vue'),
          children: [
            {
              path: '/clientallzayavka',
              name: 'ClientCabinetForZayavkas',
              component: () => import('@/view/pages/zayavka/ClientCabinetForZayavkas'),
            }
          ]
        },
        {
          path: '/',
          component: () => import('@/view/layout/CreateAnnual'),
          children: [
            {
              path: '/zayavka/map/:id',
              name: 'Zayavka GPS map',
              meta: { layout: 'create-annual' },
              component: () => import('@/view/pages/zayavka/Map/Index')
            }
          ]
        },
        {
          path: '/zayavkadetail/:id',
          name: 'zayavkadetail',
          component: () => import('@/view/pages/zayavka/Details')
        },
        {
          path: '/newzayavkadetail/:id',
          name: 'newZayavkaDetail',
          component: () => import('@/view/pages/zayavka/newDetails')
        },
        {
          path: '/ttn',
          name: 'alltnn',
          component: () => import('@/view/pages/TTN/index')
        },
        {
          path: '/doneworksnofactura',
          name: 'Down works without factura',
          component: () =>
            import('@/view/pages/zayavka/DeliveredOutfacture/index')
        },
        {
          path: '/contractappenreg/:id',
          name: 'contractappenreg',
          component: () =>
            import('@/view/pages/clients/contracts/contractappendixreg.vue')
        },

        {
          path: '/checklinkwithbalansaccount',
          name: 'checklinkwithbalansaccount',
          component: () =>
            import('@/view/pages/finance/checklinkwithbalansaccount.vue')
        },
        {
          path: '/newcontractdetails/:id',
          name: 'newContractDetails',
          component: () =>
            import('@/view/pages/clients/contracts/newContractDetails.vue')
        },
        {
          path: '/contractdetails/appendix/:id',
          name: 'Contract appendix update',
          component: () =>
            import('@/view/pages/clients/contracts/contAppenUpdate/Index')
        },
        {
          path: '/trucklists',
          name: 'trucklists',
          component: () =>
            import('@/view/pages/transport/TruckList/allTruckList')
        },
        {
          path: '/toTrucklistPage',
          name: 'toTrucklistPage',
          component: () =>
            import('@/view/pages/dashboardComponents/toTrucklistPage')
        },
        {
          path: '/truckreg',
          name: 'truckreg',
          component: () => import('@/view/pages/transport/truckReg.vue')
        },
        {
          path: '/corcards',
          name: 'corcards',
          component: () => import('@/view/pages/finance/allCorCards.vue')
        },
        {
          path: '/mainexpenditures',
          name: 'mainexpenditures',
          component: () =>
            import('@/view/pages/general-section/mainExpenditures.vue')
        },
        {
          path: '/corcarddetails/:id',
          name: 'corcarddetails',
          component: () => import('@/view/pages/finance/corCardDetails.vue')
        },
        {
          path: '/corcardupdate/:id',
          name: 'corcardupdate',
          component: () => import('@/view/pages/finance/corCardUpdate.vue')
        },
        {
          path: '/addcorcard',
          name: 'addcorcard',
          component: () => import('@/view/pages/finance/addCorCard.vue')
        },
        {
          path: '/corcardsoper',
          name: 'corcardsoper',
          component: () =>
            import('@/view/pages/finance/CorpCardsOper/allCorpCardsOper.vue')
        },
        {
          path: '/addcorcardsoper',
          name: 'Add corcardsoper',
          component: () =>
            import('@/view/pages/finance/CorpCardsOper/addCorpCardOper.vue')
        },
        {
          path: '/corcardsoperdetails/:id',
          name: 'corcardsoperdetails',
          component: () =>
            import('@/view/pages/finance/CorpCardsOper/CorpCardOperDetails.vue')
        },
        {
          path: '/doneworks',
          name: 'doneworks',
          component: () => import('@/view/pages/zayavka/doneworks.vue')
        },
        {
          path: '/userpag',
          name: 'userpag',
          component: () => import('@/view/pages/clients/userspag.vue')
        },
        {
          path: '/vipiska',
          name: 'vipiska Bank',
          redirect: '/vipiska/bank',
          component: () => import('@/view/pages/finance/Vipiska/vipiska.vue'),
          children: [
            {
              path: '/vipiska/bank',
              name: 'vipiska Bank Page',
              component: () =>
                import('@/view/pages/finance/Vipiska/Bank/NewBank.vue')
            },
            {
              path: '/vipiska/cash',
              name: 'vipiska Cash',
              component: () =>
                import('@/view/pages/finance/Vipiska/Cash/NewCash.vue')
            }
          ]
        },
        {
          path: '/vipiska/bank/:source/:date',
          name: 'vipiska bank details',
          component: () => import('@/view/pages/finance/Vipiska/Bank/Details')
        },
        {
          path: '/vipiska/bank/:source/:date/:id',
          name: 'vipiskaBankDetails',
          component: () =>
            import('@/view/pages/finance/Vipiska/Bank/bankDetails.vue')
        },
        {
          path: '/vipiska/cash/:source/:date',
          name: 'vipiska Cash details',
          component: () => import('@/view/pages/finance/Vipiska/Cash/Details')
        },
        {
          path: '/zayavkaedit/:id',
          name: 'zayavkaedit',
          component: () => import('@/view/pages/zayavka/Update')
        },
        {
          path: '/vipiska_details_bank/:id',
          name: 'vipiskadetails',
          component: () =>
            import(
              '@/view/pages/finance/Vipiska/Vipiska01/vipiskadetails01.vue'
            )
        },
        {
          path: '/vipiska_details_cash/:id',
          name: 'vipiskadetails Id',
          component: () =>
            import(
              '@/view/pages/finance/Vipiska/Vipiska02/vipiskadetails02.vue'
            )
        },
        {
          path: '/vipiskaedit01/:id',
          name: 'vipiskadedit',
          component: () =>
            import('@/view/pages/finance/Vipiska/Vipiska01/vipiskaedit01.vue')
        },
        {
          path: '/vipiskaedit02/:id',
          name: 'vipiskadedit Id',
          component: () =>
            import('@/view/pages/finance/Vipiska/Vipiska02/vipiskaedit02.vue')
        },
        {
          path: '/actreconciliation/',
          name: 'actreconciliation',
          component: () =>
            import('@/view/pages/clients/invoice/allActReconciliation.vue')
        },
        {
          path: '/actrecondetails/:id',
          name: 'actrecondetails',
          component: () =>
            import('@/view/pages/clients/invoice/actReconDetails.vue')
        },
        {
          path: '/allUsers',
          name: 'All-Users',
          redirect: '/allUsers/1',
          component: () => import('@/view/pages/users/allUsers'),
          children: [
            {
              path: '/allUsers/:id',
              name: 'All-UsersPage',
              component: () => import('@/view/pages/users/allUsersPage')
            }
          ]
        },
        {
          path: '/sklad',
          name: 'Sklad',
          redirect: '/sklad/1',
          component: () => import('@/view/pages/sklad/skladList/SkladList'),
          children: [
            {
              path: '/sklad/:id',
              name: 'All-SkladPage',
              component: () =>
                import('@/view/pages/sklad/skladList/SkladListPage')
            }
          ]
        },
        {
          path: '/products',
          name: 'Products',
          redirect: '/products/1',
          component: () => import('@/view/pages/sklad/products/ProductsList'),
          children: [
            {
              path: '/products/:id',
              name: 'All-Products',
              component: () =>
                import('@/view/pages/sklad/products/ProductsPage')
            }
          ]
        },
        {
          path: '/servicecash',
          name: 'Service Cash',
          redirect: '/servicecash/1',
          component: () =>
            import('@/view/pages/sklad/products/ProductsCash/ProductsCash'),
          children: [
            {
              path: '/servicecash/:id',
              name: 'Service Cash Page',
              component: () =>
                import(
                  '@/view/pages/sklad/products/ProductsCash/ProductsCashPage'
                )
            }
          ]
        },
        {
          path: '/servicecashdetails/:id',
          name: 'Service Cash Details',
          component: () =>
            import('@/view/pages/sklad/products/ProductsCash/Details')
        },
        {
          path: '/storeproducts',
          name: 'Store Products',
          redirect: '/storeproducts/1',
          component: () =>
            import('@/view/pages/sklad/Export/CashRegister/CashRegister'),
          children: [
            {
              path: '/storeproducts/:id',
              name: 'Store Products Page',
              component: () =>
                import(
                  '@/view/pages/sklad/Export/CashRegister/CashRegisterPage'
                )
            }
          ]
        },
        {
          path: '/storecreate',
          name: 'Store Products Create',
          component: () =>
            import('@/view/pages/sklad/Export/CashRegister/CashRegisterCreate')
        },
        {
          path: '/transferpoducts',
          name: 'Transfer Poducts',
          redirect: '/transferpoducts/1',
          component: () =>
            import(
              '@/view/pages/sklad/Export/TransferRegister/TransferRegister'
            ),
          children: [
            {
              path: '/transferpoducts/:id',
              name: 'Transfer Poducts Page',
              component: () =>
                import(
                  '@/view/pages/sklad/Export/TransferRegister/TransferRegisterPage'
                )
            }
          ]
        },
        {
          path: '/transfercreate',
          name: 'Transfer Create',
          component: () =>
            import('@/view/pages/sklad/Export/TransferRegister/TransferCreate')
        },
        {
          path: '/realisations',
          name: 'Realizations',
          component: () => import('@/view/pages/sklad/Sold/Index')
        },
        {
          path: '/realisations/create',
          name: 'Realizations-create',
          component: () => import('@/view/pages/sklad/Sold/Create')
        },
        {
          path: '/servicecashcreate',
          name: 'Product Cash Create',
          component: () =>
            import(
              '@/view/pages/sklad/products/ProductsCash/ProductsCashCreate'
            )
        },
        {
          path: '/servicecard',
          name: 'Service Card',
          redirect: '/servicecard/1',
          component: () =>
            import('@/view/pages/sklad/products/ProductsCard/ProductsCard'),
          children: [
            {
              path: '/servicecard/:id',
              name: 'Service Cards',
              component: () =>
                import(
                  '@/view/pages/sklad/products/ProductsCard/ProductsCardpage'
                )
            }
          ]
        },
        {
          path: '/servicecardcreate',
          name: 'Product Service Create',
          component: () =>
            import(
              '@/view/pages/sklad/products/ProductsCard/ProductsCardCreate'
            )
        },
        {
          path: '/servicecard_update',
          name: 'Product Service Create',
          component: () =>
              import(
                  '@/view/pages/sklad/products/ProductsCard/ProductsCardUpdate'
                  )
        },
        {
          path: '/productsdetails/:id',
          name: 'Product Details',
          component: () => import('@/view/pages/sklad/products/ProductsDetail')
        },
        {
          path: '/productscreate',
          name: 'Product Create',
          component: () => import('@/view/pages/sklad/products/ProductCreate')
        },
        {
          path: '/kontragents',
          name: 'Kontragents',
          redirect: '/kontragents/1',
          component: () =>
            import('@/view/pages/kontragents/kontragentsList/Kontragents'),
          children: [
            {
              path: '/kontragents/:id',
              name: 'All-kontragents',
              component: () =>
                import(
                  '@/view/pages/kontragents/kontragentsList/KontragentsPage'
                )
            }
          ]
        },
        {
          path: '/kontragentscreate',
          name: 'Kontragentscreate',
          component: () =>
            import('@/view/pages/kontragents/kontragentsList/KontragentsCreate')
        },
        {
          path: '/techmonitor',
          name: 'Tech Monitor',
          redirect: '/techmonitor/1',
          component: () => import('@/view/pages/transport/TechMonitor/Index'),
          children: [
            {
              path: '/techmonitor/:id',
              name: 'Tech Monitor Page',
              component: () => import('@/view/pages/transport/TechMonitor/Page')
            }
          ]
        },
        {
          path: '/techmonitorcreate',
          name: 'Tech-Monitors-create',
          component: () => import('@/view/pages/transport/TechMonitor/Create')
        },
        {
          path: '/techmonitorcreate/:id',
          name: 'Tech-Monitors-id',
          component: () => import('@/view/pages/transport/TechMonitor/Create')
        },
        {
          path: '/techmonitor/details/:id',
          name: 'Tech Monitor Details',
          component: () => import('@/view/pages/transport/TechMonitor/Details')
        },
        {
          path: '/techmonitorupdate/:id',
          name: 'Tech Monitor Update',
          component: () => import('@/view/pages/transport/TechMonitor/Update')
        },
        {
          path: '/gasequips',
          name: 'Gas Equips',
          redirect: '/gasequips/1',
          component: () => import('@/view/pages/transport/GazEquips/GazEquips'),
          children: [
            {
              path: '/gasequips/:id',
              name: 'Gas Equips Page',
              component: () => import('@/view/pages/transport/GazEquips/Page')
            }
          ]
        },
        {
          path: '/gasequipscreate',
          name: 'Gas-Equips-Create',
          component: () => import('@/view/pages/transport/GazEquips/Create')
        },
        {
          path: '/gasequipscreate/:id',
          name: 'Gas-Equips-Create-id',
          component: () => import('@/view/pages/transport/GazEquips/Create')
        },
        {
          path: '/gasequipsupdate/:id',
          name: 'Gas Update',
          component: () => import('@/view/pages/transport/GazEquips/Update')
        },
        {
          path: '/gasequipsdetail/:id',
          name: 'Gas Details',
          component: () => import('@/view/pages/transport/GazEquips/Details')
        },
        {
          path: '/advertpermits',
          name: 'Advert Permits',
          redirect: '/advertpermits/1',
          component: () =>
            import('@/view/pages/transport/AdvertPermits/AdvertPermits'),
          children: [
            {
              path: '/advertpermits/:id',
              name: 'Advert Permits Page',
              component: () =>
                import('@/view/pages/transport/AdvertPermits/AdvertPermitsPage')
            }
          ]
        },
        {
          path: '/advertpermitscreate',
          name: 'advertPermitsCreate',
          component: () =>
            import('@/view/pages/transport/AdvertPermits/AdvertPermitsCreate')
        },
        {
          path: '/advertpermitscreate/:id',
          name: 'advertPermitsCreateId',
          component: () =>
            import('@/view/pages/transport/AdvertPermits/AdvertPermitsCreate')
        },
        {
          path: '/advertpermitsupdate/:id',
          name: 'advertPermitsUpdateId',
          component: () =>
            import('@/view/pages/transport/AdvertPermits/AdvertPermitsUpdate')
        },
        {
          path: '/advertpermitsdetail/:id',
          name: 'advertPermitsDetailId',
          component: () =>
            import('@/view/pages/transport/AdvertPermits/Details')
        },
        {
          path: '/insuarences',
          name: 'Insuarences',
          redirect: '/insuarences/1',
          component: () =>
            import('@/view/pages/transport/Insuarences/Insuarences'),
          children: [
            {
              path: '/insuarences/:id',
              name: 'Insuarences Page',
              component: () =>
                import('@/view/pages/transport/Insuarences/InsuarencesPage')
            }
          ]
        },
        {
          path: '/insuarencesdetails/:id',
          name: 'Insuarences_details',
          component: () => import('@/view/pages/transport/Insuarences/details')
        },

        {
          path: '/insuarencescreate',
          name: 'insuarencesCreate',
          component: () => import('@/view/pages/transport/Insuarences/Create')
        },
        {
          path: '/insuarencescreate/:id',
          name: 'insuarencesCreateId',
          component: () => import('@/view/pages/transport/Insuarences/Create')
        },
        {
          path: '/insuarencesupdate/:id',
          name: 'Insuarences Update',
          component: () =>
            import('@/view/pages/transport/Insuarences/InsuarencesUpdate')
        },
        {
          path: '/skladcreate',
          name: 'Sklad-Create',
          component: () => import('@/view/pages/sklad/skladList/CreateSklad')
        },
        {
          path: '/measurements',
          name: 'measurements',
          component: () =>
            import('@/view/pages/sklad/Measurements/measurementsList')
        },
        {
          path: '/measurementupdate',
          name: 'measurementupdate',
          component: () =>
            import('@/view/pages/sklad/Measurements/measurementsUpdate')
        },
        {
          path: '/nomenklatura',
          name: 'nomenklatura',
          component: () =>
            import('@/view/pages/sklad/nomenklatura/nomenklaturaList')
        },
        {
          path: '/nomenklaturagroup/',
          name: 'nomenklaturagroup',
          component: () =>
            import('@/view/pages/sklad/nomenklaturaGroup/nomenklaturagroup.vue')
        },
        {
          path: '/licences',
          name: 'licences',
          component: () => import('@/view/pages/transport/Licenses/Licenses')
        },
        {
          path: '/licencescreate',
          name: 'licencescreate',
          component: () =>
            import('@/view/pages/transport/Licenses/licensesCreate')
        },
        {
          path: '/licencescreate/:id',
          name: 'licencescreateid',
          component: () =>
            import('@/view/pages/transport/Licenses/licensesCreate')
        },
        {
          path: '/licensesdetails/:id',
          name: 'licensesdetails',
          component: () =>
            import('@/view/pages/transport/Licenses/licensesDetails')
        },
        {
          path: '/licensesupdate/:id',
          name: 'licensesupdate',
          component: () =>
            import('@/view/pages/transport/Licenses/licensesUpdate')
        },
        {
          path: '/driverpermits',
          name: 'Driver Permits',
          redirect: '/driverpermits/1',
          component: () =>
            import('@/view/pages/transport/DrivePermits/DrivePermits'),
          children: [
            {
              path: '/driverpermits/:id',
              name: 'Driver Permits Page',
              component: () =>
                import('@/view/pages/transport/DrivePermits/DrivePermitsPage')
            }
          ]
        },
        {
          path: '/driverpermitscreate',
          name: 'driverPermitsCreate',
          component: () => import('@/view/pages/transport/DrivePermits/Create')
        },
        {
          path: '/driverpermitscreate/:id',
          name: 'driverPermitsCreateId',
          component: () => import('@/view/pages/transport/DrivePermits/Create')
        },
        {
          path: '/driveupdate/:id',
          name: 'driveupdate',
          component: () =>
            import('@/view/pages/transport/DrivePermits/DriveUpdate')
        },
        {
          path: '/allmainexpenditures/',
          name: 'allmainexpenditures',
          component: () =>
            import(
              '@/view/pages/finance/costs/mainExpenditures/allMainExpenditures'
            )
        },
        {
          path: '/costCategories/',
          name: 'costCategories',
          component: () =>
            import(
              '@/view/pages/finance/costs/costCategories/allCostCategories'
            )
        },
        {
          path: '/costSubCategories/',
          name: 'costSubCategories',
          component: () =>
            import(
              '@/view/pages/finance/costs/costSubcategories/allCostSubCategories'
            )
        },
        {
          path: '/generalInformartion/',
          name: 'generalInformartion',
          component: () =>
            import(
              '@/view/pages/finance/costs/general-Information/allGeneralInformation'
            )
        },
        {
          path: '/conditiongoods/',
          name: 'conditiongoods',
          component: () =>
            import('@/view/pages/sklad/ConditionOfGoods/allConditionGoods')
        },
        {
          path: '/materialreport/',
          name: 'materialreport',
          component: () =>
            import(
              '@/view/pages/accounts/materialAccount/allMaterialReport.vue'
            )
        },
        {
          path: '/materialreport2/',
          name: 'materialreport2',
          component: () =>
            import(
              '@/view/pages/accounts/materialAccount2/allMaterialReport2.vue'
            )
        },
        {
          path: '/incomexpenditures/',
          name: 'incomexpenditures',
          component: () => import('@/view/pages/test.vue')
        },
        {
          path: '/mainassetsdoc/nottaken',
          name: 'Mainassets Doc',
          redirect: '/mainassetsdoc/nottaken/1',
          component: () =>
            import('@/view/pages/finance/MainAssetNotTaken/Index'),
          children: [
            {
              path: '/mainassetsdoc/nottaken/:id',
              name: 'Mainassets Doc Not Taken Pages',
              component: () =>
                import('@/view/pages/finance/MainAssetNotTaken/Page')
            }
          ]
        },
        {
          path: '/mainassetsdoc/taken',
          name: 'mainassetsDocTaken',
          component: () => import('@/view/pages/finance/MainAssetTaken/Index')
        },
        {
          path: '/mainasset/remarks',
          name: 'mainAssetRemarks',
          component: () => import('@/view/pages/finance/Remarks/Index')
        },
        {
          path: '/mainasset/daily_amortizations',
          name: 'mainAssetRemarksDailyAmortizations',
          component: () =>
            import('@/view/pages/finance/DailyAmortizations/Index')
        },
        {
          path: '/expenditure',
          name: 'financeExpenditures',
          component: () => import('@/view/pages/finance/expenditure/index')
        },
        {
          path: '/mainassetsdoc/details/:id',
          name: 'mainassetsdocdetails',
          component: () =>
            import('@/view/pages/finance/MainAssetNotTaken/Details')
        },
        {
          path: '/mainassetsdoc/taken/:id',
          name: 'mainAssetsDocTakenDetailsId',
          component: () => import('@/view/pages/finance/MainAssetTaken/Details')
        },
        {
          path: '/mainasset/sell',
          name: 'mainAssetsDocTakenSell',
          component: () => import('@/view/pages/sklad/MainAssetSell/Index')
        },
        {
          path: '/mainasset/sell/create',
          name: 'mainAssetsDocTakenSellCreate',
          component: () => import('@/view/pages/sklad/MainAssetSell/Create')
        },
        {
          path: '/mainassetsdoc/taken/update/:id',
          name: 'mainAssetsDocTakenUpdateId',
          component: () => import('@/view/pages/finance/MainAssetTaken/Update')
        },
        {
          path: '/mainassetsdoc/create',
          name: 'Mainassets Doc Create',
          component: () =>
            import('@/view/pages/finance/MainAssetNotTaken/Create')
        },
        {
          path: '/mainassets/',
          name: 'mainassets',
          component: () =>
            import('@/view/pages/finance/mainAssets/allMainAssets.vue')
        },
        {
          path: '/mainassets/no_calc',
          name: 'mainassets no cacl',
          component: () => import('@/view/pages/finance/NoCalc/Index')
        },
        {
          path: '/mainassets/nottaken',
          name: 'Mainassets Not Taken',
          redirect: '/mainassets/nottaken/1',
          component: () =>
            import('@/view/pages/finance/MainAssetsNotTaken/Index'),
          children: [
            {
              path: '/mainassets/nottaken/:id',
              name: 'Mainassets Not Taken Pages',
              component: () =>
                import('@/view/pages/finance/MainAssetsNotTaken/Page')
            }
          ]
        },
        {
          path: '/details/:id',
          name: 'details',
          component: () => import('@/view/pages/finance/mainAssets/details.vue')
        },
        {
          path: '/ddd/:id',
          name: 'ddd',
          component: () => import('@/view/pages/providers/ddd.vue')
        },
        {
          path: '/ownerlist/',
          name: 'ownre_list',
          component: () =>
            import('@/view/pages/general-section/allowner/index.vue')
        },
        {
          path: '/annualremark',
          name: 'Annual remark',
          component: () => import('@/view/pages/AnnualRemark/index')
        },
        {
          path: '/annualremark/details/:id',
          name: 'Annual remark Details',
          component: () => import('@/view/pages/AnnualRemark/PriceMarket')
        },
        {
          path: '/update/:id',
          name: 'Update',
          component: () => import('@/view/pages/finance/mainAssets/update.vue')
        },
        // faktura details
        {
          path: '/invoicedetails/:id',
          name: 'invoiceDetails',
          component: () =>
            import(
              '@/view/pages/kontragents/Contracts/Details/faktura/details.vue'
            )
        },
        {
          path: 'mainprofit',
          name: 'main-profit',
          component: () =>
            import('@/view/pages/general-section/profit/allProfit/index.vue')
        },

        {
          path: 'mainprofitcategory',
          name: 'main-profit-category',
          component: () =>
            import(
              '@/view/pages/general-section/profit/categoryProfit/index.vue'
            )
        },

        {
          path: 'incomes',
          name: 'Incomes',
          component: () => import('@/view/pages/Incomes/Index')
        },
        {
          path: 'incomes/create',
          name: 'Incomes Create',
          component: () => import('@/view/pages/Incomes/Create')
        },
        {
          path: 'incomes/update/:id',
          name: 'Incomes Update',
          component: () => import('@/view/pages/Incomes/Update')
        },
        {
          path: 'standartappen',
          name: 'standartAppenn',
          component: () => import('@/view/pages/standartIlova.vue')
        },
        {
          path: 'standartappen/create',
          name: 'standartAppen',
          component: () => import('@/view/pages/standartIlovaCreate.vue')
        },
        {
          path: 'appendetails/:id',
          name: 'appenDetails',
          component: () => import('@/view/pages/standartIlovaDetails.vue')
        },
        {
          path: 'appende-update/:id',
          name: 'Appen Detail',
          component: () => import('@/view/pages/standartIlovaUpdate.vue')
        },
        {
          path: 'incomes/details/:id',
          name: 'Incomes Details',
          component: () => import('@/view/pages/Incomes/Details')
        },
        {
          path: 'mainprofitsubcategory',
          name: 'main-profit-subCategory',
          component: () =>
            import(
              '@/view/pages/general-section/profit/subCategoryProfit/index.vue'
            )
        },

        {
          path: 'allmainprofit',
          name: 'all-main-profit',
          component: () =>
            import(
              '@/view/pages/general-section/profit/mainallProfit/index.vue'
            )
        },
        {
          path: 'planincomesfromservices',
          name: 'planIncomesFromServices',
          component: () => import('@/view/pages/finance/RejaKorsatgichlari')
        },
        {
          path: 'reports',
          name: 'Reports',
          // redirect: 'reports/1',
          component: () => import('@/view/pages/REPORTS/index'),
          children: [
            {
              path: ':id',
              component: () => import('@/view/pages/REPORTS/Page_ID/index')
            }
          ]
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/view/pages/auth/Auth'),
      children: [
        {
          name: 'login',
          path: '/login',
          component: () => import('@/view/pages/auth/Login')
        },
        {
          name: 'register',
          path: '/register',
          component: () => import('@/view/pages/auth/Register')
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/view/layout/CreateAnnual'),
      children: [
        {
          path: '/annualremark/create',
          name: 'Annual remark Create',
          meta: { layout: 'create-annual' },
          component: () => import('@/view/pages/AnnualRemark/Create')
        }
      ]
    },
    {
      path: '*',
      redirect: '/404'
    },
    {
      // the 404 route, when none of the above matches
      path: '/404',
      name: '404',
      component: () => import('@/view/pages/error/Error-1.vue')
    }
  ],

  // eslint-disable-next-line no-unused-vars
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})
