function setClientPageContract(state, payload) {
  state.clientContract = {}
  payload.results.forEach((element) => {
    if (element.contract_type == null) {
      element.status = "Ma'lumot berilmagan"
    }
    if (element.contract_type == 'SU') {
      element.contract_class_name = 'primary'
      element.contract_type = 'Summa'
      element.contract_type1 = 'SU'
    }
    if (element.contract_type == 'MS') {
      element.contract_class_name = 'success'
      element.contract_type = 'Muddatsiz'
      element.contract_type1 = 'MS'
    }
    if (element.contract_type == 'MU') {
      element.contract_class_name = 'warning'
      element.contract_type = 'Muddatli'
      element.contract_type1 = 'MU'
    }
    if (element.payment_type == 'CONT') {
      element.payment_type = 'Shartnoma'
      element.payment_type1 = 'CONT'
    }
    if (element.payment_type == 'NAQD') {
      element.payment_type = 'Kelishuv'
      element.payment_type1 = 'NAQD'
    }
    if (element.parent_contract == null) {
      element.parent_contract_color = 'text-success'
      element.parent_contract_label = 'label-success'
    } else {
      element.parent_contract_color = 'text-primary'
      element.parent_contract_label = 'label-primary'
    }
    if (element.is_active == true) {
      element.is_active_name = 'Faol'
      element.is_active1 = true
      element.is_active_color = 'success'
    } else if (element.is_active == false) {
      element.is_active_name = 'Faol emas'
      element.is_active1 = false
      element.is_active_color = 'danger'
    }
    if (element.parent_contract == null) {
      element.parent_contract_name = 'Kontrakt'
    } else if (element.parent_contract !== null) {
      element.parent_contract_name = 'Dop kontrakt'
    }
  })

  state.clientContract = payload
}
function setClientPageContractDetails(state, paylaod) {
  state.clientContractDetails = {}
  if (paylaod.payment_type == 'CONT') {
    paylaod.payment_type = 'Shartnoma'
  } else if (paylaod.contract_type == 'MU') {
    paylaod.contract_type = 'Muddatli'
  }
  if (paylaod.is_active == true) {
    paylaod.is_active_name = 'Foal'
  } else {
    paylaod.is_active_name = 'Faol emas'
  }
  paylaod.contract_zayavka.forEach((element) => {
    if (element.zayavka_status == 'OTW') {
      element.status_style = 'warning'
      element.zayavka_status_name = "Yo'lda"
    } else if (element.zayavka_status == 'DEL') {
      element.status_style = 'success'
      element.zayavka_status_name = 'Yetkazilgan'
    } else if (element.deliver == 'POS') {
      element.deliver_name = 'Postavshik'
    } else if (element.zayavka_status == 'OTB') {
      element.zayavka_status_name = 'Buyurtmachida'
      element.status_style = 'red'
    }
  })
  state.clientContractDetails = paylaod
}
function setClientPageZayavka(state, payload) {
  state.clientPageZayavka = {}

  payload.results.find((element) => {
    if (element.zayavka_status == 'OTB') {
      element.status_style = 'red'
      element.zayavka_status_name = 'Buyurtmachida'
    } else if (element.zayavka_status == 'OTW') {
      element.status_style = 'warning'
      element.zayavka_status_name = "Yo'lda"
    } else if (element.zayavka_status == 'DEL') {
      element.status_style = 'success'
      element.zayavka_status_name = 'Yetkazilgan'
    } else if (element.deliver == 'POS') {
      element.deliver_name = 'Postavshik'
    } else if (element.payment_type == 'CONT') {
      element.payment_type_name = "Pul ko'chirish"
      element.payment_text_color = 'text-primary'
      element.payment_color_label = 'label-primary'
    } else if (element.payment_type == 'NAQD') {
      element.payment_type_name = "Naqd to'lov"
      element.payment_text_color = 'text-danger'
      element.payment_color_label = 'label-danger'
    }
    if (element.start_date !== null) {
      element.start_date_new = element.start_date
        .split('')
        .splice(0, 10)
        .join('')
    }

    element.price_with_nds = element.price_with_nds.toLocaleString('es-US')
  })

  state.clientPageZayavka = payload
}
function setClientPageFactures(state, payload) {
  state.clientPageFactures = {}
  payload.results.forEach((element) => {
    element.get_factura_amount.price =
      element.get_factura_amount.price.toLocaleString('zh-ZH')
    element.get_factura_amount.nds =
      element.get_factura_amount.nds.toLocaleString('zh-ZH')
    element.get_factura_amount.price_with_nds =
      element.get_factura_amount.price_with_nds.toLocaleString('zh-ZH')
  })
  state.clientPageFactures = payload
}
function setClientPageFacturesDetails(state, payload) {
  state.clientPageFacturesDetails = []
  state.clientPageFacturesDetails = [...payload]
}
function setClientPageFacturesDetailsTable(state, payload) {
  state.clientPageFacturesDetailsTable = []
  state.clientPageFacturesDetailsTable = [...payload]
}
function setClientPageFacturesCompletedWorks(state, payload) {
  state.clientPageFacturesCompletedWorks = []
  state.clientPageFacturesCompletedWorks = [...payload]
}
export default {
  setClientPageContract,
  setClientPageContractDetails,
  setClientPageZayavka,
  setClientPageFactures,
  setClientPageFacturesDetails,
  setClientPageFacturesDetailsTable,
  setClientPageFacturesCompletedWorks
}
