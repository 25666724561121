function SET_FINANCIAL_DATA(state, payload) {
  state.financial_datas = payload || []
}

function SET_ORDERS_WEIGHT_COUNTS(state, payload) {
  state.orders_weight_counts = payload || []
}

function SET_EMPTY_WAY_RATIO(state, payload) {
  state.empty_way_ratio = payload || []
}

function SET_ORDERS_EFFICIENCY(state, payload) {
  state.orders_efficiency = payload || []
}

function SET_WEIGHT_EFFICIENCY(state, payload) {
  state.weight_efficiency = payload || []
}

function SET_CLIENTS_DEBET_CREDIT(state, payload) {
  state.clients_debet_credit = payload || []
}

function SET_PROVIDERS_DEBET_CREDIT(state, payload) {
  state.providers_debet_credit = payload || []
}

function SET_TOP_DRIVERS(state, payload) {
  state.top_drivers = payload || []
}

function SET_TOP_TRUCKS(state, payload) {
  state.top_trucks = payload || []
}

function SET_TOP_LOANERS(state, payload) {
  state.top_loaners = payload || []
}

export default {
  SET_FINANCIAL_DATA,
  SET_ORDERS_WEIGHT_COUNTS,
  SET_EMPTY_WAY_RATIO,
  SET_ORDERS_EFFICIENCY,
  SET_WEIGHT_EFFICIENCY,
  SET_CLIENTS_DEBET_CREDIT,
  SET_PROVIDERS_DEBET_CREDIT,
  SET_TOP_DRIVERS,
  SET_TOP_TRUCKS,
  SET_TOP_LOANERS
}
