import axios from 'axios'

function findTruck({ commit }, payload) {
  commit('setIsLoading', true)
  axios
    .post('trucks/get_location/' + payload + '/')
    .then((res) => {
      commit('setIsLoading', false)
      commit('setTruckLocation', res.data)
    })
    .catch((err) => console.log(err))
}
export default {
  findTruck
}
