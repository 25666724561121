import axios from 'axios'
import Swal from 'sweetalert2'

// main expenditures
async function allBasicCost({ commit }) {
  await axios
    .get('finance/main_expenditures/')
    .then((res) => {
      commit('allBasicCostCommit', res.data)
    })
    .catch((err) => console.log(err))
}
// create main expenditures
function createMainExpenditures({ dispatch }, data) {
  return axios
    .post('finance/main_expenditures/', data)
    .then(() => {
      dispatch('allBasicCost')
      // router.push("/servicecard");
      Swal.fire({
        title: '',
        text: "Ma'lumotlar yuborildi!",
        icon: 'success',
        confirmButtonClass: 'btn btn-secondary'
      })
    })
    .catch((err) => {
      Swal.fire({
        title: '',
        text: err.response.data.username,
        icon: 'error',
        confirmButtonClass: 'btn btn-secondary'
      })
    })
}
// update main expenditures
function updateMainExpendituires({ dispatch }, payload) {
  axios
    .put('finance/main_expenditures/' + payload.id.id + '/', payload.data)
    .then(() => {
      // router.push("/servicecard");
      Swal.fire({
        title: '',
        text: "Ma'lumotlar yuborildi!",
        icon: 'success',
        confirmButtonClass: 'btn btn-secondary'
      })
      dispatch('allBasicCost')
    })
    .catch((err) => {
      Swal.fire({
        title: '',
        text: err.response.data.username,
        icon: 'error',
        confirmButtonClass: 'btn btn-secondary'
      })
    })
}
// delete main expenditures
function deleteMainExpendituires({ dispatch }, payload) {
  axios
    .delete('finance/main_expenditures/' + payload + '/')
    .then(() => {
      // router.push("/servicecard");
      Swal.fire({
        title: '',
        text: "Ma'lumotlar o'chirildi!",
        icon: 'success',
        confirmButtonClass: 'btn btn-secondary'
      })
      dispatch('allBasicCost')
    })
    .catch((err) => {
      Swal.fire({
        title: '',
        text: err.response.data.detail,
        icon: 'error',
        confirmButtonClass: 'btn btn-secondary'
      })
    })
}

// all cost categories
async function allCostCategories({ commit }) {
  commit('setIsLoading', true)
  commit('allCostCategoriesCommit', [])
  await axios
    .get('finance/expenditure_categories/')
    .then((res) => {
      setTimeout(() => {
        commit('allCostCategoriesCommit', res.data)
        commit('setIsLoading', false)
      }, 1000)
    })
    .catch((err) => console.log(err))
}
// update costcategories
function updateCostCategories({ dispatch }, payload) {
  axios
    .put('finance/expenditure_categories/' + payload.id.id + '/', payload.data)
    .then(() => {
      Swal.fire({
        title: '',
        text: "Ma'lumotlar yuborildi!",
        icon: 'success',
        confirmButtonClass: 'btn btn-secondary'
      })
      dispatch('allCostCategories')
    })
    .catch((err) => {
      Swal.fire({
        title: '',
        text: err.response.data.username,
        icon: 'error',
        confirmButtonClass: 'btn btn-secondary'
      })
    })
}
// delete costcategories
function deleteCategories({ dispatch }, payload) {
  axios
    .delete('finance/expenditure_categories/' + payload + '/')
    .then(() => {
      Swal.fire({
        title: '',
        text: "Ma'lumotlar o'chirildi!",
        icon: 'success',
        confirmButtonClass: 'btn btn-secondary'
      })
      dispatch('allCostCategories')
    })
    .catch((err) => {
      Swal.fire({
        title: '',
        text: err.response.data.detail,
        icon: 'error',
        confirmButtonClass: 'btn btn-secondary'
      })
    })
}
// create Cost Categories
function createCostCategories({ dispatch }, payload) {
  return new Promise((resolve, reject) => {
    axios
      .post('finance/expenditure_categories/', payload)
      .then(() => {
        dispatch('allCostCategories')
        // router.push("/servicecard");
        Swal.fire({
          title: '',
          text: "Ma'lumotlar yuborildi!",
          icon: 'success',
          confirmButtonClass: 'btn btn-secondary'
        })
        resolve()
      })
      .catch((err) => {
        Swal.fire({
          title: '',
          text: err.response.data.username,
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
        reject(err)
      })
  })
}

// all Cost Sub Categories
async function allCostSubCategories({ commit }) {
  await axios
    .get('finance/expenditure_types/')
    .then((res) => {
      commit('allCostSubCategoriesCommit', res.data)
    })
    .catch((err) => console.log(err))
}
// create Cost Sub Categories
function createCostSubCategories({ dispatch }, payload) {
  return new Promise((resolve, reject) => {
    axios
      .post('finance/expenditure_types/', payload)
      .then(() => {
        dispatch('allCostSubCategories')
        // router.push("/servicecard");
        Swal.fire({
          title: '',
          text: "Ma'lumotlar yuborildi!",
          icon: 'success',
          confirmButtonClass: 'btn btn-secondary'
        })
        resolve()
      })
      .catch((err) => {
        Swal.fire({
          title: '',
          text: err.response.data.username,
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
        reject(err)
      })
  })
}
// update Cost Sub Categories
function updateCostSubCategories({ dispatch }, payload) {
  axios
    .put('finance/expenditure_types/' + payload.id.id + '/', payload.data)
    .then(() => {
      Swal.fire({
        title: '',
        text: "Ma'lumotlar yuborildi!",
        icon: 'success',
        confirmButtonClass: 'btn btn-secondary'
      })
      dispatch('allCostSubCategories')
    })
    .catch((err) => {
      Swal.fire({
        title: '',
        text: err.response.data.detail,
        icon: 'error',
        confirmButtonClass: 'btn btn-secondary'
      })
    })
}
// delete Cost Sub Categories
function deleteSubCategories({ dispatch }, payload) {
  axios
    .delete('finance/expenditure_types/' + payload + '/')
    .then(() => {
      Swal.fire({
        title: '',
        text: "Ma'lumotlar o'chirildi!",
        icon: 'success',
        confirmButtonClass: 'btn btn-secondary'
      })
      dispatch('allCostSubCategories')
    })
    .catch((err) => {
      Swal.fire({
        title: '',
        text: err.response.data.detail,
        icon: 'error',
        confirmButtonClass: 'btn btn-secondary'
      })
    })
}

async function allGeneralInformation({ commit }) {
  await axios
    .get('finance/expenditure_types/sorted/')
    .then((res) => {
      commit('allGeneralInformationCommit', res.data)
    })
    .catch((err) => console.log(err))
}

export default {
  // get section
  allGeneralInformation,
  allCostCategories,
  allBasicCost,
  allCostSubCategories,
  // create section
  createCostSubCategories,
  createCostCategories,
  createMainExpenditures,
  // update section
  updateMainExpendituires,
  updateCostCategories,
  updateCostSubCategories,
  // delete section
  deleteMainExpendituires,
  deleteCategories,
  deleteSubCategories
}
