function setDriverZayavkas(state, payload) {
  state.driverZayavkas = []
  payload.find((element) => {
    if (element.zayavka_status == 'OTB') {
      element.status_style = 'red'
      element.zayavka_status_name = 'Buyurtmachida'
    }
    if (element.start_date !== null) {
      element.start_date_new = element.start_date
        .split('')
        .splice(0, 10)
        .join('')
    }
    if (element.zayavka_status == 'OTW') {
      element.status_style = 'warning'
      element.zayavka_status_name = "Yo'lda"
    }
    if (element.zayavka_status == 'DEL') {
      element.status_style = 'success'
      element.zayavka_status_name = 'Yetkazilgan'
    }
    if (element.deliver == 'POS') {
      element.deliver_name = 'Postavshik'
    }
    if (element.payment_type == 'CONT') {
      element.payment_type_name = "Pul ko'chirish"
      element.payment_text_color = 'text-primary'
      element.payment_color_label = 'label-primary'
    }
    if (element.payment_type == 'NAQD') {
      element.payment_type_name = "Naqd to'lov"
      element.payment_text_color = 'text-danger'
      element.payment_color_label = 'label-danger'
    }
  })
  state.driverZayavkas = [...payload]
}
export default {
  setDriverZayavkas
}
