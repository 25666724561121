import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
  state: {
    materialrepost: [],
    storeList: {},
    storeList2: []
  },
  mutations,
  actions,
  getters
}
