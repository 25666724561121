function allUsers(state) {
  return state.allUsers
}
function allBrands(state) {
  return state.allBrands
}
function allContracts(state) {
  return state.allContracts
}
function allDriversGet(state) {
  const data = state.allDrivers
  // for (let i = 0; i < data.length / 3; i++) {
  //   if (data[i].inn !== null) {
  //     console.log(String(data[i].inn).split(""));

  //     const rr = String(data[i].inn).split("");
  //     for (let j = 0; j < rr.length; j++) {
  //       if ((j + 1) % 3 === 0) {
  //         data[i].inn = data[i].inn + rr[j] + " ";
  //       } else if ((j + 1) % 3 !== 0) {
  //         data[i].inn = data[i].inn + rr[j];
  //       }
  //     }
  //     data[i].inn = data[i].inn.substr(9).substring(0, 11);
  //   }
  // }
  // console.log(data);
  return data
}
function allProviders(state) {
  return state.allProviders
}
function allCategoryGroupNames(state) {
  return state.categoryGroupNames
}
function curdriverdetails(state) {
  return state.driverbyid
}
function allStatusName(state) {
  return state.clientStatus
}
function allRegions(state) {
  return state.regions
}
function getallregions(state) {
  // console.log(state.regions);
  return state.allregions
}
function getallOwnership(state) {
  // console.log(state.ownership);
  return state.ownership
}
function getTruckList(state) {
  // console.log(state.setTruckListId);
  return state.setTruckListId
}
function getVipiskaDate(state) {
  return state.transferDetails
}
function getCategoryGroupData(state) {
  return state.categoryGroupData
}

// get measurements
function getAllMeasurements(state) {
  return state.allMeasurements
}
// get nomenklature
function getAllNomenklatura(state) {
  return state.allNomenklature
}
function allNomenklaturaGroup(state) {
  return state.nomenklaturaGroup
}
function test(state) {
  return state.allstafflist
}
function allProviderContractById(state) {
  return state.providerContractById
}
function providerContractZayavka(state) {
  state.providerContractZayavka.forEach((element) => {
    if (element.zayavka_status == 'DEL') {
      element.status_style = 'success'
      element.zayavka_status = 'Yetkazilgan'
    } else if (element.zayavka_status == 'Buyurtmachida') {
      element.status_style = 'red'
      element.zayavka_status = 'Buyurtmachida'
    }
  })

  return state.providerContractZayavka
}
function providerSearchResult(state) {
  return state.providerSearchResult
}
function countragentKontractFacturas(state) {
  return state.countragentKontractFactura
}
function countragentKontractsPayment(state) {
  const data = state.countragentKontractsPayment
  data.forEach((element) => {
    element.amount = element.amount.toLocaleString('es-US')
  })
  return data
}
function getcountragentKontractsSverka(state) {
  const data = state.countragentKontractsSverka
  if (data.current_sverka >= 0) {
    data.amountDebet = data.current_sverka
  } else if (data.current_sverka < 0) {
    data.amountKredit = data.current_sverka
  }
  for (let prop in data) {
    data[prop] = data[prop].toLocaleString('es-US')
  }
  return data
}
function kontragentContractAktSverka(state) {
  const data = state.kontragentContractAktSverka
  for (let prop in data.results) {
    // console.log(data.results[prop])
    for (let prop2 in data.results[prop]) {
      if (Number.isInteger(data.results[prop][prop2])) {
        data.results[prop][prop2] =
          data.results[prop][prop2].toLocaleString('es-US')
      }
    }
  }

  return data
}
function kontragentContractAktSverkaID(state) {
  const data = state.kontragentContractAktSverkaID

  for (let prop in data.akt_sverka_body) {
    for (let prop2 in data.akt_sverka_body[prop]) {
      if (Number.isInteger(data.akt_sverka_body[prop][prop2])) {
        data.akt_sverka_body[prop][prop2] =
          data.akt_sverka_body[prop][prop2].toLocaleString('es-US')
      }
    }
  }
  for (let prop in data) {
    if (Number.isInteger(data[prop])) {
      data[prop] = data[prop].toLocaleString('es-US')
    }
  }

  return data
}
function providerContractDeliveredZayavkas(state) {
  return state.providerContractDeliveredZayavkas
}
function dd(state) {
  const data = state.allTruckList
  data.forEach((element) => {
    if (element.id == state.licensesById.truck) {
      state.licensesById.truck_fullname =
        element.truck_type.brand.name + ' ' + element.truck_type.model
    }
  })
  return state.licensesById
}
function providerContractAktSverka(state) {
  const data = state.providerContractAktSverka
  for (let prop in data.results) {
    // console.log(data.results[prop])
    for (let prop2 in data.results[prop]) {
      if (Number.isInteger(data.results[prop][prop2])) {
        data.results[prop][prop2] =
          data.results[prop][prop2].toLocaleString('es-US')
      }
    }
  }
  return data
}
function providerContractAktSverkaID2(state) {
  const data = state.providerContractAktSverkaID

  for (let prop in data.akt_sverka_body) {
    for (let prop2 in data.akt_sverka_body[prop]) {
      if (Number.isInteger(data.akt_sverka_body[prop][prop2])) {
        data.akt_sverka_body[prop][prop2] =
          data.akt_sverka_body[prop][prop2].toLocaleString('es-US')
      }
    }
  }
  for (let prop in data) {
    if (Number.isInteger(data[prop])) {
      data[prop] = data[prop].toLocaleString('es-US')
    }
  }

  return data
}
function ProviderContractPayment(state) {
  // state.ProviderContractPayment.forEach((element) => {
  //   element.amount = element.amount.toLocaleString('es-US')
  // })
  return state.ProviderContractPayment
}

function GetfactureBycontract(state) {
  return state.allFacturasByContract
}

function ActRecontDetails(state) {
  const data = state.ActRecontDetails
  for (let prop in data.actsverkabody_set) {
    for (let prop2 in data.actsverkabody_set[prop]) {
      let ss = data.actsverkabody_set[prop][prop2]
      if ((ss === +ss && ss !== (ss | 0)) || Number.isInteger(ss))
        data.actsverkabody_set[prop][prop2] =
          data.actsverkabody_set[prop][prop2].toLocaleString('es-US')
    }
  }
  for (let prop in data) {
    let ee = data[prop]
    if ((ee === +ee && ee !== (ee | 0)) || Number.isInteger(ee)) {
      data[prop] = data[prop].toLocaleString('es-US')
    }
  }

  return data
}
function staffDepartments(state) {
  return state.staffDepartments
}
function staffPositions(state) {
  const data = state.staffPositions
  for (let i = 0; i < state.staffDepartments.length; i++) {
    data.forEach((element) => {
      if (state.staffDepartments[i].id == element.department) {
        element.department_name = state.staffDepartments[i].name
      }
      // if (
      //   element.oklad === +element.oklad &&
      //   element.oklad === (element.oklad | 0)
      // ) {
      //   element.oklad = element.oklad.toLocaleString();
      // }
    })
  }

  return state.staffPositions
}

function getAllPersonal(state) {
  const data = state.allPersonal
  if (data.results !== undefined) {
    data.results.forEach((element) => {
      for (let i = 0; i < state.regions.length; i++) {
        if (element.adress_oblast == state.regions[i].id) {
          element.adress_oblast_name = state.regions[i].name
        }
        for (let j = 0; j < state.regions[i].oblast_region.length; j++) {
          if (element.adress_region == state.regions[i].oblast_region[j].id) {
            element.adress_region_name = state.regions[i].oblast_region[j].name
          }
        }
      }
    })
  }

  return data
}
function allOblast(state) {
  return state.allOblast
}

function allStaff(state) {
  const data = state.staffDepartments
  data.forEach((element) => {
    for (let i = 0; i < state.allStaff.length; i++) {
      if (state.allStaff[i].staffpersonal_description !== undefined) {
        state.allStaff[i].full_name =
          state.allStaff[i].staffpersonal_description.first_name +
          ' ' +
          state.allStaff[i].staffpersonal_description.last_name
        if (state.allStaff[i].position_description.department == element.id) {
          state.allStaff[i].department_name = element.name
        }
      }
    }
  })
  return state.allStaff
}
function allNonEmployedStaff(state) {
  const data = state.allNonEmployedStaff

  return data
}
function allRegionByOblast(state) {
  return state.regionByOblast
}
function staffVacations(state) {
  return state.staffVacations
}
function allStaffIllnesses(state) {
  // const data = state.allStaff;
  // console.log(state.allStaffIllnesses.results.length);

  // data.forEach(element => {
  //   for (let i = 0; i < count; i++) {
  //     if (element.id == state.allStaffIllnesses.results[i].employee) {
  //       state.allStaffIllnesses.results[i].staff_full_name =
  //         element.staffpersonal_description.first_name +
  //         " " +
  //         element.staffpersonal_description.last_name;
  //     }
  //   }
  // });
  // console.log(count)
  // console.log(state.allStaffIllnesses)
  return state.allStaffIllnesses
}
function allOwners(state) {
  return state.allOwners
}
export default {
  allOwners,
  allStaffIllnesses,
  staffVacations,
  allRegionByOblast,
  allStaff,
  allNonEmployedStaff,
  allOblast,
  getAllPersonal,
  staffPositions,
  staffDepartments,
  ActRecontDetails,
  ProviderContractPayment,
  GetfactureBycontract,
  providerContractAktSverkaID2,
  providerContractAktSverka,
  dd,
  providerContractDeliveredZayavkas,
  kontragentContractAktSverkaID,
  kontragentContractAktSverka,
  getcountragentKontractsSverka,
  countragentKontractsPayment,
  countragentKontractFacturas,
  providerSearchResult,
  providerContractZayavka,
  allProviderContractById,
  test,
  allNomenklaturaGroup,
  getAllNomenklatura,
  allUsers,
  getAllMeasurements,
  getTruckList,
  getallregions,
  getVipiskaDate,
  getCategoryGroupData,
  allCategoryGroupNames,
  allBrands,
  allProviders,
  allContracts,
  allDriversGet,
  getallOwnership,
  allStatusName,
  curdriverdetails,
  allRegions
}
