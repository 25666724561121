import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
  state: {
    clientContract: {},
    clientContractDetails: {},
    clientPageZayavka: {},
    clientPageFactures: {},
    clientPageFacturesDetails: [],
    clientPageFacturesDetailsTable: [],
    clientPageFacturesCompletedWorks: []
  },
  mutations,
  actions,
  getters
}
