import mutations from './mutations'
import actions from './actions'
// import getters from './getters'
import {
  filterData3,
  filterData4,
  filterData5,
  filterData7,
  filterData18,
  filterData19,
  filterData20,
  filterData21,
  filterData22,
  filterData23,
  filterData25,
  filterData26,
  filterData27,
  filterData28,
  filterData33
} from './getters_'

export default {
  state: {
    reportsData: [],
    table_loading: false,
    queryParams: {
      currentPage: 1,
      perPage: 10
    },
    total: 0,
    filterDialog: false,

    filters: {
      tashkilot_nomi: '',
      shartnoma: '',
      boshlangich: '',
      hisob_raqami: '',
      mfo: '',
      inn: '',
      oked: '',
      address: '',
      kod_nds: ''
    },
    filters3: {
      gos_number: '',
      year: '',
      dvigatel_no: '',
      bought_date: '',
      price: '',
      capacity_kg: '',
      capacity_m3: '',
      odometr: '',
      odometr_updated_date_manual: '',
      kuzov_number: '',
      marka: ''
    },
    filters4: {
      marka: '',
      get_truck_number: '',
      odometr: '',
      insurance: {
        start_date: '',
        end_date: ''
      },
      technical_monitoring: {
        start_date: '',
        end_date: ''
      },
      advert_permit: {
        start_date: '',
        end_date: ''
      },
      truck_oil_state: {
        start_usage: '', // num
        end_usage: '' // num
      },
      truck_tire_state: {
        start_usage: '', // num
        end_usage: '' // num
      },
      truck_accumulator_state: {
        start_date_usage: '', // num
        end_date_usage: '' // num
      },
      truck_kolodka_state: {
        start_usage: '', // num
        end_usage: '' // num
      }
    },

    filters7: {
      marka: '',
      get_truck_number: '',
      insurance: {
        start_date: '',
        end_date: ''
      },
      technical_monitoring: {
        start_date: '',
        end_date: ''
      },
      advert_permit: {
        start_date: '',
        end_date: ''
      },
      truck_oil_state: {
        start_usage: '', // num
        end_usage: '' // num
      },
      truck_tire_state: {
        start_usage: '', // num
        end_usage: '' // num
      },
      truck_accumulator_state: {
        start_usage: '', // num
        end_usage: '' // num
      },
      truck_kolodka_state: {
        start_usage: '', // num
        end_usage: '' // num
      }
    },

    filters18: {},
    filters19: {},
    filters20: {},
    filters21: {},
    filters22: {},
    filters23: {},
    filters26: {},
    filters27: {},
    filters28: {}
  },
  mutations,
  actions,
  getters: {
    filteredReport33: (state) => {
      return filterData33(state)
    },
    filteredReport28: (state) => {
      return filterData28(state)
    },
    filteredReport27: (state) => {
      return filterData27(state)
    },
    filteredReport26: (state) => {
      return filterData26(state)
    },
    filteredReport25: (state) => {
      return filterData25(state)
    },
    filteredReport23: (state) => {
      return filterData23(state)
    },
    filteredReport22: (state) => {
      return filterData22(state)
    },
    filteredReport21: (state) => {
      return filterData21(state)
    },
    filteredReport20: (state) => {
      return filterData20(state)
    },
    filteredReport19: (state) => {
      return filterData19(state)
    },
    filteredReport18: (state) => {
      return filterData18(state)
    },
    filteredReport7: (state) => {
      return filterData7(state)
    },
    filteredReport5: (state) => {
      return filterData5(state)
    },
    filteredReport4: (state) => {
      return filterData4(state)
    },
    filteredReport3: (state) => {
      return filterData3(state)
    },
    filteredData: (state) => {
      let { reportsData, queryParams, filters } = state
      let filtered

      filtered = reportsData.filter((report) => {
        // if(
        //   !report.client.full_name.includes(filters.tashkilot_nomi)
        // ) {
        //   return false;
        // } else {
        //   return true;
        // }
        if (
          filters.tashkilot_nomi !== '' &&
          !report.client.full_name
            .toLowerCase()
            .includes(filters.tashkilot_nomi.toLowerCase())
        )
          return false
        if (
          filters.shartnoma !== '' &&
          !report.contract_number.includes(filters.shartnoma)
        )
          return false
        if (
          filters.boshlangich !== '' &&
          report.contract_start_date !== filters.boshlangich
        )
          return false
        if (
          filters.hisob_raqami !== '' &&
          !report.client.bank_account1.includes(filters.hisob_raqami)
        )
          return false
        if (
          filters.mfo !== '' &&
          !report.client.bank_code1.includes(filters.mfo)
        )
          return false
        if (filters.inn !== '' && !report.client.inn.includes(filters.inn))
          return false
        if (filters.oked !== '' && !report.client.oked.includes(filters.oked))
          return false
        if (
          filters.address !== '' &&
          !report.client.yur_address
            .toLowerCase()
            .includes(filters.address.toLowerCase())
        )
          return false
        if (
          filters.kod_nds !== '' &&
          !report.client.code_nds.includes(filters.kod_nds)
        )
          return false
        return true
      })

      // pagination
      let finalResult
      if (
        (queryParams.currentPage - 1) * queryParams.perPage +
          queryParams.perPage +
          1 <=
        filtered.length
      ) {
        finalResult = filtered.slice(
          (queryParams.currentPage - 1) * queryParams.perPage,
          (queryParams.currentPage - 1) * queryParams.perPage +
            queryParams.perPage
        )
      } else {
        finalResult = filtered.slice(
          (queryParams.currentPage - 1) * queryParams.perPage,
          filtered.length
        )
      }
      // console.log(
      //   'current: ',
      //   (queryParams.currentPage - 1) * queryParams.perPage
      // )
      return { total: filtered.length, finalResult, filtered }
      // pagination end
    }
  }
}
