import mutations from './mutations'
import actions from './actions'

export default {
    state: {
        providerContractDetailOperationsFilterStartDate:null
    },
    mutations,
    actions
}
