import dayjs from 'dayjs'

export function filterData3(data) {
  let { reportsData = [], queryParams, filters3 } = data
  let filtered
  // console.dir(data)
  filtered = (reportsData || []).filter((report) => {
    if (
      filters3.marka !== '' &&
      !report.marka.toLowerCase().includes(filters3.marka.toLowerCase())
    )
      return false
    if (
      filters3.gos_number !== '' &&
      !report.get_truck_number
        .toLowerCase()
        .includes(filters3.gos_number.toLowerCase())
    )
      return false
    if (
      filters3.dvigatel_no !== '' &&
      !report.dvigatel_number
        .toLowerCase()
        .includes(filters3.dvigatel_no.toLowerCase())
    )
      return false
    if (
      filters3.kuzov_number !== '' &&
      !report.kuzov_number
        .toLowerCase()
        .includes(filters3.kuzov_number.toLowerCase())
    )
      return false
    if (filters3.year !== '' && report.year !== filters3.year) return false
    if (
      filters3.bought_date !== '' &&
      report.bought_date !== filters3.bought_date
    )
      return false
    if (
      filters3.price !== '' &&
      report.bought_price !== Number(filters3.price)
    ) {
      // console.log('price: ', report.bought_price !== Number(filters3.price))
      return false
    }

    if (
      filters3.capacity_kg !== '' &&
      report.capacity_kg !== Number(filters3.capacity_kg)
    )
      return false
    if (
      filters3.capacity_m3 !== '' &&
      report.capacity_m3 !== Number(filters3.capacity_m3)
    )
      return false
    if (filters3.odometr !== '' && report.odometr !== Number(filters3.odometr))
      return false
    if (
      filters3.odometr_updated_date_manual !== '' &&
      report.odometr_updated_date_manual !==
        filters3.odometr_updated_date_manual
    )
      return false

    return true
  })

  let finalResult
  if (
    (queryParams.currentPage - 1) * queryParams.perPage +
      queryParams.perPage +
      1 <=
    filtered.length
  ) {
    finalResult = filtered.slice(
      (queryParams.currentPage - 1) * queryParams.perPage,
      (queryParams.currentPage - 1) * queryParams.perPage + queryParams.perPage
    )
  } else {
    finalResult = filtered.slice(
      (queryParams.currentPage - 1) * queryParams.perPage,
      filtered.length
    )
  }
  // console.log('current: ', (queryParams.currentPage - 1) * queryParams.perPage)
  // console.log('Final result: ', finalResult)
  return { total: filtered.length, finalResult, filtered }
}

export function filterData4(data) {
  let { reportsData, queryParams, filters4 } = data
  let filtered
  filtered = (reportsData || []).filter((report) => {
    if (
      filters4.marka !== '' &&
      !report.marka.toLowerCase().includes(filters4.marka.toLowerCase())
    ) {
      // console.log('----------------------------------------')
      return false
    }
    if (
      filters4.get_truck_number !== '' &&
      !report.get_truck_number
        .toLowerCase()
        .includes(filters4.get_truck_number.toLowerCase())
    ) {
      // console.log('----------------------------------------')
      return false
    }

    if (
      !!report.insurance &&
      filters4.insurance.start_date !== '' &&
      filters4.insurance.start_date !== report.insurance.start_date
    ) {
      // console.log('----------------------------------------')
      return false
    } else if (
      report.insurance === null &&
      filters4.insurance.start_date !== ''
    ) {
      // console.log('2----------------------------------------')
      return false
    }
    if (
      !!report.insurance &&
      filters4.insurance.end_date !== '' &&
      filters4.insurance.end_date !== report.insurance.end_date
    ) {
      // console.log('----------------------------------------')
      return false
    } else if (
      report.insurance === null &&
      filters4.insurance.end_date !== ''
    ) {
      // console.log('2----------------------------------------')
      return false
    }

    if (
      !!report.technical_monitoring &&
      filters4.technical_monitoring.start_date !== '' &&
      filters4.technical_monitoring.start_date !==
        report.technical_monitoring.start_date
    ) {
      // console.log('----------------------------------------')
      return false
    } else if (
      report.technical_monitoring === null &&
      filters4.technical_monitoring.start_date !== ''
    ) {
      // console.log('2----------------------------------------')
      return false
    }
    if (
      !!report.technical_monitoring &&
      filters4.technical_monitoring.end_date !== '' &&
      filters4.technical_monitoring.end_date !==
        report.technical_monitoring.end_date
    ) {
      // console.log('----------------------------------------')
      return false
    } else if (
      report.technical_monitoring === null &&
      filters4.technical_monitoring.end_date !== ''
    ) {
      // console.log('2----------------------------------------')
      return false
    }

    if (
      !!report.advert_permit &&
      filters4.advert_permit.start_date !== '' &&
      filters4.advert_permit.start_date !== report.advert_permit.start_date
    ) {
      // console.log('----------------------------------------')
      return false
    } else if (
      report.advert_permit === null &&
      filters4.advert_permit.start_date !== ''
    ) {
      // console.log('2----------------------------------------')
      return false
    }
    if (
      !!report.advert_permit &&
      filters4.advert_permit.end_date !== '' &&
      filters4.advert_permit.end_date !== report.advert_permit.end_date
    ) {
      // console.log('----------------------------------------')
      return false
    } else if (
      report.advert_permit === null &&
      filters4.advert_permit.end_date !== ''
    ) {
      // console.log('2----------------------------------------')
      return false
    }

    if (
      !!report.truck_oil_state &&
      filters4.truck_oil_state.start_usage !== '' &&
      Number(filters4.truck_oil_state.start_usage) !==
        report.truck_oil_state.start_usage
    ) {
      // console.log('----------------------------------------')
      return false
    } else if (
      report.truck_oil_state === null &&
      filters4.truck_oil_state.start_usage !== ''
    ) {
      // console.log('2----------------------------------------')
      return false
    }

    if (
      !!report.truck_oil_state &&
      filters4.truck_oil_state.end_usage !== '' &&
      Number(filters4.truck_oil_state.end_usage) !==
        report.truck_oil_state.end_usage
    ) {
      // console.log('----------------------------------------')
      return false
    } else if (
      report.truck_oil_state === null &&
      filters4.truck_oil_state.end_usage !== ''
    ) {
      // console.log('2----------------------------------------')
      return false
    }

    if (
      !!report.truck_tire_state &&
      filters4.truck_tire_state.start_usage !== '' &&
      Number(filters4.truck_tire_state.start_usage) !==
        report.truck_tire_state.start_usage
    ) {
      // console.log('----------------------------------------')
      return false
    } else if (
      report.truck_tire_state === null &&
      filters4.truck_tire_state.start_usage !== ''
    ) {
      // console.log('2----------------------------------------')
      return false
    }
    if (
      !!report.truck_tire_state &&
      filters4.truck_tire_state.end_usage !== '' &&
      Number(filters4.truck_tire_state.end_usage) !==
        report.truck_tire_state.end_usage
    ) {
      // console.log('----------------------------------------')
      return false
    } else if (
      report.truck_tire_state === null &&
      filters4.truck_tire_state.end_usage !== ''
    ) {
      // console.log('2----------------------------------------')
      return false
    }

    if (
      !!report.truck_accumulator_state &&
      filters4.truck_accumulator_state.start_date_usage !== '' &&
      Number(filters4.truck_accumulator_state.start_date_usage) !==
        report.truck_accumulator_state.start_date_usage
    ) {
      // console.log('----------------------------------------')
      return false
    }
    // else if (
    //   report.truck_accumulator_state === null &&
    //   filters4.truck_accumulator_state.start_date_usage !== ''
    // ) {
    //   console.log('2----------------------------------------')
    //   return false
    // }
    if (
      !!report.truck_accumulator_state &&
      filters4.truck_accumulator_state.end_date_usage !== '' &&
      Number(filters4.truck_accumulator_state.end_date_usage) !==
        report.truck_accumulator_state.end_date_usage
    ) {
      // console.log('3----------------------------------------')
      return false
    }
    // else if (
    //   report.truck_accumulator_state === null &&
    //   filters4.truck_accumulator_state.end_date_usage !== ''
    // ) {
    //   console.log('4----------------------------------------')
    //   return false
    // }

    if (
      !!report.truck_kolodka_state &&
      filters4.truck_kolodka_state.start_usage !== '' &&
      Number(filters4.truck_kolodka_state.start_usage) !==
        report.truck_kolodka_state.start_usage
    ) {
      return false
    } else if (
      report.truck_kolodka_state === null &&
      filters4.truck_kolodka_state.start_usage !== ''
    ) {
      return false
    }
    if (
      !!report.truck_kolodka_state &&
      filters4.truck_kolodka_state.end_usage !== '' &&
      Number(filters4.truck_kolodka_state.end_usage) !==
        report.truck_kolodka_state.end_usage
    ) {
      return false
    } else if (
      report.truck_kolodka_state === null &&
      filters4.truck_kolodka_state.end_usage !== ''
    ) {
      return false
    }

    return true
  })

  let finalResult
  if (
    (queryParams.currentPage - 1) * queryParams.perPage +
      queryParams.perPage +
      1 <=
    filtered.length
  ) {
    finalResult = filtered.slice(
      (queryParams.currentPage - 1) * queryParams.perPage,
      (queryParams.currentPage - 1) * queryParams.perPage + queryParams.perPage
    )
  } else {
    finalResult = filtered.slice(
      (queryParams.currentPage - 1) * queryParams.perPage,
      filtered.length
    )
  }

  // console.log('current: ', (queryParams.currentPage - 1) * queryParams.perPage)
  // console.log('Final result: ', finalResult)
  return { total: filtered.length, finalResult, filtered }
}

export function filterData5(data) {
  let { reportsData = [], queryParams, filters4 } = data
  let filtered
  filtered = (reportsData || []).filter((report) => {
    if (
      filters4.marka !== '' &&
      !report.marka.toLowerCase().includes(filters4.marka.toLowerCase())
    )
      return false
    if (
      filters4.get_truck_number !== '' &&
      !report.get_truck_number
        .toLowerCase()
        .includes(filters4.get_truck_number.toLowerCase())
    )
      return false

    if (
      !!report.insurance &&
      filters4.insurance.start_date !== '' &&
      filters4.insurance.start_date !== report.insurance.start_date
    ) {
      return false
    } else if (
      report.insurance === null &&
      filters4.insurance.start_date !== ''
    ) {
      return false
    }
    if (
      !!report.insurance &&
      filters4.insurance.end_date !== '' &&
      filters4.insurance.end_date !== report.insurance.end_date
    ) {
      return false
    } else if (
      report.insurance === null &&
      filters4.insurance.end_date !== ''
    ) {
      return false
    }

    if (
      !!report.technical_monitoring &&
      filters4.technical_monitoring.start_date !== '' &&
      filters4.technical_monitoring.start_date !==
        report.technical_monitoring.start_date
    ) {
      return false
    } else if (
      report.technical_monitoring === null &&
      filters4.technical_monitoring.start_date !== ''
    ) {
      return false
    }
    if (
      !!report.technical_monitoring &&
      filters4.technical_monitoring.end_date !== '' &&
      filters4.technical_monitoring.end_date !==
        report.technical_monitoring.end_date
    ) {
      return false
    } else if (
      report.technical_monitoring === null &&
      filters4.technical_monitoring.end_date !== ''
    ) {
      return false
    }

    if (
      !!report.advert_permit &&
      filters4.advert_permit.start_date !== '' &&
      filters4.advert_permit.start_date !== report.advert_permit.start_date
    ) {
      return false
    } else if (
      report.advert_permit === null &&
      filters4.advert_permit.start_date !== ''
    ) {
      return false
    }
    if (
      !!report.advert_permit &&
      filters4.advert_permit.end_date !== '' &&
      filters4.advert_permit.end_date !== report.advert_permit.end_date
    ) {
      return false
    } else if (
      report.advert_permit === null &&
      filters4.advert_permit.end_date !== ''
    ) {
      return false
    }

    if (
      !!report.truck_oil_state &&
      filters4.truck_oil_state.start_usage !== '' &&
      Number(filters4.truck_oil_state.start_usage) !==
        report.truck_oil_state.start_usage
    ) {
      return false
    } else if (
      report.truck_oil_state === null &&
      filters4.truck_oil_state.start_usage !== ''
    ) {
      return false
    }

    if (
      !!report.truck_oil_state &&
      filters4.truck_oil_state.end_usage !== '' &&
      Number(filters4.truck_oil_state.end_usage) !==
        report.truck_oil_state.end_usage
    ) {
      return false
    } else if (
      report.truck_oil_state === null &&
      filters4.truck_oil_state.end_usage !== ''
    ) {
      return false
    }

    if (
      !!report.truck_tire_state &&
      filters4.truck_tire_state.start_usage !== '' &&
      Number(filters4.truck_tire_state.start_usage) !==
        report.truck_tire_state.start_usage
    ) {
      return false
    } else if (
      report.truck_tire_state === null &&
      filters4.truck_tire_state.start_usage !== ''
    ) {
      return false
    }
    if (
      !!report.truck_tire_state &&
      filters4.truck_tire_state.end_usage !== '' &&
      Number(filters4.truck_tire_state.end_usage) !==
        report.truck_tire_state.end_usage
    ) {
      return false
    } else if (
      report.truck_tire_state === null &&
      filters4.truck_tire_state.end_usage !== ''
    ) {
      return false
    }

    if (
      !!report.truck_accumulator_state &&
      filters4.truck_accumulator_state.start_usage !== '' &&
      Number(filters4.truck_accumulator_state.start_usage) !==
        report.truck_accumulator_state.start_usage
    ) {
      return false
    } else if (
      report.truck_accumulator_state === null &&
      filters4.truck_accumulator_state.start_usage !== ''
    ) {
      return false
    }
    if (
      !!report.truck_accumulator_state &&
      filters4.truck_accumulator_state.end_usage !== '' &&
      Number(filters4.truck_accumulator_state.end_usage) !==
        report.truck_accumulator_state.end_usage
    ) {
      return false
    } else if (
      report.truck_accumulator_state === null &&
      filters4.truck_accumulator_state.end_usage !== ''
    ) {
      return false
    }

    if (
      !!report.truck_kolodka_state &&
      filters4.truck_kolodka_state.start_usage !== '' &&
      Number(filters4.truck_kolodka_state.start_usage) !==
        report.truck_kolodka_state.start_usage
    ) {
      return false
    } else if (
      report.truck_kolodka_state === null &&
      filters4.truck_kolodka_state.start_usage !== ''
    ) {
      return false
    }
    if (
      !!report.truck_kolodka_state &&
      filters4.truck_kolodka_state.end_usage !== '' &&
      Number(filters4.truck_kolodka_state.end_usage) !==
        report.truck_kolodka_state.end_usage
    ) {
      return false
    } else if (
      report.truck_kolodka_state === null &&
      filters4.truck_kolodka_state.end_usage !== ''
    ) {
      return false
    }

    return true
  })

  let finalResult
  if (
    (queryParams.currentPage - 1) * queryParams.perPage +
      queryParams.perPage +
      1 <=
    filtered.length
  ) {
    finalResult = filtered.slice(
      (queryParams.currentPage - 1) * queryParams.perPage,
      (queryParams.currentPage - 1) * queryParams.perPage + queryParams.perPage
    )
  } else {
    finalResult = filtered.slice(
      (queryParams.currentPage - 1) * queryParams.perPage,
      filtered.length
    )
  }
  // console.log('current: ', (queryParams.currentPage - 1) * queryParams.perPage)
  // console.log('Final result: ', finalResult)
  return { total: filtered.length, finalResult, filtered }
}

export function filterData7(data) {
  let {
    reportsData: { count, results }
  } = data

  let filtered = results || []

  return { total: count, finalResult: results, filtered }
}

export function filterData18(data) {
  let {
    reportsData: { count, results }
  } = data

  let filtered = results || []

  return { total: count, finalResult: results, filtered }
}

export function filterData19(data) {
  // const { reportsData = [], total } = data

  // return { total, finalResult: reportsData || [], reportsData }

  const {
    reportsData: { count, results }
  } = data

  let filtered = results || []

  return { total: count, finalResult: results, filtered }
}

export function filterData20(data) {
  // const { reportsData = [], total } = data

  // return { total, finalResult: reportsData || [], reportsData }

  const {
    reportsData: { count, results }
  } = data

  let filtered = results || []

  return { total: count, finalResult: results, filtered }
}

export function filterData21(data) {
  // const { reportsData = [], total } = data

  // return { total, finalResult: reportsData || [], reportsData }

  const {
    reportsData: { count, operations, ...props }
  } = data

  let filtered = operations || []

  return { total: count, finalResult: operations, filtered, fields: props }
}
export function filterData22(data) {
  // const { reportsData = [], total } = data

  // return { total, finalResult: reportsData || [], reportsData }

  const {
    reportsData: { count, results, ...props }
  } = data

  let filtered = results || []

  return { total: count, finalResult: results, filtered, fields: props }
}

export function filterData23(data) {
  // const { reportsData = [], total } = data

  // return { total, finalResult: reportsData || [], reportsData }

  const {
    reportsData: { count, results, ...props }
  } = data

  let filtered = results || []

  return { total: count, finalResult: results, filtered, fields: props }
}

export function filterData25(data) {
  let {
    reportsData: { count, results, ...props }
  } = data

  results = (results || []).map((item) => ({
    ...item,
    calced_salary_summa: (item.calced_salary_by_month || []).reduce(
      (sum, n) => (+sum || 0) + (+n.total_calculated || 0),
      0
    ),
    calced_salary_by_month: Array.from(Array(12).keys())
      .map((el) => ({
        month: dayjs(`${dayjs().year()}-${el + 1}-01`).format('YYYY-MM-DD')
      }))
      .map((el) => {
        const findIndex = (item.calced_salary_by_month || []).findIndex(
          (f) => dayjs(f.month).month() == dayjs(el.month).month()
        )

        if (findIndex != -1) {
          return item.calced_salary_by_month[findIndex]
        }

        return el
      })
      .sort((a, b) => dayjs(a.month).month() - dayjs(b.month).month())
  }))

  let filtered = results || []

  return { total: count, finalResult: results, filtered, fields: props }
}

export function filterData26(data) {
  // const { reportsData = [], total } = data

  // return { total, finalResult: reportsData || [], reportsData }

  const {
    reportsData: { count, results, ...props }
  } = data

  let filtered = results || []

  return { total: count, finalResult: results, filtered, fields: props }
}

export function filterData27(data) {
  const {
    reportsData: { count, results, ...props }
  } = data

  let filtered = results || []

  return { total: count, finalResult: results, filtered, fields: props }
}

export function filterData28(data) {
  const {
    reportsData: { result = {} }
  } = data

  return { result }
}

export function filterData31(data) {
  const {
    reportsData: { result = {} }
  } = data

  return { result }
}

export function filterData33(data) {
  // const { reportsData = [], total } = data

  // return { total, finalResult: reportsData || [], reportsData }

  const {
    reportsData: { count, results }
  } = data

  let filtered = results || []

  return { total: count, finalResult: results, filtered }
}