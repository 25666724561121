import axios from 'axios'

// material repost
async function materialRepost({ commit }) {
  try {
    const {data} = await axios.get('warehouse/material_report/current_remains/')
    commit('materialRepostCommit', data)
  } catch (e) {
    console.log(e);
  }
   
}
// store list
async function storeList({ commit }) {
  axios
    .get('warehouse/list/')
    .then((res) => {
      commit('storeListCommit', res.data)
    })
    .catch((res) => console.log(res))
}

async function materialReport2({ commit }, payload) {
  await axios
    .get(
      'warehouse/material_report/todate_remains/?warehouse=' +
        payload.params +
        '&to_date=' +
        payload.to_date +
        '&from_date=' +
        payload.from_date
    )
    .then((res) => {
      commit('materialReport2Commit', res.data)
    })
    .catch((res) => console.log(res))
}

export default {
  // get section
  materialRepost,
  storeList,
  materialReport2
}
