import ApiService from '@/core/services/api.service'
import JwtService, { getToken } from '@/core/services/jwt.service'
import router from '@/router'
import axios from 'axios'
import Swal from 'sweetalert2'

function success() {
    Swal.fire({
        title: '',
        text: "Ma'lumotlar yuborildi!",
        icon: 'success'
    })
}

function refreshToken({ commit }) {
    const reToken = JSON.parse(localStorage.getItem('user'))
    // console.log('refreshToken(): reToken', reToken)

    if (!reToken) {
        commit('setIsLoading', false)
        commit('logOut')
        if (router.currentRoute.path !== '/login') router.push({ name: 'login' })
        return
    }

    return axios
        .post('token/refresh/', {
            refresh: reToken.refresh
        })
        .then(res => {
            // console.log('is there?', res)
            JwtService.destroyToken()
            JwtService.saveToken(res.data.access)
            ApiService.setHeader()
            return res
        })
}

function changeDashboardTruckListValue({ commit }, payload) {
    commit('changeDashboardTruckListValue', payload)
}

function getPerPage({ commit }, payload) {
    axios
        .get('clients/list/', {
            params: {
                page: payload
            }
        })
        .then(res => {
            commit('setPerPage', res.data)
        })
        .catch(err => console.log(err))
}

async function getmainassets({ commit }) {
    commit('setIsLoading', true)
    await axios
        .get('warehouse/main_assets/')
        .then(res => {
            commit('getmainassetsCommit', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function getDailyAmortizationUnCalc({ commit }) {
    commit('setIsLoading', true)
    await axios
        .get('warehouse/main_assets/dailyamortizations/uncalc_days/')
        .then(res => {
            commit('setDailyAmortizationUnCalc', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

function createDailyAmortizationUnCalc({ commit, dispatch }, payload) {
    commit('setIsLoading', true)
    axios
        .post('warehouse/main_assets/dailyamortizations/uncalc_days/', payload)
        .then(res => {
            console.log(res)
            dispatch('getDailyAmortizationUnCalc')
            success()
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function serivesPaymentSource({ commit }) {
    await axios
        .get('warehouse/import_payment_sources/')
        .then(res => {
            // console.log('res.data', res.data)
            commit('serivesPaymentSource', res && res.data)
        })
        .catch(err => console.log(err))
}

async function getmainassetsNotTaken({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('warehouse/main_assets/without_docs/', {
            params: {
                page: payload
            }
        })
        .then(res => {
            commit('setMainassetsNotTaken', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

// get provider Contract Zayavka
async function providerContractZayavka({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('providers/contract/zayavkas/' + payload + '/')
        .then(res => {
            commit('providerContractZayavkaCommit', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function getZayavkaDrivers({ commit }) {
    commit('setIsLoading', true)
    await axios
        .get('zayavkas/create/drivers/list/')
        .then(res => {
            commit('getZayavkaDriversCommit', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function getmainassetsDetails({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('warehouse/main_assets/details/' + payload + '/')
        .then(res => {
            commit('mainassetsDetailsCommit', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function getAllUsersPerPage({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('clients/list/', {
            params: {
                page: payload
            }
        })
        .then(res => {
            commit('setAllUsers', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function mainAssets({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('warehouse/main_assets_doc/not_taken/', {
            params: {
                page: payload
            }
        })
        .then(res => {
            commit('setIsLoading', false)
            commit('mainAssetsCommit', res.data)
        })
        .catch(err => console.log(err))
}

async function notLinkedTotruck({ commit }) {
    commit('setIsLoading', true)
    await axios
        .get('warehouse/main_assets/not_linked_totruck/')
        .then(res => {
            commit('setIsLoading', false)
            commit('setNotLinkedTotruck', res.data)
        })
        .catch(err => console.log(err))
}

async function mainAssetsDocPag({ commit, dispatch }, payload) {
    await axios
        .get('warehouse/main_assets_doc/', {
            params: {
                page: payload
            }
        })
        .then(res => {
            // dispatch("getstafflistall");
            dispatch('getPaymentSource')
            commit('mainAssetsCommit', res.data)
        })
        .catch(err => console.log(err))
}

async function mainAssetsTaken({ commit, dispatch }, payload) {
    commit('setIsLoading', true)

    await axios
        .get('warehouse/main_assets_doc/taken/', {
            params: {
                page: payload
            }
        })
        .then(res => {
            // dispatch("getstafflistall");
            dispatch('getPaymentSource')
            commit('setIsLoading', false)
            commit('mainAssetsCommitTaken', res.data)
        })
        .catch(err => console.log(err))
}

async function getCompletedWorks({ commit }, payload) {
    axios
        .get(
            'contracts/clientcontracts/factures/done_jobs_display/' + payload + '/'
        )
        .then(res => {
            commit('setCompletedWorks', res.data)
        })
        .catch(res => console.log(res))
}

function mainAssetsAdd({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
        axios
            .post('warehouse/main_assets/', payload)
            .then(() => {
                dispatch('getmainassetsNotTaken', '1')
                dispatch('mainAssets', '1')
                Swal.fire({
                    title: '',
                    text: "Ma'lumotlar yuborildi!",
                    icon: 'success'
                })
                resolve()
            })
            .catch(err => {
                if (err.status == 400) {
                    Swal.fire({
                        title: '',
                        text: 'Malumot yuborilishda xato yuz berdi!',
                        icon: 'error'
                    })
                    reject(err)
                } else if (err.response.data.inventar_number[0]) {
                    Swal.fire({
                        title: '',
                        text: 'Sistemada bu inventar mavjud',
                        icon: 'error'
                    })
                    reject(err)
                }
            })
    })
}

async function annualRemark({ commit }) {
    commit('setIsLoading', true)
    await axios
        .get('warehouse/main_assets/annual_remark/')
        .then(res => {
            commit('setAnnualRemark', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function tillDateList({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('warehouse/main_assets/till_date/list/', {
            params: {
                till_date: payload
            }
        })
        .then(res => {
            commit('setTillDateList', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function deleteDateElement({ commit, dispatch }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('warehouse/main_assets/anual_report/delete/' + payload.id + '/')
        .then(() => {
            Swal.fire({
                title: '',
                text: "Ma'lumotlar o'chirildi!",
                icon: 'success'
            })
            commit('setIsLoading', false)
            dispatch('annualRemark')
        })
        .catch(err => console.log(err))
}

function createDateList({ commit, dispatch }, payload) {
    commit('setIsLoading', true)
    return axios
        .post('warehouse/main_assets/annual_remark/', payload)
        .then(res => {
            commit('setIsLoading', false)
            setInterval(() => {
                dispatch('annualRemark')
            }, 1000)
            Swal.fire({
                title: '',
                text: "Ma'lumotlar yuborildi!",
                icon: 'success'
            })
            return res
        })
        .catch(err => console.log(err))
}

function mainAssetsCreate({ commit, dispatch }, payload) {
    commit('setIsLoading', true)
    return new Promise((resolve, reject) => {
        axios
            .post('warehouse/main_assets_doc/', payload)
            .then(() => {
                dispatch('mainAssets', '1')
                commit('setIsLoading', false)
                Swal.fire({
                    title: '',
                    text: "Ma'lumotlar yuborildi!",
                    icon: 'success'
                })
                router.push('/mainassetsdoc/nottaken')
                resolve()
            })
            .catch(err => {
                commit('setIsLoading', false)
                console.log(err)
                reject(err)
            })
    })
}

function getReArmor({ commit, dispatch }, payload) {
    commit('setIsLoading', true)
    axios
        .get('warehouse/main_assets/recalc_amortization/' + payload + '/')
        .then(() => {
            commit('setIsLoading', false)
            Swal.fire({
                title: '',
                text: 'Hisobot davri uchun amortizatsiya qayta xisoblandi!',
                icon: 'success'
            })
            dispatch('getmainassetsDetails', payload)
        })
        .catch(err => console.log(err))
}

function getReAmor({ commit, dispatch }, payload) {
    commit('setIsLoading', true)
    axios
        .get('warehouse/main_assets/recalc_remain/' + payload + '/')
        .then(() => {
            commit('setIsLoading', false)
            dispatch('getmainassetsDetails', payload)
        })
        .catch(err => console.log(err))
}

function clientSearch({ commit }, payload) {
    // console.log(axios.get("clients/search/", {body: JSON.stringify({name: "yanf"})}));
    commit('setIsLoading', true)
    commit('setAllClientsName', [])
    return axios
        .get('clients/search/', {
            params: {
                name: payload.name
            }
        })
        .then(res => {
            commit('setIsLoading', false)
            // console.log("22323", res);
            commit('setAllClientsName', res.data)
        })
        .catch(err => console.log(err))
}

// provider search
async function providerSearch({ commit }, payload) {
    commit('setIsLoading', true)
    axios
        .get('providers/list/search/', {
            params: {
                name: payload.name
            }
        })
        .then(res => {
            commit('setIsLoading', false)
            commit('setProviderSearch', res.data)
        })
        .catch(err => console.log(err))
}

async function counteragentsSearch({ commit }, payload) {
    // console.log(axios.get("clients/search/", {body: JSON.stringify({name: "yanf"})}));
    commit('setIsLoading', true)
    const response = await axios
        .get('counteragents/search/', {
            params: payload
        })
        .then(res => {
            commit('setIsLoading', false)
            // console.log("22323", res);
            return res.data
            // commit("setAllClientsName", res.data);
        })
        .catch(err => {
            return err.data
        })
    return response
}

async function counteragentContracts({ commit }, payload) {
    commit('setIsLoading', true)
    const response = await axios
        .get('counteragents/contracts/by_counteragent/' + payload + '/')
        .then(res => {
            commit('setIsLoading', false)
            commit('counteragentContractsCommit', res.data)
            // console.log("22323", res);
            return res.data
            // commit("setAllClientsName", res.data);
        })
        .catch(err => {
            return err.data
        })
    return response
}

async function getSkladList({ commit }, payload) {
    commit('setIsLoading', true)
    axios
        .get('warehouse/list/', {
            params: {
                page: payload
            }
        })
        .then(res => {
            commit('setIsLoading', false)
            commit('setSkladList', res.data)
        })
        .catch(err => console.log(err))
}

async function getFinanceExpenditureList({ commit }, payload) {
    commit('setIsLoading', true)
    axios
        .get('finance/expenditure/list/', {
            params: {
                page: payload
            }
        })
        .then(res => {
            commit('setFinanceExpenditureList', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function getAllSkladList({ commit }) {
    // console.log(axios.get("clients/search/", {body: JSON.stringify({name: "yanf"})}));
    commit('setIsLoading', true)
    return axios
        .get('warehouse/all/')
        .then(res => {
            commit('setIsLoading', false)
            commit('setAllSkladList', res.data)
        })
        .catch(err => console.log(err))
}

async function getstafflistall({ commit }) {
    await axios
        .get('staff/list')
        .then(res => {
            commit('staffListCommit', res.data)
        })
        .catch(err => console.log(err))
}

function createSklad({ commit, dispatch }, payload) {
    commit('setIsLoading', true)
    return new Promise((resolve, reject) => {
        axios
            .post('warehouse/list/', payload)
            .then(() => {
                Swal.fire({
                    title: '',
                    text: "Ma'lumotlar yuborildi!",
                    icon: 'success'
                })
                commit('setIsLoading', false)
                dispatch('getSkladList', '1')
                router.push('/sklad')
                resolve()
            })
            .catch(err => {
                console.log(err)
                reject(err)
            })
    })
}

function updateSklad({ dispatch }, payload) {
    axios
        .put('warehouse/list/' + payload.id + '/', payload.data)
        .then(() => {
            success()
            dispatch('getSkladList', '1')
            // router.push('/sklad')
        })
        .catch(err => console.log(err))
}

function getGeneralPaymentTypes({ commit }) {
    axios
        .get('finance/transfers/payment_source/')
        .then(res => {
            commit('setGeneralPaymentTypes', res.data)
        })
        .catch(err => console.log(err))
}

function KontragentContractAktSverkaCreate({ commit, dispatch }, payload) {
    console.log(payload)
    commit('setIsLoading', true)
    axios
        .post('counteragents/akt_sverka/list/', payload)
        .then(res => {
            console.log(res)
            Swal.fire({
                title: '',
                text: "Ma'lumotlar yuborildi!",
                icon: 'success'
            })
            commit('setIsLoading', false)
            router.push('/kontragents/contract/aktsverka/')
            dispatch('kontragentContractAktSverka')
        })
        .catch(err => console.log(err))
}

function ProviderContractAktSverkaCreate({ commit, dispatch }, payload) {
    commit('setIsLoading', true)
    axios
        .post('providers/akt_sverka/list/', payload)
        .then(() => {
            Swal.fire({
                title: '',
                text: "Ma'lumotlar yuborildi!",
                icon: 'success'
            })
            commit('setIsLoading', false)
            router.push('/providerscontractaktsverka')
            dispatch('providerContractAktSverka')
        })
        .catch(err => console.log(err))
}

function createGoodsservicesExport({ commit }, payload) {
    commit('setIsLoading', true)
    console.log('createGoodsservicesExport');
    return new Promise((resolve, reject) => {
        axios
            .post('warehouse/goodsservices/', payload)
            .then(() => {
                Swal.fire({
                    title: '',
                    text: "Ma'lumotlar yuborildi!",
                    icon: 'success'
                })
                commit('setIsLoading', false)
                router.go(-1)
                resolve()
            })
            .catch(err => {
                commit('setIsLoading', false)
                console.log(err)
                reject(err)
            })
    })
}

function createGoodsservicesExport2({ commit }, payload) {
    commit('setIsLoading', true)
    console.log('createGoodsservicesExport2');

    return new Promise((resolve, reject) => {
        axios
            .post('warehouse/goodsservices/', payload)
            .then(() => {
                Swal.fire({
                    title: '',
                    text: "Ma'lumotlar yuborildi!",
                    icon: 'success'
                })
                commit('setIsLoading', false)
                router.push('/transferpoducts')
                resolve()
            })
            .catch(err => {
                reject(err)
                console.log(err)
                commit('setIsLoading', false)
            })
    })
}

function createGoodsservicesExportOptional({ commit }, payload) {
    commit('setIsLoading', true)
    return new Promise((resolve, reject) => {
        axios
            .post('warehouse/goodsservices/export/', payload)
            .then(() => {
                Swal.fire({
                    title: '',
                    text: "Ma'lumotlar yuborildi!",
                    icon: 'success'
                })
                commit('setIsLoading', false)
                router.go(-1)
                resolve()
            })
            .catch(err => {
                commit('setIsLoading', false)
                console.log(err)
                reject(err)
            })
    })
}

async function getStaffList({ commit }, payload) {
    console.log(payload)
    commit('setIsLoading', true)
    axios
        .get('staff/search/', {
            params: payload
        })
        .then(res => {
            commit('setIsLoading', false)

            commit('setStaffList', res.data)
        })
        .catch(err => console.log(err))
}

// get staff departments
async function staffDepartments({ commit }) {
    // commit('setIsLoading', true)
    await axios
        .get('staff/departments/list/')
        .then(res => {
            // commit('setIsLoading', false)
            commit('staffDepartmentsCommit', res.data)
        })
        .catch(err => console.log(err))
}

// get staff positions
async function staffPositions({ commit }) {
    // commit('setIsLoading', true)
    await axios
        .get('staff/staff_positions/list/')
        .then(res => {
            // commit('setIsLoading', false)
            commit('ShowTest', false)
            commit('staffPositionsCommit', res.data)
        })
        .catch(err => console.log(err))
}

// create staff departments

function staffDepartmentsCreate({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
        axios
            .post('staff/departments/list/', payload)
            .then(() => {
                success()
                dispatch('staffDepartments')
                resolve()
            })
            .catch(err => {
                console.log(err)
                reject(err)
            })
    })
}

// create staff position

function staffPositionCreate({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
        axios
            .post('staff/staff_positions/list/', payload)
            .then(() => {
                success()
                dispatch('staffPositions')
                resolve()
            })
            .catch(err => {
                console.log(err)
                reject(err)
            })
    })
}

// update staff departments

function staffDepartmentsUpdate({ dispatch }, payload) {
    axios
        .put('staff/departments/list/' + payload.id + '/', payload.data)
        .then(() => {
            success()
            dispatch('staffDepartments')
        })
        .catch(err => console.log(err))
}

// update staff positions

function staffPositionUpdate({ dispatch }, payload) {
    axios
        .put('staff/staff_positions/list/' + payload.id + '/', payload.data)
        .then(() => {
            success()
            dispatch('staffPositions')
        })
        .catch(err => console.log(err))
}

async function providerContractDeliveredZayavkas({ commit }, payload) {
    commit('setIsLoading', true)

    await axios
        .get('providers/contract/delivered_zayavkas/' + payload + '/')
        .then(res => {
            commit('providerContractDeliveredZayavkasCommit', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function clientFilter({ commit }, data) {
    commit('setIsLoading', true)
    await axios
        .post('clients/search/byfields/', data)
        .then(res => {
            commit('setAllUsers', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function getProductsList({ commit }, payload) {
    // console.log(axios.get("clients/search/", {body: JSON.stringify({name: "yanf"})}));
    commit('setIsLoading', true)
    axios
        .get(
            'warehouse/goodsservices/?page=' +
            payload +
            '&payment_source=1&operation_type=IMPORT'
        )
        .then(res => {
            commit('setIsLoading', false)
            commit('setProductsList', res.data)
        })
        .catch(err => {
            commit('setIsLoading', false)
            console.log(err)
        })
}

async function getProductsList2({ commit }, payload) {
    // console.log(axios.get("clients/search/", {body: JSON.stringify({name: "yanf"})}));
    commit('setIsLoading', true)
    axios
        .get('warehouse/goodsservices/?page= ' + payload + '&payment_source=2')
        .then(res => {
            commit('setIsLoading', false)
            commit('setProductsList', res.data)
        })
        .catch(err => console.log(err))
}

async function getProductsList3({ commit }, payload) {
    // console.log(axios.get("clients/search/", {body: JSON.stringify({name: "yanf"})}));
    commit('setIsLoading', true)
    axios
        .get('warehouse/goodsservices/?page= ' + payload + '&payment_source=3')
        .then(res => {
            commit('setIsLoading', false)
            commit('setProductsList', res.data)
        })
        .catch(err => console.log(err))
}

async function getKontragentsList({ commit }, payload) {
    commit('setIsLoading', true)
    console.log(payload)
    axios
        .get('counteragents/list', {
            params: {
                page: payload.page
            }
        })
        .then(res => {
            commit('setIsLoading', false)
            commit('setKontragentsList', res.data)
        })
        .catch(err => console.log(err))
}

async function searchKontragentsList({ commit }, payload) {
    commit('setIsLoading', true)
    let params = {}
    if (Object.keys(payload.filters).length > 0) {
        params.filters = payload.filters
        params.page = payload.page
    } else {
        params.page = payload.page
    }
    axios(
        // .post('counteragents/contracts/search-by-field/', payload)
        {
            url: 'counteragents/list',
            params,
            method: 'GET'
        }
    )
        .then(res => {
            commit('setIsLoading', false)
            commit('setKontragentsList', res.data)
        })
        .catch(err => console.log(err))
}

async function searchKontragentsList_2({ commit }, payload) {
    commit('setIsLoading', true)
    axios({
        method: 'GET',
        url: 'counteragents/list',
        params: {
            filters: payload
        }
    })
        .then(res => {
            commit('setIsLoading', false)
            commit('setKontragentsList', res.data)
        })
        .catch(err => {
            commit('setIsLoading', false)
            console.log(err)
        })
}

async function createKontragent({ commit, dispatch }, payload) {
    commit('setIsLoading', true)
    return new Promise((resolve, reject) => {
        return axios
            .post('counteragents/list/', payload)
            .then(() => {
                Swal.fire({
                    title: '',
                    text: "Ma'lumotlar yuborildi!",
                    icon: 'success'
                })
                commit('setIsLoading', false)
                dispatch('getKontragentsList', {})
                router.push('/kontragents')

                resolve()
            })
            .catch(err => {
                console.log(err)
                commit('setIsLoading', false)
                reject(err)
            })
    })
}

async function vipiskaFilter({ commit }, data) {
    commit('setIsLoading', true)
    await axios
        .post('finance/transfers/search/', data)
        .then(res => {
            commit('setIsLoading', false)
            commit('setAllTransferList', res.data)
        })
        .catch(err => console.log(err))
}

function vipiskaFilterCashPerPage({ commit }, payload) {
    commit('setIsLoading', true)
    axios
        .post(
            'finance/transfers/search/',
            {
                params: {
                    page: payload.page
                }
            },
            payload.data
        )
        .then(res => {
            commit('setIsLoading', false)
            commit('setAllTransferList', res.data)
        })
        .catch(err => console.log(err))
}

async function getAllStaffIllnesses({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('staff/illness/list/', {
            params: {
                page: payload
            }
        })
        .then(res => {
            commit('setIsLoading', false)
            commit('setAllStaffIllnesses', res.data)
        })
        .catch(err => console.log(err))
}

function createStaffIllnesses({ dispatch }, data) {
    axios
        .post('staff/illness/list/', data)
        .then(() => {
            success()
            dispatch('getAllStaffIllnesses')
        })
        .catch(err => console.log(err))
}

async function clientsSearchPerPages({ commit }, data) {
    // console.log("22222", data);
    commit('setIsLoading', true)
    await axios
        .post(
            'clients/search/byfields/',
            {
                params: {
                    page: data.id
                }
            },
            data.payload
        )
        .then(res => {
            commit('setAllUsers', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function productDetails({ commit }, data) {
    commit('setIsLoading', true)
    await axios
        .get('warehouse/goodsservices/' + data + '/')
        .then(res => {
            commit('setProductDetails', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}
async function updateProductDetails({ commit }, data) {
    commit('setIsLoading', true)
    try {
        await axios.put('warehouse/goodsservices/' + data.id + '/', data.data)
        commit('setIsLoading', false)
    } catch (error) {
        console.log(error);
    }
}

async function contractsSearch({ commit }, payload) {
    console.log('dataaa', payload)
    commit('setIsLoading', true)
    await axios
        .post('contracts/clientcontracts/search-by-field/', payload)
        .then(res => {
            commit('setAllContracts', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function kontragentsContractsSearch({ commit }, payload) {
    console.log('dataaa', payload)
    commit('setIsLoading', true)
    await axios
        .post('counteragents/contracts/search-by-field/', payload)
        .then(res => {
            commit('setKontragentsContractsList', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => {
            console.log(err)
            commit('setIsLoading', false)
        })
}

function zayavkaSearch({ commit }, data) {
    commit('setIsLoading', true)
    return axios
        .post('zayavkas/list/search/', data)
        .then(res => {
            commit('setIsLoading', false)
            commit('setAllZayavkaPag', res.data)
            commit('setAllZayavkaDatas', res.data)
        })
        .catch(err => console.log(err))
}

function zayavkaSearchCustom({ commit }, { page, params }) {
    commit('setIsLoading', true)
    return axios
        .post(`zayavkas/list/search/?page=${page}`, params)
        .then(res => {
            commit('setIsLoading', false)
            commit('setAllZayavkaPag', res.data)
            commit('setAllZayavkaDatas', res.data)
        })
        .catch(err => console.log(err))
}

async function contractsSearchPerPages({ commit }, data) {
    commit('setIsLoading', true)
    await axios
        .post(
            'contracts/clientcontracts/search-by-field/',
            {
                params: {
                    page: data.id
                }
            },
            data.payload
        )
        .then(res => {
            commit('setAllContracts', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function zayavkaSearchPerPages({ commit }, data) {
    // console.log("22222", data);
    // console.log("22222", data.id);
    commit('setIsLoading', true)
    await axios
        .post('zayavkas/list/search/?page=' + data.id, data.payload)
        .then(res => {
            commit('setAllZayavkaPag', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function getPaymentSource({ commit }) {
    await axios
        .get('finance/transfers/payment_source/')
        .then(res => {
            commit('setPaymentSource', res.data)
        })
        .catch(err => console.log(err))
}

async function getAllOwner({ commit }) {
    commit('setIsLoading', true)
    await axios
        .get('general/ownership-types/')
        .then(res => {
            commit('setAllOwner', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

function ownerCreate({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
        axios
            .post('general/ownership-types/', payload)
            .then(() => {
                success()
                dispatch('getAllOwner')
                resolve()
            })
            .catch(err => {
                console.log(err)
                reject(err)
            })
    })
}

function ownerUpdate({ dispatch }, payload) {
    axios
        .put('general/ownership-types/' + payload.id + '/', payload.data)
        .then(() => {
            success()
            dispatch('getAllOwner')
        })
        .catch(err => console.log(err))
}

function ownerDelete({ dispatch }, payload) {
    axios
        .delete('general/ownership-types/' + payload + '/')
        .then(() => {
            dispatch('getAllOwner')
        })
        .catch(err => console.log(err))
}

async function getCategoryGroupNames({ commit, dispatch }) {
    commit('setIsLoading', true)
    return axios
        .get('warehouse/import_category_group/import_category/')
        .then(res => {
            commit('setCategoryGroupNames', res.data.reverse())
            dispatch('getCategoryGroupData', res.data[0].id)
            commit('setIsLoading', false)
            return res || {}
        })
        .catch(err => console.log(err))
}

async function getCategoryGroupData({ commit }, payload) {
    commit('setIsLoading', true)
    commit('setCategoryGroupData', [])
    await axios
        .get('warehouse/import_category/?group=' + payload)
        .then(res => {
            commit('setCategoryGroupData', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function getMeasurements({ commit }) {
    commit('setIsLoading', true)
    await axios
        .get('warehouse/measurements/')
        .then(res => {
            commit('setMeasurements', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

// get All Act Reco
async function getAllActRecont({ commit }) {
    await axios
        .get('contracts/clientcontracts/actsverka/list/')
        .then(res => {
            commit('setAllActRecont', res.data)
        })
        .catch(err => console.log(err))
}

// get All Act Reco by id
async function getAllActRecontDetails({ commit }, payload) {
    await axios
        .get('contracts/clientcontracts/actsverka/details/' + payload + '/')
        .then(res => {
            commit('setAllActRecontDetails', res.data)
        })
        .catch(err => console.log(err))
}

async function getAllPersonal({ commit }, payload) {
    commit('setIsLoading', true)
    if (payload !== undefined) {
        await axios
            .get('staff/personal/list/', {
                params: {
                    page: payload
                }
            })
            .then(res => {
                commit('setAllPersonal', res.data)
                commit('setIsLoading', false)
            })
            .catch(err => console.log(err))
    } else {
        commit('setIsLoading', false)
    }
}

async function staffVacations({ commit }, payload) {
    await axios
        .get('staff/vacations/list/', {
            params: {
                page: payload
            }
        })
        .then(res => {
            commit('setStaffVacations', res.data)
        })
        .catch(err => console.log(err))
}

async function getCompanyGeneralInfo({ commit }) {
    await axios
        .get('general/company_general_info/')
        .then(res => {
            commit('setCompanyGeneralInfo', res.data)
        })
        .catch(err => console.log(err))
}

async function getDetailsSalaryPaymentStatements({ commit }, payload) {
    await axios
        .get('staff/salary/paymentstatements/list/' + payload + '/')
        .then(res => {
            commit('setDetailsSalaryPaymentStatements', res.data)
        })
        .catch(err => console.log(err))
}

function updatePaymentStatemnts({ dispatch }, payload) {
    console.log(payload)
    axios
        .put('staff/salary/paymentstatements/elements/list/update/', payload)
        .then(() => {
            success()
            router.push('/payment_statements')
            dispatch('getPaymentStatements', '1')
        })
        .catch(err => console.log(err))
}

function vacationUpdate({ dispatch }, payload) {
    axios
        .put('staff/vacations/list/' + payload.id + '/', payload.data)
        .then(() => {
            success()
            dispatch('staffVacations')
        })
        .catch(err => console.log(err))
}

function createStaffVacations({ dispatch }, payload) {
    axios
        .post('staff/vacations/list/', payload)
        .then(() => {
            dispatch('staffVacations')
            success()
        })
        .catch(err => console.log(err))
}

async function getAllStaff({ commit }) {
    commit('setIsLoading', true)
    await axios
        .get('staff/employed/list/')
        .then(res => {
            commit('setAllStaff', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function getDeliveredOutfacture({ commit }) {
    commit('setIsLoading', true)
    await axios
        .get('zayavkas/list/delivered-outfacture/')
        .then(res => {
            commit('setDeliveredOutfacture', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

function illnessesUpdate({ dispatch }, payload) {
    axios
        .put('staff/employed/list/' + payload.id + '/', payload.data)
        .then(() => {
            success()
            dispatch('getAllStaffIllnesses')
        })
        .catch(err => console.log(err))
}

function createAllStaff({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
        axios
            .post('staff/employed/list/', payload)

            .then(() => {
                success()

                dispatch('getAllStaff')
                dispatch('getAllDrivers')
                dispatch('getAllNonEmployedStaff')
                resolve()
            })
            .catch(err => {
                console.log(err)
                reject(err)
            })
    })
}

// function createAllStaff({ dispatch }, payload) {
//   axios
//     .post("staff/employed/list/", payload)
//     .then(() => {
//       success();
//       dispatch("getAllStaff");
//     })
//     .catch(err => console.log(err));
// }

// get nonemployed list
async function getAllNonEmployedStaff({ commit }) {
    await axios
        .get('staff/nonemployed/list/')
        .then(res => {
            commit('setAllNonEmployedStaff', res.data)
        })
        .catch(err => console.log(err))
}

async function getServiceCash({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('warehouse/goodsservices/?page=' + payload + '&payment_source=2')
        .then(res => {
            commit('setIsLoading', false)
            commit('setServiceCash', res.data)
        })
        .catch(err => console.log(err))
}

async function getRealisations({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('warehouse/goodsservices/', {
            params: {
                operation_type: payload.operation_type,
                page: payload.page
            }
        })
        .then(res => {
            commit('setRealizations', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

// get All Act RecoPag
async function getAllActRecontPag({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('contracts/clientcontracts/actsverka/list/', {
            params: {
                page: payload
            }
        })
        .then(res => {
            commit('setAllActRecont', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function getPaymentOrderTypes({ commit }) {
    commit('setIsLoading', true)
    await axios
        .get('finance/transfers/payment_order_types/')
        .then(res => {
            commit('setPaymentOrderTypes', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function getTransferSalaryList({ commit }, payload) {
    // console.log('list', payload)
    commit('setIsLoading', true)
    await axios
        .get('finance/transfers/for_salary/list/', {
            params: {
                payment_source: payload.payment_source,
                type_transfer: payload.type_transfer
            }
        })
        .then(res => {
            commit('setTransferSalaryList', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function getStaffEmployedList({ commit }) {
    commit('setIsLoading', true)
    await axios
        .get('staff/employed/list/')
        .then(res => {
            commit('setStaffEmployedList', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function getTypeStateTransfers({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('finance/transfers/type_statetransfers/', {
            params: {
                payment_source: payload.payment_source,
                order_type: payload.order_type
            }
        })
        .then(res => {
            commit('setTypeStateTransfers', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

function getTransferDetails({ commit }, payload) {
    return axios
        .get('finance/transfer/details/' + payload + '/')
        .then(res => {
            commit('setTransferDetails', res.data)
        })
        .catch(err => console.log(err))
}

function getProviderContractPayment({ commit }, payload) {
    return axios
        .get('providers/contract/operations/by_contract_id/' + payload + '/')
        .then(res => {
            commit('ProviderContractPaymentCommit', res.data)
        })
        .catch(err => console.log(err))
}

async function getAllUssers({ commit }) {
    commit('setIsLoading', true)

    await axios
        .get('clients/list/')
        .then(res => {
            commit('setAllUsers', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function getAllGroups({ commit }) {
    await axios
        .get('general/groups/')
        .then(res => {
            console.log('res --- - - - - ',res.data)
            if (res.data){
                commit('setAllGroups', res.data)
            }
        })
        .catch(err => console.log(err))
}

function personalCreate({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
        axios
            .post('staff/personal/list/', payload)
            .then(() => {
                success()
                router.push('/allpersonal')
                dispatch('getAllPersonal', 1)
                resolve()
            })
            .catch(err => {
                console.log(err)
                reject(err)
            })
    })
}

function personalUpdate({ dispatch }, payload) {
    console.log(payload)
    axios
        .put('staff/personal/list/' + payload.id.id + '/', payload.data)
        .then(() => {
            success()
            dispatch('getAllPersonal', 1)
        })
        .catch(err => console.log(err))
}

function payrolCreate({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
        axios
            .post('staff/salary/payrol/list/', payload)
            .then(() => {
                success()
                dispatch('getPayrolList')
                router.push('/salary_payrol')
                resolve()
            })
            .catch(err => {
                console.log(err)
                reject(err)
            })
    })
}

async function getPayrolList({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('staff/salary/payrol/list/', {
            params: {
                page: payload
            }
        })
        .then(res => {
            commit('setPayrolList', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function getFinanceBalance({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('finance/balans/', {
            params: {
                oper_date: payload
            }
        })
        .then(res => {
            commit('setFinanceBalance', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function getStaffEmployedListForMonth({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('staff/employed/list/for_month/', {
            params: {
                month: payload.month,
                year: payload.year
            }
        })
        .then(res => {
            commit('setStaffEmployedListForMonth', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function getPayrolListDetails({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('staff/salary/payrol/list/' + payload + '/')
        .then(res => {
            commit('setPayrolListDetails', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function getAllBrands({ commit }) {
    commit('setIsLoading', true)
    await axios
        .get('general/truckbrands/')
        .then(res => {
            commit('setAllBrands', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => {
            console.log(err)
            commit('setIsLoading', false)
        })
}

// Get all truck Brands
async function getAllTruckBrands({ commit }) {
    await axios
        .get('general/truckbrands/')
        .then(res => {
            commit('setAllTrcukBrands', res.data)
        })
        .catch(err => console.log(err))
}

async function getPositions({ commit }) {
    await axios
        .get('general/otherpersonalpositions/')
        .then(res => {
            commit('setPositions', res.data)
        })
        .catch(err => console.log(err))
}

async function getClientStatus({ commit }) {
    return axios
        .get('general/clientstatus/')
        .then(res => {
            commit('setClientStatus', res.data)
            return (res && res.data) || []
        })
        .catch(err => console.log(err))
}

async function createStaffEmployedList({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .post('staff/worked_days/', payload)
        .then(res => {
            console.log(res)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function getRegions({ commit }) {
    await axios
        .get('general/oblasts/regions/')
        .then(res => {
            commit('setRegions', res.data)
            commit('setAllRegions', res.data)
        })
        .catch(err => console.log(err))
}

async function getOblast({ commit }) {
    await axios
        .get('general/oblasts/')
        .then(res => {
            commit('setOblast', res.data)
            // commit("setAllRegions", res.data);
        })
        .catch(err => console.log(err))
}

async function getRegionByOblast({ commit }, payload) {
    await axios
        .get('general/oblasts/' + payload + '/')
        .then(res => {
            commit('setRegionByOblast', res.data)
            // commit("setAllRegions", res.data);
        })
        .catch(err => console.log(err))
}

async function getOwnership({ commit }) {
    await axios
        .get('general/ownership-types/')
        .then(res => {
            commit('setOwnership', res.data)
        })
        .catch(err => console.log(err))
}

async function getAllContracts({ commit }) {
    commit('setIsLoading', true)
    await axios
        .get('contracts/clientcontracts/')
        .then(res => {
            commit('setAllContracts', res.data)
            commit('setIsLoading', false)
            // commit("setContract", res.data);
        })
        .catch(err => console.log(err))
}

async function getAllContractsPag({ commit }, payload) {
    commit('setIsLoading', true)
    //
    await axios
        .get('contracts/clientcontracts/?page=' + payload)
        .then(res => {
            commit('setAllContracts', res.data)
            commit('setIsLoading', false)
            // commit("setContract", res.data);
        })
        .catch(err => console.log(err))
}

async function getTechMonitor({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('trucks/tech_monitor/', {
            params: {
                page: payload
            }
        })
        .then(res => {
            commit('setTechMonitor', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

function changeTruckStatus({ commit, dispatch }, payload) {
    commit('setIsLoading', true)
    axios
        .put('trucks/status/update/' + payload.id + '/', payload.data)
        .then(() => {
            dispatch('getTruckLists')
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function getMainAssetDetailsList({ commit }) {
    commit('setIsLoading', true)
    await axios
        .get('warehouse/main_assets_doc/elements')
        .then(res => {
            commit('setMainAssetsDocDetails', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

function createTechMonitor({ commit }, payload) {
    commit('setIsLoading', true)
    return new Promise((resolve, reject) => {
        axios
            .post('trucks/tech_monitor/', payload)
            .then(() => {
                // router.push("/techmonitor");
                router.go(-1)
                Swal.fire({
                    title: '',
                    text: "Ma'lumotlar yuborildi!",
                    icon: 'success'
                })
                commit('setIsLoading', false)
                resolve()
            })
            .catch(err => {
                reject(err)
                commit('setIsLoading', false)
                console.log(err)
            })
    })
}

function deleteTechMonitor({ dispatch, commit }, payload) {
    commit('setIsLoading', true)
    axios
        .delete('trucks/tech_monitor/' + payload.id)
        .then(() => {
            Swal.fire({
                title: '',
                text: "Ma'lumotlar o'chirildi!",
                icon: 'success'
            })
            dispatch('truckListall', payload.page)
            dispatch('getTechMonitor', payload.page)
        })
        .catch(err => console.log(err))
}

function updateTechMonitor({ commit }, payload) {
    commit('setIsLoading', true)
    axios
        .put('trucks/tech_monitor/' + payload.id + '/', payload.data)
        .then(() => {
            Swal.fire({
                title: '',
                text: "Ma'lumotlar yuborildi!",
                icon: 'success'
            })
            router.go(-1)
        })
}

function mainAssetsMainPart({ commit }, payload) {
    commit('setIsLoading', true)
    axios
        .put('warehouse/main_assets/details/' + payload.id + '/', payload.data)
        .then(() => {
            // dispatch('getReAmor', payload.pageIndex)
        })
        .catch(err => console.log(err))
}

function mainAssetsMainDocPart({ commit, dispatch }, payload) {
    commit('setIsLoading', true)
    axios
        .put('warehouse/main_assets_doc/details/' + payload.id + '/', payload.data)
        .then(() => {
            dispatch('getmainassetsDetails', payload.parentId)
        })
        .catch(err => console.log(err))
}

function mainAssetTakenUpdate({ commit, dispatch }, payload) {
    commit('setIsLoading', true)
    axios
        .put('warehouse/main_assets_doc/details/' + payload.id + '/', payload.data)
        .then(() => {
            dispatch('mainAssetTakenDetails', payload.id)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

function mainAssetTakenDetails({ commit }, payload) {
    commit('setIsLoading', true)
    axios
        .get('warehouse/main_assets_doc/details/' + payload + '/')
        .then(res => {
            commit('setIsLoading', false)
            commit('setMainAssetTakenDetails', res.data)
        })
        .catch(err => console.log(err))
}

function mainAssetsMainDocDetails({ commit, dispatch }, payload) {
    commit('setIsLoading', true)
    delete payload.data.id
    axios
        .put(
            'warehouse/main_assets_doc/elements/details/' + payload.id + '/',
            payload.data
        )
        .then(() => {
            dispatch('getmainassetsDetails', payload.parentId)
            dispatch('getReAmor', payload.pageIndex)
            Swal.fire({
                title: '',
                text: "Ma'lumotlar yuborildi!",
                icon: 'success'
            })
        })
        .catch(err => console.log(err))
}

function mainAssetsDelete({ commit, dispatch }, payload) {
    commit('setIsLoading', true)
    axios
        .get('warehouse/main_assets/delete/' + payload.id + '/')
        .then(() => {
            dispatch('getmainassets')
            Swal.fire({
                title: '',
                text: "Ma'lumotlar o'chirildi!",
                icon: 'success'
            })

            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

function removeMainAssetsDocElement({ commit, dispatch }, payload) {
    commit('setIsLoading', true)
    axios
        .put('warehouse/main_assets_doc/details/' + payload.id + '/', payload.data)
        .then(() => {
            dispatch('getmainassetsDetails', payload.parentId)
            dispatch('mainAssets', '1')
            dispatch('getReAmor', payload.pageIndex)
            Swal.fire({
                title: '',
                text: "Ma'lumotlar yuborildi!",
                icon: 'success'
            })

            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

// update measurements
function updateMeasurements({ dispatch }, payload) {
    axios
        .put('warehouse/measurements/' + payload.id.id + '/', payload.data)
        .then(() => {
            Swal.fire({
                title: '',
                text: "Ma'lumotlar yuborildi!",
                icon: 'success'
            })
            dispatch('getMeasurements')
        })
        .catch(() => {
            Swal.fire({
                title: '',
                text: "Ma'lumotlar yuborilmadi!",
                icon: 'error'
            })
        })
}

async function getGasEquips({ commit }, payload) {
    commit('setIsLoading', true)

    await axios
        .get('trucks/gas_equips/', {
            params: {
                page: payload
            }
        })
        .then(res => {
            commit('setGasEquips', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function getGasEquipsDetails({ commit }, payload) {
    commit('setIsLoading', true)

    await axios
        .get('trucks/gas_equips/' + payload + '/')
        .then(res => {
            commit('setGasEquipsDetails', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

function createGasEquips({ commit }, payload) {
    commit('setIsLoading', true)
    return new Promise((resolve, reject) => {
        axios
            .post('trucks/gas_equips/', payload)
            .then(() => {
                Swal.fire({
                    title: '',
                    text: "Ma'lumotlar yuborildi!",
                    icon: 'success'
                })
                router.go(-1)
                commit('setIsLoading', false)
                resolve()
            })
            .catch(err => {
                console.log(err)
                reject(err)
            })
    })
}

function deleteGasEquips({ dispatch, commit }, payload) {
    commit('setIsLoading', true)
    axios
        .delete('/trucks/gas_equips/' + payload.id + '/')
        .then(() => {
            Swal.fire({
                title: '',
                text: "Ma'lumotlar o'chirildi!",
                icon: 'success'
            })
            dispatch('truckListall', payload.page)
            dispatch('getGasEquips', payload.page)
        })
        .catch(err => console.log(err))
}

function updateGasEquips({ commit }, payload) {
    commit('setIsLoading', true)
    axios
        .put('/trucks/gas_equips/' + payload.id + '/', payload.data)
        .then(() => {
            Swal.fire({
                title: '',
                text: "Ma'lumotlar o'chirildi!",
                icon: 'success'
            })
            router.go(-1)
        })
        .catch(err => console.log(err))
}

async function getAdvertPermits({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('trucks/reklama_permits/', {
            params: {
                page: payload
            }
        })

        .then(res => {
            commit('setAdvertPermits', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function getAdvertPermitsDetails({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('trucks/reklama_permits/' + payload + '/')
        .then(res => {
            commit('setAdvertPermitsDetails', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

// get nomenklatura group

async function nomenklaturaGroup({ commit }) {
    commit('setIsLoading', true)
    await axios
        .get('warehouse/import_category_group/')
        .then(res => {
            commit('nomenklaturaGroupCommit', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function mainAssetsRemarks({ commit }) {
    commit('setIsLoading', true)
    await axios
        .get('warehouse/main_assets/remark/')
        .then(res => {
            commit('setMainAssetsRemarks', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

function mainAssetsRemarksUpdate({ commit }, payload) {
    commit('setIsLoading', true)
    axios
        .put('warehouse/main_assets/remark/' + payload.id + '/', payload.data)
        .then(() => {
            Swal.fire({
                title: '',
                text: "Ma'lumotlar yuborildi!",
                icon: 'success'
            })
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

function mainAssetsRemarksCreate({ commit, dispatch }, payload) {
    commit('setIsLoading', true)
    axios
        .post('warehouse/main_assets/doremark/', payload.data)
        .then(() => {
            Swal.fire({
                title: '',
                text: "Ma'lumotlar yuborildi!",
                icon: 'success'
            })
            commit('setIsLoading', false)
            dispatch('getmainassetsDetails', payload.id)
        })
        .catch(err => console.log(err))
}

function mainAssetsRemarksDelete({ commit, dispatch }, payload) {
    commit('setIsLoading', true)
    axios
        .delete('warehouse/main_assets/remark/' + payload.id)
        .then(() => {
            Swal.fire({
                title: '',
                text: "Ma'lumotlar yuborildi!",
                icon: 'success'
            })
            commit('setIsLoading', false)
            dispatch('mainAssetsRemarks')
        })
        .catch(err => console.log(err))
}

// create nomenklatura group
function createNomenklaturaGroup({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
        axios
            .post('warehouse/import_category_group/', payload)
            .then(() => {
                Swal.fire({
                    title: '',
                    text: "Ma'lumotlar yuborildi!",
                    icon: 'success'
                })
                dispatch('getNomenklaturaGroup')
                resolve()
            })
            .catch(err => {
                console.log(err)
                reject(err)
            })
    })
}

// update nomenklatura group
function updateNomenklaturaGroup({ dispatch }, payload) {
    axios
        .put('warehouse/import_category_group/' + payload.id.id + '/', payload.data)
        .then(() => {
            Swal.fire({
                title: '',
                text: "Ma'lumotlar yuborildi!",
                icon: 'success'
            })
            dispatch('getNomenklaturaGroup')
        })
        .catch(err => console.log(err))
}

// delete nomenklatura group
function deteleNomenklaturaGroup({ dispatch }, payload) {
    axios
        .delete('warehouse/import_category_group/' + payload + '/')
        .then(() => {
            Swal.fire({
                title: '',
                text: "Ma'lumotlar ochirildi!",
                icon: 'success'
            })
            dispatch('getNomenklaturaGroup')
        })

        .catch(err => {
            if (err.response.status == '487') {
                Swal.fire({
                    title: '',
                    text: err.response.data.detail,
                    icon: 'error'
                })
            }
        })
}

function createAdvertPermits({ commit }, payload) {
    commit('setIsLoading', true)
    return new Promise((resolve, reject) => {
        axios
            .post('trucks/reklama_permits/', payload)
            .then(() => {
                router.go(-1)
                commit('setIsLoading', false)
                Swal.fire({
                    title: '',
                    text: "Ma'lumotlar yuborildi!",
                    icon: 'success'
                })
                resolve()
            })
            .catch(err => {
                console.log(err)
                reject(err)
            })
    })
}

function deleteAdvertPermits({ dispatch, commit }, payload) {
    commit('setIsLoading', true)
    axios
        .delete('trucks/reklama_permits/' + payload.id + '/')
        .then(() => {
            Swal.fire({
                title: '',
                text: "Ma'lumotlar o'chirildi!",
                icon: 'success'
            })
            dispatch('truckListall', payload.page)
            dispatch('getAdvertPermits', payload.page)
        })
        .catch(err => console.log(err))
}

function updateAdvertPermits({ commit }, payload) {
    commit('setIsLoading', true)
    axios
        .put('trucks/reklama_permits/' + payload.id + '/', payload.data)
        .then(() => {
            router.go(-1)
        })
        .catch(err => console.log(err))
}

async function getInsuarences({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('trucks/insuarences/', {
            params: {
                page: payload
            }
        })
        .then(res => {
            commit('setInsuarences', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

function creategetInsuarences({ commit }, payload) {
    commit('setIsLoading', true)
    return new Promise((resolve, reject) => {
        axios
            .post('trucks/insuarences/', payload)
            .then(() => {
                success()
                router.go(-1)
                commit('setIsLoading', false)
                resolve()
            })
            .catch(err => {
                console.log(err)
                reject(err)
            })
    })
}

function deletegetInsuarences({ dispatch, commit }, payload) {
    commit('setIsLoading', true)
    axios
        .delete('trucks/insuarences/' + payload.id + '/')
        .then(() => {
            Swal.fire({
                title: '',
                text: "Ma'lumotlar o'chirildi!",
                icon: 'success'
            })
            dispatch('truckListall', payload.page)
            dispatch('getInsuarences', payload.page)
        })
        .catch(err => console.log(err))
}

function updategetInsuarences({ commit }, payload) {
    commit('setIsLoading', true)
    axios
        .put('trucks/insuarences/' + payload.id + '/', payload.data)
        .then(() => {
            Swal.fire({
                title: '',
                text: "Ma'lumotlar o'zgartirildi!",
                icon: 'success'
            })
            router.go(-1)
        })
        .catch(err => console.log(err))
}

async function getAllDrivers({ commit }) {
    commit('setIsLoading', true)
    await axios
        .get('drivers/list/')
        .then(res => {
            if(res.data){
                commit('setAllDrivers', res.data)
                commit('setIsLoading', false)
            }
        })
        .catch(err => {
            console.log(err);
            commit('setIsLoading', false)
        })
}

async function getDriverDetails({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('drivers/list/' + payload + '/')
        .then(res => {
            commit('setDriverDetails', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function getAllDriversRevenues({ commit }) {
    await axios
        .get('drivers/list/with_revenues/')
        .then(res => {
            commit('setAllDriversRevenues', res.data)
        })
        .catch(err => console.log(err))
}

async function getAllDriversRevenuesFromToDate({ commit }, payload) {
    console.log(payload)
    await axios
        .get(
            'drivers/list/with_revenues/?start_date=' +
            payload.start_date +
            '&end_date=' +
            payload.end_date
        )
        .then(res => {
            commit('setAllDriversRevenues', res.data)
        })
        .catch(err => console.log(err))
}

async function getAllDriversById({ commit }, payload) {
    await axios
        .get('drivers/list/' + payload + '/')
        .then(res => {
            commit('setAllDriversById', res.data)
        })
        .catch(err => console.log(err))
}

async function getAllFacturas({ commit }) {
    commit('setIsLoading', true)
    await axios
        .get('contracts/clientcontracts/factures/')
        .then(res => {
            commit('setIsLoading', false)

            commit('setAllFacturas', res.data)
        })
        .catch(err => console.log(err))
}

function getAllFacturasPage({ commit }, { page, params }) {
    commit('setIsLoading', true)
    const CancelToken = axios.CancelToken
    let cancel

    if (cancel) {
        cancel()
    }
    return axios
        .get('contracts/clientcontracts/factures/', {
            params: {
                ...params,
                page: page || 1
            },
            cancelToken: new CancelToken(function executor(c) {
                cancel = c
            })
        })
        .then(res => {
            commit('setAllFacturas', res.data)
            commit('setIsLoading', false)
            return (res && res.data) || {}
            // commit("setContract", res.data);
        })
        .catch(err => console.log(err))
}

async function getAllProvidersPag({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('providers/list/?page=' + payload)
        .then(res => {
            commit('setIsLoading', false)
            commit('setAllProviders', res.data)
            // commit("setContract", res.data);
        })
        .catch(err => console.log(err))
}

async function getFacturaDeatil({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('contracts/clientcontracts/factures/' + payload + '/')
        .then(res => {
            commit('setIsLoading', false)
            commit('setFacturaDetail', res.data)
        })
        .catch(err => console.log(err))
}

async function getAllTruckTypes({ commit }) {
    commit('setIsLoading', true)
    await axios
        .get('general/trucktypes/')
        .then(res => {
            commit('setIsLoading', false)
            commit('setAllTruckTypes', res.data)
        })
        .catch(err => console.log(err))
}

function CreateTruckTypes({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
        axios
            .post('general/trucktypes/', payload)
            .then(res => {
                console.log(res)
                dispatch('getAllTruckTypes')
                success()
                resolve()
            })
            .catch(err => {
                console.log(err)
                reject(err)
            })
    })
}

async function allFacturasFilter({ commit }, data) {
    commit('setIsLoading', true)
    await axios
        .get('contracts/clientcontracts/factures/search/', data)
        .then(res => {
            commit('setAllFacturas', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function allFacturasSearchPerPages({ commit }, data) {
    commit('setIsLoading', true)
    await axios
        .post(
            'contracts/clientcontracts/factures/search/?page=' + data.id,
            data.payload
        )
        .then(res => {
            commit('setAllFacturas', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

function getAllProviders({ commit }, params) {
    return axios
        .get('providers/list/', { params })
        .then(res => {
            commit('setAllProviders', res.data)
            return (res && res.data) || []
        })
        .catch(err => console.log(err))
}

async function getProductCondition({ commit }) {
    await axios
        .get('warehouse/product_condition/')
        .then(res => {
            commit('setProductCondition', res.data)
        })
        .catch(err => console.log(err))
}

function getStoredproductsForExport({ commit }, payload) {
    return axios
        .get('warehouse/storedproducts/for_export/', {
            params: {
                warehouse: payload
            }
        })
        .then(res => {
            commit('setStoredproductsForExport', res.data)
        })
        .catch(err => console.log(err))
}

async function getGoodsServicesImport({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('warehouse/goodsservices/', {
            params: {
                operation_type: payload.operation_type,
                page: payload.page
            }
        })
        .then(res => {
            commit('setIsLoading', false)
            commit('setGoodsServicesImport', res.data)
        })
        .catch(err => console.log(err))
}

async function getProviderDetails({ commit }, payload) {
    await axios
        .get('providers/detail/' + payload + '/')
        .then(res => {
            let data = {}
            Object.keys(res.data).forEach(key => {
                data[key] = res.data[key] || ''
            })
            commit('setProviderDetails', data)
        })
        .catch(err => console.log(err))
}

// Get one provider Contract details
async function getProviderContractdetail({ commit }, payload) {
    await axios
        .get('contracts/providercontracts/' + payload + '/')
        .then(res => {
            commit('setProviderContractDetail', res.data)
        })
        .catch(err => console.log(err))
}

// Get only clients Names
async function getAllClientsName({ commit }) {
    commit('setIsLoading', true)
    return axios
        .get('clients/onlynames/')
        .then(res => {
            commit('setIsLoading', false)
            commit('setAllClientsName', res.data)
        })
        .catch(err => {
            commit('setIsLoading', false)
            console.log(err)
        })
}

async function getIncomeDashboard({ commit }) {
    await axios
        .get('finance/income/total/by_months/')
        .then(res => {
            commit('setIncomeDashboard', res.data)
        })
        .catch(err => console.log(err))
}

async function getExpenditureDashboard({ commit }) {
    await axios
        .get('finance/expenditure/total/by_months/')
        .then(res => {
            commit('setExpenditureDashboard', res.data)
        })
        .catch(err => console.log(err))
}

// Get completed job
function getCurrCompJob({ commit }, payload) {
    commit('setIsLoading', true)
    return axios
        .get('contracts/clientcontracts/completed-jobs/' + payload + '/')
        .then(res => {
            const reconvertedCompJobs = ((res && res.data) || []).map(item => ({
                ...item,
                start_date: item.start_date.slice(0, 10),
                end_date: item.end_date.slice(0, 10),
            }))
            commit('setCurrCompJob', reconvertedCompJobs)
            commit('setIsLoading', false)
        })
        .catch(err => {
            console.log(err)
            commit('setIsLoading', false)
        })
}

// Get client Contract by id
function getClientContract({ commit }, payload) {
    return axios
        .get('contracts/clientcontracts/detail/' + payload + '/')
        .then(res => {
            commit('setContractDetails', res.data)
            commit('setZayavkaDetail', res.data)
            commit('setAllMarshrut', res.data)
        })
        .catch(err => console.log(err))
}

function updateContract({ commit, dispatch }, payload) {
    commit('setIsLoading', true)
    axios
        .put('contracts/clientcontracts/update/' + payload.id + '/', payload.data)
        .then(() => {
            commit('setIsLoading', false)
            dispatch('getAllContracts')
            router.push({ path: '/allcontracts' })
            success()
        })
        .catch(err => console.log(err))
}

// get additionalcontracts
async function getAllAdditionalContracts({ commit }, payload) {
    await axios
        .get('contracts/clientcontracts/additionalcontracts/' + payload + '/')
        .then(res => {
            commit('setAllAdditionalContracts', res.data)
        })
        .catch(err => console.log(err))
}

async function getTruckLists({ commit }) {
    commit('setIsLoading', true)
    return axios
        .get('/trucks/list/')
        .then(res => {
            commit('setTruckLists', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => {
            console.log(err)
            commit('setIsLoading', false)
        })
}

async function truckListsSearch({ commit }, data) {
    commit('setIsLoading', true)
    await axios
        .post('trucks/search/', data)
        .then(res => {
            commit('setIsLoading', false)
            commit('setTruckLists', res.data)
        })
        .catch(err => console.log(err))
}

async function getInsuranceTruckDetails({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('trucks/insuarences/' + payload + '/')
        .then(res => {
            commit('setIsLoading', false)
            commit('setInsuranceTruckDetails', res.data)
        })
        .catch(err => console.log(err))
}

async function getTechMonitorDetails({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('trucks/tech_monitor/' + payload + '/')
        .then(res => {
            commit('setTechMonitorDetails', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

// truck list
async function truckListall({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('trucks/list/' + payload + '/')
        .then(res => {
            // console.log("888", res.data)
            commit('setIsLoading', false)
            commit('truckListallCommit', res.data)
        })
        .catch(err => console.log(err))
}

// gettruck list by id
function getTruckListById({ commit }, payload) {
    axios
        .get('trucks/list/' + payload + '/')
        .then(res => {
            commit('setTruckTypesId', res.data)
        })
        .catch(err => console.log(err))
}

// get allZayavka

function deleteZayavka({ dispatch }, payload) {
    axios
        .delete('zayavkas/list/' + payload.id + '/')
        .then(() => {
            dispatch('getAllZayavkaPage', payload.page)
        })
        .catch(err => console.log(err))
}

function deleteIlova({ dispatch }, payload) {
    axios
        .delete('contracts/contract/appendix/delete/' + payload.id + '/')
        .then(() => {
            dispatch('getClientContract', payload.page)
        })
        .catch(err => console.log(err))
}

async function contractPayments({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('contracts/clientcontracts/operations_bycontract/' + payload + '/')
        .then(res => {
            commit('setIsLoading', false)
            commit('setOperationsBycontract', res.data)
        })
        .catch(err => console.log(err))
}

// get allZayavka paginatin
function getAllZayavkaPage({ commit }, params) {
    commit('setIsLoading', true)
    return axios
        .get('zayavkas/list/', {
            params
        })
        .then(res => {
            commit('setIsLoading', false)
            commit('setAllZayavkaPag', res.data)
            commit('setAllZayavkaDatas', res.data)
        })
        .catch(err => console.log(err))
}

function getAllZayavkaPageWithParams({ commit }, { page, params }) {
    commit('setIsLoading', true)
    const CancelToken = axios.CancelToken
    let cancel

    if (cancel) {
        cancel()
    }

    return axios
        .get(`zayavkas/list/`, {
            params: {
                ...params,
                page: page || 1
            },
            cancelToken: new CancelToken(function executor(c) {
                cancel = c
            })
        })
        .then(res => {
            commit('setIsLoading', false)
            commit('setAllZayavkaPag', res.data)
            commit('setAllZayavkaDatas', res.data)
        })
        .catch(err => console.log(err))
}

async function getAllProvidersContract({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('contracts/providercontracts/', {
            params: {
                page: payload
            }
        })
        .then(res => {
            commit('setIsLoading', false)
            commit('setAllProvidersContract', res.data)
        })
        .catch(err => console.log(err))
}

async function getAllProvidersWithoutPg({ commit }) {
    await axios
        .get('providers/list/without_pg/')
        .then(res => {
            commit('setAllProvidersWithoutPg', res.data)
        })
        .catch(err => console.log(err))
}

async function getIncomesFromService({ commit }) {
    await axios
        .get('finance/plan/incomes_from_service/this_year/')
        .then(res => {
            commit('setIncomesFromService', res.data)
        })
        .catch(err => console.log(err))
}

function getProvidersContractById({ commit }, payload) {
    axios
        .get('contracts/providercontracts/byprovider_id/' + payload + '/')
        .then(res => {
            commit('setProviderContract', res.data)
        })
        .catch(err => console.log(err))
}

async function getNameOfContract({ commit }, payload) {
    await axios
        .get('contracts/contract/appendix/' + payload + '/')
        .then(res => commit('settNameOfContract', res.data))
        .catch(err => console.log(err))
}

// get zayavka by id
async function getZayavkaDetails({ commit }, payload) {
    axios
        .get('zayavkas/list/' + payload + '/')
        .then(res => {
            // console.log("1231321", res.data.contract_appendix.marshrut);
            commit('setAllZayavkaById', res.data)
            commit('loading', false)
        })
        .catch(err => console.log(err))
}

function updateZayavkaDetails({ commit }, payload) {
    axios
        .patch('zayavkas/update/' + payload.id + '/', payload.data)
        .then(res => {
            console.log(res)
            success()
            router.go(-1)
            commit('loading', false)
        })
        .catch(err => console.log(err))
}

async function getKontragentsContractsList({ commit }, payload) {
    commit('setIsLoading', true)
    axios
        .get('counteragents/contracts/', {
            params: {
                page: payload
            }
        })
        .then(res => {
            commit('setIsLoading', false)
            commit('setKontragentsContractsList', res.data)
        })
        .catch(err => {
            commit('setIsLoading', false)
            console.log(err)
        })
}

async function CreateContragentContract({ dispatch, commit }, payload) {
    commit('setIsLoading', true)
    return new Promise((resolve, reject) => {
        axios
            .post('counteragents/contracts/', payload)
            .then(() => {
                dispatch('getKontragentsContractsList', '1')
                commit('setIsLoading', false)
                Swal.fire({
                    title: '',
                    text: "Ma'lumotlar yuborildi!",
                    icon: 'success'
                })
                resolve()
            })
            .catch(err => {
                commit('setIsLoading', false)
                console.log(err)
                reject(err)
            })
    })
}

async function getKontragentsContractsListWithoutPg({ commit }) {
    await axios
        .get('counteragents/list/without_pg/')
        .then(res => {
            commit('setKontragentsContractsListWithoutPg', res.data)
        })
        .catch(err => console.log(err))
}

async function getCountragentKontracts({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('counteragents/contracts/' + payload + '/')
        .then(res => {
            commit('setCountragentKontracts', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

// update kontragent contract
function UpdateCountragentKontracts({ dispatch, commit }, payload) {
    console.log(payload)
    commit('setIsLoading', true)
    axios
        .put('counteragents/contracts/' + payload.value + '/', payload.data)
        .then(() => {
            dispatch('getKontragentsContractsList')

            Swal.fire({
                title: '',
                text: "Ma'lumotlar yuborildi!",
                icon: 'success'
            })
            router.push('/kontragents/contract/1')
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function getCountragentKontractsPayments({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('counteragents/contracts/operations/by_counteragent/' + payload)
        .then(res => {
            commit('setCountragentKontractsPayment', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function countragentKontractsPayments({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('counteragents/contracts/operations/by_contract/' + payload)
        .then(res => {
            commit('countragentKontractsPaymentsCommit', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function countragentKontractsSverka({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('counteragents/contracts/sverka/by_contract/' + payload + '/')
        .then(res => {
            commit('countragentKontractsSverkaCommit', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

// get kontragent contract aktsverka
async function kontragentContractAktSverka({ commit }) {
    commit('setIsLoading', true)
    await axios
        .get('counteragents/akt_sverka/list/')
        .then(res => {
            commit('kontragentContractAktSverkaCommit', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function getStartingPoint({ commit }, payload) {
    try {
        const res = await axios.get(
            'clients/starting_point/debet_credit/by_client_id/' + payload + '/'
        )
        commit('SET_STARTING_POINT', res.data)
    } catch (error) {
        console.log(error)
    }
}

async function getProviderStartingPoint({ commit }, payload) {
    try {
        const res = await axios.get(
            'providers/starting_point/debet_credit/by_provider_id/' + payload + '/'
        )
        commit('SET_PROVIDER_STARTING_POINT', res.data)
    } catch (error) {
        console.log(error)
    }
}

async function getCountragentStartingPoint({ commit }, payload) {
    try {
        const res = await axios.get(
            'counteragents/starting_point/debet_credit/by_client_id/' + payload + '/'
        )
        commit('SET_COUNTRAGENT_STARTING_POINT', res.data)
    } catch (error) {
        console.log(error)
    }
}

async function createCountragentStartingPoint({ dispatch, commit }, payload) {
    commit('setIsLoading', true)
    try {
        const res = await axios.post(
            'counteragents/starting_point/debet_credit/',
            payload
        )
        console.log(res)
        success()
        dispatch('getCountragentStartingPoint', payload.client)
        commit('setIsLoading', false)
        return true
    } catch (error) {
        console.log(error)
        commit('setIsLoading', false)
        return false
    }
}

async function createProviderStartingPoint({ dispatch, commit }, payload) {
    commit('setIsLoading', true)
    try {
        const res = await axios.post(
            'providers/starting_point/debet_credit/',
            payload
        )
        console.log(res)
        success()
        dispatch('getProviderStartingPoint', payload.client)
        commit('setIsLoading', false)
        return true
    } catch (error) {
        console.log(error)
        commit('setIsLoading', false)
        return false
    }
}

async function createStartingPoint({ dispatch, commit }, payload) {
    commit('setIsLoading', true)
    try {
        const res = await axios.post(
            'clients/starting_point/debet_credit/',
            payload
        )
        console.log(res)
        success()
        dispatch('getStartingPoint', payload.client)
        commit('setIsLoading', false)
        return true
    } catch (error) {
        console.log(error)
        commit('setIsLoading', false)
        return false
    }
}

async function updateStartingPoint({ dispatch, commit }, payload) {
    commit('setIsLoading', true)
    try {
        const res = await axios.put(
            'clients/starting_point/debet_credit/' + payload.id + '/',
            payload.data
        )
        console.log(res)
        success()
        dispatch('getStartingPoint', payload.data.client)
        commit('setIsLoading', false)
        return true
    } catch (error) {
        console.log(error)
        commit('setIsLoading', false)
        return false
    }
}

async function updateProviderStartingPoint({ dispatch, commit }, payload) {
    commit('setIsLoading', true)
    try {
        const res = await axios.put(
            'providers/starting_point/debet_credit/' + payload.id + '/',
            payload.data
        )
        console.log(res)
        success()
        dispatch('getProviderStartingPoint', payload.data.client)
        commit('setIsLoading', false)
        return true
    } catch (error) {
        console.log(error)
        commit('setIsLoading', false)
        return false
    }
}

async function updateCountragentStartingPoint({ dispatch, commit }, payload) {
    commit('setIsLoading', true)
    try {
        const res = await axios.put(
            'counteragents/starting_point/debet_credit/' + payload.id + '/',
            payload.data
        )
        console.log(res)
        success()
        dispatch('getCountragentStartingPoint', payload.data.client)
        commit('setIsLoading', false)
        return true
    } catch (error) {
        console.log(error)
        commit('setIsLoading', false)
        return false
    }
}

async function deleteStartingPoint({ dispatch }, payload) {
    try {
        const res = await axios.delete(
            'clients/starting_point/debet_credit/' + payload.id + '/'
        )
        console.log(res)
        dispatch('getStartingPoint', payload.client)
        return true
    } catch (error) {
        console.log(error)
        return false
    }
}

async function deleteProviderStartingPoint({ dispatch }, payload) {
    try {
        const res = await axios.delete(
            'providers/starting_point/debet_credit/' + payload.id + '/'
        )
        console.log(res)
        dispatch('getProviderStartingPoint', payload.client)
        return true
    } catch (error) {
        console.log(error)
        return false
    }
}

async function deleteCountragentStartingPoint({ dispatch }, payload) {
    try {
        const res = await axios.delete(
            'counteragents/starting_point/debet_credit/' + payload.id + '/'
        )
        console.log(res)
        dispatch('getCountragentStartingPoint', payload.client)
        return true
    } catch (error) {
        console.log(error)
        return false
    }
}

// get kontragent contract aktsverka pag
async function CounteragentsContractAktSverkaPag({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('counteragents/akt_sverka/list/', {
            params: {
                page: payload
            }
        })
        .then(res => {
            commit('kontragentContractAktSverkaCommit', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

// get provider contract akt sverka
async function providerContractAktSverka({ commit }) {
    commit('setIsLoading', true)
    await axios
        .get('providers/akt_sverka/list/')
        .then(res => {
            commit('providerContractAktSverkaCommit', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

// get provider contract akt sverka pag
async function providerContractAktSverkaPag({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('providers/akt_sverka/list/', {
            params: {
                page: payload
            }
        })
        .then(res => {
            commit('providerContractAktSverkaCommit', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

// get kontragent contract aktsverka by id
async function kontragentContractAktSverkaID({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('counteragents/akt_sverka/list/' + payload + '/')
        .then(res => {
            commit('kontragentContractAktSverkaIDCommit', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

// get provider contract aktsverka by id
async function providerContractAktSverkaID({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('providers/akt_sverka/list/' + payload + '/')
        .then(res => {
            commit('providerContractAktSverkaIDCommit', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function countragentKontractsSverkaDate({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('counteragents/contracts/sverka/by_contract/' + payload.id + '/', {
            params: {
                to_date: payload.to_date
            }
        })
        .then(res => {
            commit('countragentKontractsSverkaCommit', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function getCountragentKontractFacturas({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('counteragents/contracts/facturas/' + payload)
        .then(res => {
            commit('setCountragentKontractFacturas', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function countragentKontractFacturas({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('counteragents/contracts/facturas/by_contract/' + payload)
        .then(res => {
            commit('countragentKontractFacturasCommit', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

// get zayavka by id
async function getZaById({ commit }, payload) {
    await axios
        .get('zayavkas/bycontract/' + payload + '/')
        .then(res => {
            commit('setZaById', res.data)
        })
        .catch(err => console.log(err))
}

async function bankAccountLink({ commit }, payload) {
    await axios
        .get(
            'warehouse/main_assets/dailyamortizations/check_link_with_balansaccount',
            {
                params: {
                    bank_account_link: payload
                }
            }
        )
        .then(res => {
            commit('setBankAccountLink', res.data)
        })
        .catch(err => console.log(err))
}

function factureBycontract({ commit }, payload) {
    commit('setIsLoading', true)
    return axios
        .get('contracts/clientcontracts/facture-bycontract/' + payload + '/')
        .then(res => {
            commit('setIsLoading', false)
            commit('setFactureBycontract', res.data)
        })
        .catch(err => console.log(err))
}

// get ttn list
async function getAllTnn({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('zayavkas/ttnlist/', {
            params: { page: payload }
        })
        .then(res => {
            commit('setAllTnn', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

// get one client contract
async function getOneClientContract({ commit }, payload) {
    await axios
        .get('clients/contracts/' + payload + '/')
        .then(res => {
            commit('setOneClientContract', res.data)
        })
        .catch(err => console.log(err))
}

async function getProviderContractById({ commit }, payload) {
    await axios
        .get('contracts/providercontracts/byprovider_id/' + payload + '/')
        .then(res => {
            commit('setProviderContractById', res.data)
        })
        .catch(err => console.log(err))
}

async function getClientDetails({ commit }, payload) {
    await axios
        .get('clients/detail/' + payload + '/')
        .then(res => {
            commit('setOneClientDetails', res.data)
        })
        .catch(err => console.log(err))
}

// get one without facture

async function getDoneWorks({ commit }) {
    await axios
        .get('zayavkas/delivered/without-facture/')
        .then(res => {
            //
            commit('setDoneWorks', res.data)
        })
        .catch(err => console.log(err))
}

function getDoneWorksWithPage({ commit }, params) {
    commit('setIsLoading', true)
    return axios
        .get('zayavkas/delivered/without-facture/', {
            params
        })
        .then(res => {
            //
            commit('setDoneWorks', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => {
            console.log(err)
            commit('setIsLoading', false)
        })
}

// get cor cards
async function getAllCorCards({ commit }) {
    await axios
        .get('finance/corpcards/')
        .then(res => {
            commit('setAllCorCards', res.data)
        })
        .catch(err => console.log(err))
}

// get cor cards details
async function getCorpCardsDetails({ commit }, payload) {
    await axios
        .get('finance/corpcards/' + payload)
        .then(res => {
            commit('setCorpCardsDetails', res.data)
        })
        .catch(err => console.log(err))
}

// get operations by card
async function getOperByCard({ commit }, payload) {
    await axios
        .get('finance/corpcards/operations/bycard/' + payload + '/')
        .then(res => {
            //
            commit('setOperByCard', res.data)
        })
        .catch(err => console.log(err))
}

// get cor cards details
async function getCorpCardDetails({ commit }, payload) {
    await axios
        .get('finance/corpcards/' + payload)
        .then(res => {
            commit('setCorpCardDetails', res.data)
        })
        .catch(err => console.log(err))
}

// get main expenditures
async function getAllMainExp({ commit }) {
    await axios
        .get('finance/main_expenditures/')
        .then(res => {
            commit('setAllMainExp', res.data)
        })
        .catch(err => console.log(err))
}

// get cities
async function getAllCities({ commit }) {
    await axios
        .get('general/city/')
        .then(res => {
            commit('setAllCities', res.data)
        })
        .catch(err => console.log(err))
}

// get transfer list

async function getAllTransferList({ commit }, payload) {
    await axios
        .get('finance/transfers/list/', {
            params: {
                payment_source: payload.payment_source
            }
        })
        .then(res => {
            commit('setAllTransferList', res.data)
        })
        .catch(err => console.log(err))
}

// get total sum transfer
async function getTotalSum({ commit }, payload) {
    if (payload !== undefined) {
        await axios
            .get('finance/ttotal_sumransfers//', {
                params: {
                    payment_source: payload
                }
            })
            .then(res => {
                console.log('payloasd', res)
                commit('setTotalSum', res.data)
            })
            .catch(err => console.log(err))
    }
}

async function getAllTransferListPagBank({ commit }, data) {
    await axios
        .get('finance/transactions_daily/', {
            params: {
                payment_source: data.payment_source
            }
        })
        .then(res => {
            // commit('setAllTransferList', res.data)
            commit('setAlltransactionsDaily', res.data)
        })
        .catch(err => console.log(err))
}

async function getAllTransferListPagCash({ commit }, data) {
    await axios
        .get('finance/transactions_daily/', {
            params: {
                payment_source: data.payment_source
            }
        })
        .then(res => {
            // commit('setAllTransferListCash', res.data)
            commit('setAlltransactionsDaily', res.data)
        })
        .catch(err => console.log(err))
}

// get by type of transfer action
async function getTypeOfAction({ commit }, payload) {
    await axios
        .get('finance/transfers/type_statetransfers/' + payload)
        .then(res => {
            commit('setTypeOfAction', res.data)
        })
        .catch(err => console.log(err))
}

async function getTnnById({ commit }, payload) {
    await axios
        .get('zayavkas/ttnlist/' + payload + '/')
        .then(res => {
            // console.log("1231321", res.data.contract_appendix.marshrut);
            commit('setTnnId', res.data)
        })
        .catch(err => console.log(err))
}

// Get Nds
async function getNds({ commit }) {
    await axios
        .get('general/nds/')
        .then(res => {
            commit('setNds', res.data)
        })
        .catch(err => console.log(err))
}

// Get Truck Empty by id
async function getTruckEmptyById({ commit }, payload) {
    await axios
        .get('trucks/empty/' + payload + '/')

        .then(res => {
            commit('setTruckEmptyById', res.data)
        })
        .catch(err => console.log(err))
}

function mainExpenditures({ commit }) {
    return axios
        .get('finance/expenditure_total/by_categories/')
        .then(res => {
            commit('setMainExpenditures', res && res.data)
        })
        .catch(err => console.log(err))
}

async function getMainProfit({ commit }) {
    commit('setIsLoading', true)
    await axios
        .get('finance/main_incomes/')
        .then(res => {
            commit('setMainProfit', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

function createMainProfit({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
        axios
            .post('finance/main_incomes/', payload)
            .then(() => {
                dispatch('getMainProfit')
                success()
                resolve()
            })
            .catch(err => {
                console.log(err)
                reject(err)
            })
    })
}

function updateMainProfit({ dispatch }, payload) {
    axios
        .put('finance/main_incomes/' + payload.id.id + '/', payload.data)
        .then(() => {
            dispatch('getMainProfit')
            success()
        })
        .catch(err => console.log(err))
}

function deleteMainProfit({ dispatch }, payload) {
    axios
        .delete('finance/main_incomes/' + payload + '/')
        .then(() => {
            dispatch('getMainProfit')
            success()
        })
        .catch(err => console.log(err))
}

async function getMainProfitCategory({ commit }) {
    commit('setIsLoading', true)
    await axios
        .get('finance/income_categories/')
        .then(res => {
            commit('setMainProfitCategory', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function getDailyAmortizationMainAssets({ commit }) {
    commit('setIsLoading', true)
    await axios
        .get(
            'warehouse/main_assets/dailyamortizations/check_link_with_expenditures/'
        )
        .then(res => {
            commit('setDailyAmortizationMainAssets', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

function updateinancePlanIncomeYearly({ dispatch }, payload) {
    console.log(payload)
    axios
        .put('finance/plan/income_plans/' + payload.id + '/', payload.data)
        .then(() => {
            dispatch('getFinancePlanIncomeYearly')
        })
        .catch(err => console.log(err))
}

function createExpenditure({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
        axios
            .post('finance/expenditure/list/', payload + '/')
            .then(res => {
                console.log(res)
                dispatch('getFinanceExpenditureList')
                success()
                resolve()
            })
            .catch(err => {
                console.log(err)
                reject(err)
            })
    })
}

function createCategoryProfit({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
        axios
            .post('finance/income_categories/', payload)
            .then(() => {
                dispatch('getMainProfitCategory')
                success()
                resolve()
            })
            .catch(err => {
                console.log(err)
                reject(err)
            })
    })
}

function updateCategoryProfit({ dispatch }, payload) {
    axios
        .put('finance/income_categories/' + payload.id.id + '/', payload.data)
        .then(() => {
            dispatch('getMainProfitCategory')
            success()
        })
        .catch(err => console.log(err))
}

function updateBrand({ dispatch }, payload) {
    axios
        .put('general/truckbrands/' + payload.id + '/', payload.data)
        .then(() => {
            dispatch('getAllBrands')
            success()
        })
        .catch(err => console.log(err))
}

function deleteBrand({ dispatch }, payload) {
    axios
        .delete('general/truckbrands/' + payload.id)
        .then(() => {
            dispatch('getAllBrands')
            success()
        })
        .catch(err => console.log(err))
}

function newupdateTruck({ dispatch }, payload) {
    axios
        .put('general/trucktypes/' + payload.id + '/', payload.data)
        .then(() => {
            dispatch('getAllTruckTypes')
            success()
        })
        .catch(err => console.log(err))
}

function deleteCategoryProfit({ dispatch }, payload) {
    axios
        .delete('finance/income_categories/' + payload + '/')
        .then(() => {
            dispatch('getMainProfitCategory')
            success()
        })
        .catch(err => console.log(err))
}

async function getMainProfitSubCategory({ commit }) {
    commit('setIsLoading', true)
    await axios
        .get('finance/income_types/')
        .then(res => {
            commit('setMainProfitSubCategory', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function getMainProfitSubCategorySpecial({ commit }) {
    commit('setIsLoading', true)

    await axios
        .get('finance/main_incomes/special/')
        .then(res => {
            commit('setMainProfitSubCategorySpecial', res.data)
            console.log(res)
            // commit("setAllMfoBank", res.data);
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function getMainProfitSubCategorySpecialTotal({ commit }) {
    commit('setIsLoading', true)
    await axios
        .get('finance/income_total/by_categories/')
        .then(res => {
            commit('setMainProfitSubCategorySpecialTotal', res.data)
            // commit("setAllMfoBank", res.data);
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function getFinancePlanIncomeYearly({ commit }) {
    commit('setIsLoading', true)
    await axios
        .get('finance/plan/incomes_from_service/yearly/')
        .then(res => {
            commit('setFinancePlanIncomeYearly', res.data)
            // commit("setAllMfoBank", res.data);
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

function createinancePlanIncomeYearly({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
        axios
            .post('finance/plan/income_plans/', payload.data)
            .then(() => {
                dispatch('getFinancePlanIncomeYearly')
                success()
                resolve()
            })
            .catch(err => {
                console.log(err)
                reject(err)
            })
    })
}

async function getTopClientDashboard({ commit }) {
    commit('setIsLoading', true)
    await axios
        // .get('zayavkas/dashboard/topclients/')
        .get('dashboard/top_5_clients/')
        .then(res => {
            commit('setTopClientDashboard', res.data?.results || [])
            // commit("setAllMfoBank", res.data);
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

function setZayavkaItem(
    // eslint-disable-next-line no-unused-vars
    { commit },
    { zayavkaItemId = null, data = null } = {}
) {
    // if (!zayavkaItemId || !data) {
    //   alert('Jungle Eshon')
    //   return
    // }

    // console.log('v', zayavkaItemId, data)
    return axios.put(`zayavkas/zayavka/change_distance/${zayavkaItemId}/`, data)
}

async function getInfoDriverDashboard({ commit }) {
    commit('setIsLoading', true)
    await axios
        .get('drivers/dashboard/top_drivers/')
        .then(res => {
            commit('setInfoDriverDashboard', res.data)
            // commit("setAllMfoBank", res.data);
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function getLicenceTypes({ commit }) {
    commit('setIsLoading', true)
    await axios
        .get('trucks/licencetypes/list/')
        .then(res => {
            commit('setLicenceTypes', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function getMainExSubCategorySpecialTotal({ commit }) {
    commit('setIsLoading', true)
    await axios
        .get('finance/expenditure_total/by_categories/')
        .then(res => {
            commit('setMainExSubCategorySpecialTotal', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

function createSubCategoryProfit({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
        axios
            .post('finance/income_types/', payload)
            .then(() => {
                dispatch('getMainProfitSubCategory')
                success()
                resolve()
            })
            .catch(err => {
                console.log(err)
                reject(err)
            })
    })
}

function createIncome({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
        axios
            .post('finance/income/list/', payload)
            .then(() => {
                router.push('/incomes')
                success()
                dispatch('getIncomeList', '1')
                resolve()
            })
            .catch(err => {
                console.log(err)
                reject(err)
            })
    })
}

async function getIncomeList({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('finance/income/list/', {
            params: {
                page: payload
            }
        })
        .then(res => {
            commit('setIncomeList', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function expenditureIncomeTotal({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('finance/expenditure_income/total/by_months/', {
            params: {
                page: payload
            }
        })
        .then(res => {
            commit('setExpenditureIncomeTotal', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

function updateClientContAppen({ commit }, payload) {
    commit('setIsLoading', true)
    return axios
        .put(
            'contracts/contract/appendix/update/price/' + payload.id + '/',
            payload.data
        )
        .then(() => {
            // console.log(res)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

function updateClientContAppenDirection({ commit }, payload) {
    commit('setIsLoading', true)
    axios
        .post('contracts/contract/appendix/new_direction/add/', payload)
        .then(res => {
            console.log(res)
            commit('setIsLoading', false)
            success()
            router.go(-1)
        })
        .catch(err => console.log(err))
}

function addTruckToContractAppendixById(
    { commit },
    { contractId, new_truck_types }
) {
    commit('setIsLoading', true)
    return axios
        .put(`contracts/contract/appendix/update/add_truck/${contractId}/`, {
            new_truck_types
        })
        .then(res => {
            console.log(res)
            commit('setIsLoading', false)
        })
        .catch(err => {
            commit('setIsLoading', false)
            console.log(err)
        })
}

async function getIncomeListDetails({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('finance/income/list/' + payload + '/')
        .then(res => {
            commit('setIncomeListDetails', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function getAllAppen({ commit }) {
    commit('setIsLoading', true)
    await axios
        .get('contracts/contract/appendix/standart/list/')
        .then(res => {
            commit('setAllAppen', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function updateIncomeList({ dispatch, commit }, payload) {
    console.log(payload)
    commit('setIsLoading', true)
    await axios
        .put('finance/income/list/' + payload.id.id + '/', payload.data)
        .then(res => {
            console.log(res)
            dispatch('getIncomeList', '1')
            router.push('/incomes')
            success()
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

function createMainAssetRealizationFacturas({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
        axios
            .post('warehouse/main_assets/realisation/facturas/', payload)
            .then(res => {
                console.log(res)
                success()
                dispatch('getMainProfitSubCategory')
                router.go(-1)
                resolve()
            })
            .catch(err => {
                console.log(err)
                reject(err)
            })
    })
}

function getMainAssetRealizationFacturas({ commit }, payload) {
    commit('setIsLoading', true)
    axios
        .get('warehouse/main_assets/realisation/facturas/', payload)
        .then(res => {
            console.log(res)
            commit('setMainAssetRealizationFacturas', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

function upadateSubCategoryProfit({ dispatch }, payload) {
    axios
        .put('finance/income_types/' + payload.id.id + '/', payload.data)
        .then(() => {
            dispatch('getMainProfitSubCategory')
            success()
        })
        .catch(err => console.log(err))
}

async function getMainProfitSorted({ commit }) {
    commit('setIsLoading', true)
    await axios
        .get('finance/income_types/sorted/')
        .then(res => {
            commit('setMainProfitSorted', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

// Create Actions
function createClient({ dispatch }, data) {
    return new Promise((resovle, reject) => {
        axios
            .post('authapp/client/create_new/', data)
            .then(() => {
                dispatch('getAllUssers')
                router.push('/allUsers')
                resovle()
                success()
                // commit("setOwnership", res.data);
            })
            .catch(err => {
                reject(err)
                console.log(err)
            })
    })
}

function updateClient({ dispatch }, payload) {
    axios
        .put(`clients/update/${payload.id}/`, payload.data)
        .then(() => {
            dispatch('getAllUssers')
            router.go(-1)
            success()
            // commit("setOwnership", res.data);
        })
        .catch(err => {
            console.log(err)
        })
}

function createGoodsservices({ dispatch }, data) {
    console.log('createGoodsservicesExport3');
    return new Promise((resolve, reject) => {
        return axios
            .post('warehouse/goodsservices/', data)
            .then(() => {
                dispatch('getProductsList', '1')
                router.push('/products')
                Swal.fire({
                    title: "Ma'lumotlar yuborildi!",
                    text: "Ma'lumotlar yuborildi!",
                    icon: 'success'
                })
                resolve()
            })
            .catch(err => {
                console.log(err)
                Swal.fire({
                    title: "Ma'lumotlar yuborilishda xatolik!",
                    text: "Ma'lumotlar yuborilishda xatolik!",
                    icon: 'error'
                })
                reject(err)
            })
    })
}

function createGoodsservices2({ commit }, data) {
    console.log('createGoodsservicesExport4');
    return new Promise((resolve, reject) => {
        axios
            .post('warehouse/goodsservices/', data)
            .then(() => {
                commit('setIsLoading', false)
                router.push('/servicecash')
                Swal.fire({
                    title: '',
                    text: "Ma'lumotlar yuborildi!",
                    icon: 'success'
                })
                resolve()
            })
            .catch(err => {
                console.log(err)
                reject()
            })
    })
}

function createGoodsservices3({ commit }, data) {
    commit('setIsLoading', true)
    console.log('createGoodsservicesExport5');
    return new Promise((resolve, reject) => {
        axios
            .post('warehouse/goodsservices/', data)
            .then(() => {
                commit('setIsLoading', false)
                router.push('/servicecard')
                Swal.fire({
                    title: '',
                    text: "Ma'lumotlar yuborildi!",
                    icon: 'success'
                })
                resolve()
            })
            .catch(err => {
                console.log(err)
                commit('setIsLoading', false)
                reject(err)
            })
    })
}

async function editGoodsService3({commit}, payload) {
    commit('setIsLoading', true)
    try {
        await axios.put(`warehouse/goodsservices/update/${payload.id}/`, payload.data)
        commit('setIsLoading', false)
        Swal.fire({
            title: '',
            text: "Ma'lumotlar yuborildi!",
            icon: 'success'
        })
    } catch (err) {
        console.log(err);
        commit('setIsLoading', false)
    }
}

// Create contract
function createContract({ dispatch }, data) {
    return new Promise((resolve, reject) => {
        axios
            .post('contracts/clientcontracts/', data)
            .then(() => {
                dispatch('getAllContracts')
                // router.push("/users/allUsers");
                router.push({ path: '/allcontracts' })
                Swal.fire({
                    title: "Ma'lumotlar yuborildi!",
                    text: "Ma'lumotlar yuborildi!",
                    icon: 'success'
                })
                resolve()
            })
            .catch(err => {
                Swal.fire({
                    title: 'Xatolik yuz berdi',
                    text: err.response.data.username,
                    icon: 'error'
                })
                reject(err)
            })
    })
}

function countragetContractUpdate({ dispatch }, payload) {
    axios
        .put('counteragents/contracts/' + payload.id + '/', payload.data)
        .then(() => {
            dispatch('getKontragentsContractsList', payload.pageIndex)
            Swal.fire({
                title: '',
                text: "Ma'lumotlar yuborildi!",
                icon: 'success'
            })
        })
        .catch(err => console.log(err))
}

// Create zayavka
function createZayavka(_, data) {
    return (
        axios
            // .post('zayavkas/list/', data)
            .post('zayavkas/create/', data)
            .then(() => {
                // await dispatch('getAllZayavka')
                router.push('/allZayavka')
                Swal.fire({
                    title: "Ma'lumotlar yuborildi!",
                    text: "Ma'lumotlar yuborildi!",
                    icon: 'success'
                })
            })
            .catch(err => {
                const non_field_errors = (
                    (err.response.data || {}).non_field_errors || []
                ).join('\n')

                console.error(err)
                console.error(non_field_errors)
                Swal.fire({
                    title: "Ma'lumotlar yuborishda xatolik!",
                    text: `${non_field_errors}`,
                    icon: 'error'
                })
            })
    )
}

function linkWithBalansAccount({ dispatch }) {
    axios
        .put('warehouse/main_assets/dailyamortizations/link_with_balansaccount/')
        .then(() => {
            dispatch('bankAccountLink', false)
            Swal.fire({
                title: '',
                text: "Ma'lumotlar yuborildi!",
                icon: 'success'
            })
        })
        .catch(err => console.log(err))
}

// Create brand
function createBrand({ dispatch }, data) {
    return new Promise((resolve, reject) => {
        axios
            .post('general/truckbrands/', data)
            .then(() => {
                dispatch('getAllTruckBrands')
                router.push('allbrands')
                Swal.fire({
                    title: '',
                    text: "Ma'lumotlar yuborildi!",
                    icon: 'success'
                })
                resolve()
            })
            .catch(err => {
                console.log(err)
                reject(err)
            })
    })
}

function contractFactures({ dispatch, commit }, data) {
    commit('setIsLoading', true)
    // console.log(data)
    return axios
        .post('contracts/clientcontracts/factures/', data)
        .then(async res => {
            console.log(res)
            await dispatch('getCurrCompJob', data.contract)
            dispatch('factureBycontract', data.contract)
            commit('setIsLoading', false)
            success()
        })
        .catch(err => {
            console.log(err)
            commit('setIsLoading', false)
        })
}

// create Con Card
function createConCard({ dispatch }, data) {
    return new Promise((resolve, reject) => {
        axios
            .post('finance/corpcards/', data)
            .then(() => {
                dispatch('getAllCorCards')
                router.push('corcards')
                success()
                resolve()
            })
            .catch(err => {
                console.log(err)
                reject(err)
            })
    })
}

function createPaymentStatements({ dispatch }, data) {
    return new Promise((resolve, reject) => {
        axios
            .post('staff/salary/paymentstatements/list/', data)
            .then(() => {
                dispatch('getAllCorCards')
                router.push('/payment_statements')
                success()
                resolve()
            })
            .catch(err => {
                console.log(err)
                reject(err)
            })
    })
}

async function getPaymentStatements({ commit }, payload) {
    await axios
        .get('staff/salary/paymentstatements/list/', {
            params: {
                page: payload
            }
        })
        .then(res => {
            commit('setPaymentStatements', res.data)
        })
        .catch(err => console.log(err))
}

// update corp card
function updateCorpCard({ dispatch }, payload) {
    axios
        .put('finance/corpcards/' + payload.id.id + '/', payload.data)
        .then(() => {
            dispatch('getAllCorCards')
            router.push('/corcards')
            Swal.fire({
                title: '',
                text: "Ma'lumotlar yuborildi!",
                icon: 'success'
            })
        })
        .catch(err => console.log(err))
}

function updateCorpCardAction({ dispatch }, payload) {
    axios
        .put('finance/corpcards/' + payload.id.id + '/', payload.data)
        .then(() => {
            console.log('payload = ',payload.id.id)
            dispatch('getAllCorCards')
            Swal.fire({
                title: '',
                text: "Ma'lumotlar yuborildi!",
                icon: 'success'
            })
        })
        .catch(err => console.log(err))
}

function addToMainAssets({ dispatch }, payload) {
    axios
        .put('trucks/list/' + payload.id + '/', payload.data)
        .then(() => {
            dispatch('getTruckLists')
            // dispatch("getAllCorCards");
            Swal.fire({
                title: '',
                text: "Ma'lumotlar yuborildi!",
                icon: 'success'
            })
        })
        .catch(err => console.log(err))
}

// delete corp card
function deleteCorpCard({ dispatch }, payload) {
    axios
        .delete('finance/corpcards/' + payload)
        .then(() => {
            dispatch('getAllCorCards')
        })
        .catch(err => {
            if (err.response.status == 301) {
                dispatch('getAllCorCards')
                Swal.fire({
                    title: '',
                    text: "Ma'lumotlar o'chirildi!",
                    icon: 'success'
                })
            }
        })
}

// Create driver
function createDriver({ dispatch }, data) {
    axios
        .post('drivers/list/', data)
        .then(() => {
            success()
            router.go(-1)
            dispatch('getAllDrivers')
        })
        .catch(err => console.log(err))
}

// Create truck
function createTruck({ dispatch }, data) {
    return new Promise((resovle, reject) => {
        axios
            .post('trucks/list/', data)
            .then(() => {
                dispatch('getTruckLists')
                router.push('trucklists')
                Swal.fire({
                    title: '',
                    text: "Ma'lumotlar yuborildi!",
                    icon: 'success'
                })
                resovle()
            })
            .catch(err => {
                console.log(err)
                reject(err)
            })
    })
}

// update truck
function updateTruck({ dispatch }, data) {
    axios
        .put('trucks/list/' + data.id.id + '/', data.data)
        .then(() => {
            dispatch('getTruckLists')
            router.push('/trucklists')
            success()
        })
        .catch(err => console.log(err))
}

// Create contract appen
function createContractAppen({ commit }, data) {
    commit('setIsLoading', true)
    return new Promise((resolve, reject) => {
        axios
            .post('contracts/contract/appendix/', data)
            .then(() => {
                commit('setIsLoading', false)
                router.go(-1)
                Swal.fire({
                    title: '',
                    text: "Ma'lumotlar yuborildi!",
                    icon: 'success'
                })
                resolve()
            })
            .catch(err => {
                commit('setIsLoading', false)
                console.log(err)
                reject()
            })
    })
}

function getStandartContAppen({ commit }, payload) {
    commit('setIsLoading', true)
    axios
        .get('contracts/contract/appendix/' + payload + '/')
        .then(res => {
            commit('setIsLoading', false)
            commit('setStandartContAppen', res.data)
        })
        .catch(err => console.log(err))
}

// Create provider
function createProvider({ dispatch }, data) {
    return axios
        .post('providers/create/', data)
        .then(() => {
            dispatch('getAllProviders')
            router.push('allproviders')
            Swal.fire({
                title: '',
                text: "Ma'lumotlar yuborildi!",
                icon: 'success'
            })
        })
        .catch(err => {
            console.log(err)
        })
}

function createProviderContract({ dispatch }, data) {
    return new Promise((resolve, reject) => {
        axios
            .post('contracts/providercontracts/', data)
            .then(() => {
                dispatch('getAllProvidersContract')
                router.push('/providerscontract')
                Swal.fire({
                    title: '',
                    text: "Ma'lumotlar yuborildi!",
                    icon: 'success'
                })
                resolve()
            })
            .catch(err => {
                console.log(err)
                reject(err)
            })
    })
}

function UpdateProviderContract({ dispatch }, payload) {
    axios
        .put('contracts/providercontracts/' + payload.id + '/', payload.data1)
        .then(() => {
            dispatch('getAllProvidersContract')
            router.push('/providerscontract')
            Swal.fire({
                title: '',
                text: "Ma'lumotlar yuborildi!",
                icon: 'success'
            })
        })
        .catch(err => console.log(err))
}

// update zayavka
function confirmZayavkaStatus({ dispatch }, payload) {
    axios
        .put('zayavkas/zayavka/partialupdate/' + payload.id + '/', payload.data1)
        .then(() => {
            dispatch('getAllZayavkaPage', payload.page)
            Swal.fire({
                title: '',
                text: "Ma'lumotlar yuborildi!",
                icon: 'success'
            })
        })
        .catch(err => console.log(err))
}

// Delete provider contract
function deleteProviderContract({ dispatch }, data) {
    axios
        .delete('contracts/providercontracts/' + data + '/')
        .then(() => {
            dispatch('getAllProvidersContract')
            Swal.fire({
                title: '',
                text: "Ma'lumotlar yuborildi!",
                icon: 'success'
            })
        })
        .catch(err => {
            if (err.response.status == 301) {
                dispatch('getAllProvidersContract')
                Swal.fire({
                    title: '',
                    text: "Ma'lumotlar o'chirildi!",
                    icon: 'success'
                })
            }
        })
}

// delete faktura
function deleteFaktura({ dispatch }, factureId) {
    return axios
        .delete('contracts/clientcontracts/factures/delete/' + factureId + '/')
        .then(() => {
            dispatch('getAllFacturas')
        })
        .catch(err => {
            if (err.response.status == 301) {
                dispatch('getAllFacturas')
            }
        })
}

//deleteProductsCash
function deleteProductsCash({ dispatch }, productCashId) {
    return axios
        .delete('warehouse/goodsservices/delete/' + productCashId + '/')
        .then(() => {
            dispatch('allSkladList')
        })
        .catch(err => {
            if (err.response.status == 301) {
                dispatch('allSkladList')
            }
        })
}

function deleteProductsCard({ dispatch }, productCardId) {
    return axios
        .delete('warehouse/goodsservices/delete/' + productCardId + '/')
        .then(() => {
            dispatch('allSkladList')
        })
        .catch(err => {
            if (err.response.status == 301) {
                dispatch('allSkladList')
            }
        })
}

function deleteProductsBank({ dispatch }, productBankId) {
    return axios
        .delete('warehouse/goodsservices/delete/' + productBankId + '/')
        .then(() => {
            dispatch('allSkladList')
        })
        .catch(err => {
            if (err.response.status == 301) {
                dispatch('allSkladList')
            }
        })
}

// Update
function updateZayavkaStatus({ dispatch }, data) {
    return axios
        .put('zayavkas/zayavka/partialupdate/' + data.id + '/', data.data)
        .then(() => {
            dispatch('getAllZayavkaPage', data.page)
            Swal.fire({
                title: '',
                text: "Ma'lumotlar yuborildi!",
                icon: 'success'
            })
        })
        .catch(err => console.log(err))
}

// eslint-disable-next-line no-unused-vars
function no_reload_updateZayavkaStatusLocally({ commit }, data) {
    return axios
        .put('zayavkas/zayavka/partialupdate/' + data.id + '/', data.data)
        .then(() => {
            commit('setZayavkaItem', data)
            Swal.fire({
                title: '',
                text: "Ma'lumotlar yuborildi!",
                icon: 'success'
            })
        })
        .catch(err => console.log(err))
}

// ----
// corp card details
async function getallCorpCardDetail({ commit }, payload) {
    await axios
        .get('finance/corpcards/operations/' + payload)
        .then(res => {
            commit('setallCorpCardDetal', res.data)
        })
        .catch(err => console.log(err))
}

async function getAlltransactionsDaily({ commit }, payload) {
    await axios
        .get('finance/transactions_daily', {
            params: {
                payment_source: payload.payment_source
            }
        })
        .then(res => {
            commit('setAlltransactionsDaily', res.data)
        })
        .catch(err => console.log(err))
}

async function TransactionsDailyDetails({ commit }, payload) {
    await axios
        .get('finance/transactions_daily/details/', {
            params: {
                payment_source: payload.payment_source,
                oper_date: payload.oper_date,
                page: payload.page
            }
        })
        .then(res => {
            commit('setTransactionsDailyDetails', res.data)
        })
        .catch(err => console.log(err))
}

// get operations by id
async function getCorpCardsOper({ commit }, payload) {
    await axios
        .get('finance/corpcards/operations/' + payload)
        .then(res => {
            commit('setCorpCardsOper', res.data)
        })
        .catch(err => console.log(err))
}

async function findSellMainAsset({ commit }, payload) {
    await axios
        .get('warehouse/main_assets/by_warehouse/' + payload + '/')
        .then(res => {
            commit('setFindSellMainAsset', res.data)
        })
        .catch(err => console.log(err))
}

// get corp cards oper
async function getCorpCardsOperList({ commit }) {
    await axios
        .get('finance/corpcards/operations/')
        .then(res => {
            commit('setCorpCardsOperList', res.data)
        })
        .catch(err => console.log(err))
}

// create Con Card Oper
function createConCardOper({ dispatch }, data) {
    return new Promise((resolve, reject) => {
        axios
            .post('finance/corpcards/operations/', data)
            .then(() => {
                dispatch('getAllCorCards')
                dispatch('getOperByCard', data.card)
                dispatch('getCorpCardsDetails', data.card)
                // router.push("/corcards");
                Swal.fire({
                    title: '',
                    text: "Ma'lumotlar yuborildi!",
                    icon: 'success'
                })
                resolve()
            })
            .catch(err => {
                console.log(err)
                reject(err)
            })
    })
}

//  create transaction
function postTransferData({ dispatch, commit }, data) {
    const NewData = {}
    NewData.transfer = data.transfer
    NewData.contract = data.contract
    commit('setIsLoading', true)
    return new Promise((resolve, reject) => {
        axios
            .post('finance/transfers/list/', NewData)

            .then(() => {
                // console.log(res)
                commit('setIsLoading', false)
                if (data.data.payment_source == '02') {
                    const data = {
                        payment_source: '02',
                        page: 1
                    }
                    dispatch('getAllTransferListPagCash', data)
                } else if (data.data.payment_source == '01') {
                    const data = {
                        payment_source: '01',
                        page: 1
                    }
                    dispatch('getAllTransferListPagBank', data)
                }
                success()
                resolve()
            })
            .catch(err => {
                console.log(err)
                commit('setIsLoading', false)
                reject(err)
            })
    })
}

// update transfer
function transferEdit({ dispatch }, payload) {
    axios
        .put('finance/transfer/details/' + payload.id.id + '/', payload.data)
        .then(() => {
            dispatch('getAllTransferList', '01')
            Swal.fire({
                title: '',
                text: "Ma'lumotlar yuborildi!",
                icon: 'success'
            })
            router.push('/vipiska')
        })
        .catch(err => console.log(err))
}

// create transaction full
function transactionData({ commit }, data) {
    commit('setIsLoading', true)
    axios
        .post('finance/corpcards/operations/', data)
        .then(res => {
            console.log(res)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

function contractData({ commit }, data) {
    axios
        .post('contracts/clientcontracts/operations/', data)
        .then(res => {
            console.log(res)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

// create akt
function createAkt({ dispatch }, data) {
    return new Promise((resovle, reject) => {
        axios
            .post('contracts/clientcontracts/actsverka/list/', data)
            .then(() => {
                dispatch('getAllActRecont')
                Swal.fire({
                    title: "Ma'lumotlar yuborildi!",
                    text: "Ma'lumotlar yuborildi!",
                    icon: 'success'
                })
                resovle()
            })
            .catch(err => {
                console.log(err)
                reject(err)
            })
    })
}

function createMeasurements({ dispatch }, data) {
    return new Promise((resolve, reject) => {
        axios
            .post('warehouse/measurements/', data)

            .then(() => {
                dispatch('getMeasurements')
                Swal.fire({
                    title: '',
                    text: "Ma'lumotlar yuborildi!",
                    icon: 'success'
                })
                resolve()
            })
            .catch(err => {
                console.log(err)
                reject(err)
            })
    })
}

// measurements delete
function measurementDelete({ dispatch }, payload) {
    axios
        .delete('warehouse/measurements/' + payload + '/')
        .then(() => {
            Swal.fire({
                title: '',
                text: "Ma'lumotlar ochirildi!",
                icon: 'success'
            })
            dispatch('getMeasurements')
        })
        .catch(err => {
            if (err.response.status == 488) {
                Swal.fire({
                    title: '',
                    text: err.response.data.detail,
                    icon: 'error'
                })
            }
        })
}

// get nomenklatura
async function getNomenklatura({ commit }) {
    await axios
        .get('warehouse/import_category/')
        .then(res => {
            commit('setNomenklaturaAll', res.data)
        })
        .catch(err => console.log(err))
}

async function getInfoForDashboard({ commit }) {
    await axios
        .get('finance/incomes_outcomes/')
        .then(res => {
            commit('setInfoForDashboard', res.data)
        })
        .catch(err => console.log(err))
}

// get nomenklatura by group
async function getNomenklaturaByGroup({ commit }, payload) {
    await axios
        .get('warehouse/import_category/', {
            params: {
                group: payload
            }
        })
        .then(res => {
            commit('setNomenklatura', res.data)
        })
        .catch(err => console.log(err))
}

// get nomenklatura group
function getNomenklaturaGroup({ commit }) {
    return axios
        .get('warehouse/import_category_group/')
        .then(res => {
            commit('setNomenklaturaGroup', res.data)
        })
        .catch(err => console.log(err))
}

// create nomenklatura
async function createNomenklatura({ dispatch }, payload) {
    let response = await axios
        .post('warehouse/import_category/', payload.data)
        .catch(err => {
            console.log(err)
            return err
        })
    dispatch('getNomenklaturaByGroup', payload.id)
    success()
    return response
}

async function getProvodkaList({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('finance/balans/provodka/list/', {
            params: {
                page: payload
            }
        })
        .then(res => {
            commit('setProvodkaList', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

function createProvodkaList({ commit }, payload) {
    commit('setIsLoading', true)
    return new Promise((resolve, reject) => {
        axios
            .post('finance/balans/provodka/list/', payload)
            .then(() => {
                success()
                router.push('/provodka')
                commit('setIsLoading', false)
                resolve()
            })
            .catch(err => {
                console.log(err)
                reject()
            })
    })
}

async function getBalansAccountList({ commit }) {
    commit('setIsLoading', true)
    await axios
        .get('finance/balans_accounts/list/')
        .then(res => {
            commit('setBalansAccountLists', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

// delete nomenklatura
async function deleteNomenklatura({ dispatch }, payload) {
    await axios
        .delete('warehouse/import_category/' + payload.id)
        .then(() => {
            dispatch('getNomenklaturaByGroup', payload.tab)
            Swal.fire({
                title: '',
                text: "Ma'lumotlar o'chirildi!",
                icon: 'success'
            })
        })
        .catch(err => {
            if (err.response.status == 497) {
                Swal.fire({
                    title: '',
                    text: err.response.data.detail,
                    icon: 'error'
                })
            } else if (err.response.status == 499) {
                Swal.fire({
                    title: '',
                    text: err.response.data.detail,
                    icon: 'error'
                })
            } else if (err.response.status == 498) {
                Swal.fire({
                    title: '',
                    text: err.response.data.detail,
                    icon: 'error'
                })
            }
        })
}

// update nomenklatura
async function updateNomenklatura({ dispatch }, payload) {
    await axios
        .put('warehouse/import_category/' + payload.data.id + '/', payload.data)
        .then(() => {
            dispatch('getNomenklaturaByGroup', payload.tabid.id)
            Swal.fire({
                title: '',
                text: "Ma'lumotlar yuborildi!",
                icon: 'success'
            })
        })
        .catch(err => console.log(err))
}

// get licenses
async function getLicenses({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('trucks/licenses/', {
            params: {
                page: payload
            }
        })
        .then(res => {
            commit('setLicenses', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

// delete truck
function deletTruck({ dispatch }, data) {
    axios
        .delete('trucks/list/' + data + '/')
        .then(() => {
            dispatch('getTruckLists')
            Swal.fire({
                title: '',
                text: "Ma'lumotlar ochirildi!",
                icon: 'success'
            })
        })
        .catch(err => console.log(err))
}

// get licenses by id
async function getLicensesById({ commit }, payload) {
    await axios
        .get('trucks/licenses/' + payload + '/')
        .then(res => {
            // dispatch("getTruckListById",res.data.truck);
            commit('setLicensesById', res.data)
        })
        .catch(err => console.log(err))
}

// delete licenses
async function deleteLicenses({ dispatch }, payload) {
    await axios
        .delete('trucks/licenses/' + payload.id + '/')
        .then(() => {
            Swal.fire({
                title: '',
                text: "Ma'lumotlar ochirildi!",
                icon: 'error'
            })
            dispatch('truckListall', payload.page)
            dispatch('getLicenses')
        })
        .catch(err => console.log(err))
}

function createLicenses({ commit, dispatch }, payload) {
    commit('setIsLoading', true)
    return new Promise((resolve, reject) => {
        axios
            .post('trucks/licenses/', payload)
            .then(() => {
                commit('setIsLoading', false)
                dispatch('getLicenses')
                Swal.fire({
                    title: '',
                    text: "Ma'lumotlar yuborildi!",
                    icon: 'success'
                })
                router.go(-1)
                resolve()
            })
            .catch(err => {
                reject(err)
                console.log(err)
            })
    })
}

// update licenses
function updateLicenses({ dispatch }, payload) {
    axios
        .put('trucks/licenses/' + payload.id.id + '/', payload.data)
        .then(() => {
            dispatch('getLicenses')
            Swal.fire({
                title: '',
                text: "Ma'lumotlar yuborildi!",
                icon: 'success'
            })
            router.go(-1)
        })
        .catch(err => console.log(err))
}

// get trucks drive permits
async function getDriverPermits({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('trucks/drive_permits/', {
            params: {
                page: payload
            }
        })
        .then(res => {
            commit('setIsLoading', false)
            commit('setDriverPermits', res.data)
        })
        .catch(err => console.log(err))
}

async function getFacturaDisplay({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .get('contracts/clientcontracts/factures_display/' + payload + '/')
        .then(res => {
            commit('setIsLoading', false)
            commit('setFacturaDisplay', res.data)
        })
        .catch(err => console.log(err))
}

// get drive permit details
async function getDriverPermitsDetails({ commit }, payload) {
    const response = await axios
        .get('trucks/drive_permits/' + payload + '/')
        .then(res => {
            commit('setDriverPermitsDetails', res.data)
            return true
        })
        .catch(err => console.log(err))
    return response
}

// create drive permit
function createDrive({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
        axios
            .post('trucks/drive_permits/', payload.data)
            .then(() => {
                dispatch('getDriverPermits', payload.page)
                router.go(-1)
                Swal.fire({
                    title: '',
                    text: "Ma'lumotlar yuborildi!",
                    icon: 'success'
                })
                resolve()
            })
            .catch(err => {
                console.log(err)
                reject(err)
            })
    })
}

function deleteDriverPermits({ dispatch, commit }, payload) {
    commit('setIsLoading', true)
    axios
        .delete('trucks/drive_permits/' + payload.id + '/')
        .then(() => {
            Swal.fire({
                title: '',
                text: "Ma'lumotlar o'chirildi!",
                icon: 'success'
            })
            dispatch('truckListall', payload.page)
            dispatch('getDriverPermits', payload.page)
        })
        .catch(err => console.log(err))
}

function updateDriverPermits({ commit }, payload) {
    commit('setIsLoading', true)
    axios
        .put('trucks/drive_permits/' + payload.id + '/', payload.data)
        .then(() => {
            router.go(-1)
            success()
            commit('setIsLoading', false)
        })
        .catch(err => console.log(err))
}

async function getAllMfoBank({ commit }) {
    // commit('setIsLoading', true)
    await axios
        .get('finance/banks/')
        .then(res => {
            if (res) {
                commit('setAllMfoBank', res.data)
                commit('setIsLoading', false)
            }
        })
        .catch(err => {
            console.log(err)
            commit('setIsLoading', false)
        })
}

function getMfoBankByNumber({ commit }, mfoCode) {
    // console.log('mfoCode', mfoCode)
    commit('setIsLoading', true)
    return axios
        .post('finance/banks/search/bymfo/', {
            code__icontains: mfoCode
        })
        .then(res => {
            commit('setAllMfoBank', (res && res.data) || [])
            commit('setIsLoading', false)
        })
        .catch(() => {
            commit('setIsLoading', false)
        })
}

function test_uchun() {
    // commit('setIsLoading', true)
    const auth = getToken()
    // axios.defaults.headers.common['Authorization']
    // console.log('auth', auth)

    if (!auth) return

    const BASE_URL = process.env.VUE_APP_BASE_URL || ''
    // console.log('BASE_URL', BASE_URL)
// gitdagi .env file ni korgan joyizga kirib berolismi .env ni ozimi? ha gitda shu repositoriyadan
    return axios({
        method: 'get',
        url: BASE_URL.slice(0, -8) + '/test_uchun',
        headers: { Authorization: `Bearer ${auth}` }
    })
}

test_uchun()

/// Adham's adds
async function corpCardSearch({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .post('warehouse/goodsservices/search-by-field/?page=' + payload.page, {
            ...payload.payload,
            payment_source: '3'
        })
        .then(res => {
            commit('setProductsList', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => {
            commit('setIsLoading', false)
            console.log(err)
        })
}

async function cashSearch({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .post('warehouse/goodsservices/search-by-field/?page=' + payload.page, {
            ...payload.payload,
            payment_source: '2'
        })
        .then(res => {
            commit('setServiceCash', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => {
            commit('setIsLoading', false)
            console.log(err)
        })
}

async function byBankSearch({ commit }, payload) {
    commit('setIsLoading', true)
    console.log('aaa: ', payload)
    await axios
        .post('warehouse/goodsservices/search-by-field/?page=' + payload.page, {
            ...payload.payload,
            payment_source: '1'
        })
        .then(res => {
            commit('setProductsList', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => {
            commit('setIsLoading', false)
            console.log(err)
        })
}

async function writeOffSearch({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .post(
            'warehouse/goodsservices/search-by-field/?page=' + payload.page,
            payload.payload
        )
        .then(res => {
            commit('setGoodsServicesImport', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => {
            commit('setIsLoading', false)
            console.log(err)
        })
}

async function transferSearch({ commit }, payload) {
    commit('setIsLoading', true)
    await axios
        .post(
            'warehouse/goodsservices/search-by-field/?page=' + payload.page,
            payload.payload
        )
        .then(res => {
            commit('setGoodsServicesImport', res.data)
            commit('setIsLoading', false)
        })
        .catch(err => {
            commit('setIsLoading', false)
            console.log(err)
        })
}

// async function updateProviderContractOperationStartDateFilter({commit}, payload) {
//     commit()
// }

export default {
    /// adham's adds
    corpCardSearch,
    cashSearch,
    byBankSearch,
    writeOffSearch,
    transferSearch,
    /// adham's add end
    searchKontragentsList_2,
    kontragentsContractsSearch,
    getStartingPoint,
    getCompletedWorks,
    newupdateTruck,
    deleteBrand,
    updateBrand,
    getCompanyGeneralInfo,
    createExpenditure,
    getFinanceExpenditureList,
    updateSklad,
    getIncomeDashboard,
    updateinancePlanIncomeYearly,
    createinancePlanIncomeYearly,
    getFinancePlanIncomeYearly,
    getTopClientDashboard,
    getInfoDriverDashboard,
    bankAccountLink,
    getAllAppen,
    ownerDelete,
    getDetailsSalaryPaymentStatements,
    getMainProfitSubCategorySpecialTotal,
    getInfoForDashboard,
    getAllMfoBank,
    getMfoBankByNumber,
    test_uchun,
    getMainProfitSorted,
    deleteProductsCard,
    deleteProductsBank,
    upadateSubCategoryProfit,
    getMainProfitSubCategory,
    createSubCategoryProfit,
    updateCategoryProfit,
    deleteCategoryProfit,
    createCategoryProfit,
    getMainProfitCategory,
    deleteMainProfit,
    updateMainProfit,
    createMainProfit,
    getAllDriversRevenuesFromToDate,
    personalUpdate,
    getMainProfit,
    ownerCreate,
    getAllOwner,
    illnessesUpdate,
    createStaffIllnesses,
    getAllStaffIllnesses,
    createStaffVacations,
    vacationUpdate,
    staffVacations,
    getRegionByOblast,
    createAllStaff,
    getPaymentStatements,
    getFinanceBalance,
    getAllNonEmployedStaff,
    getAllStaff,
    serivesPaymentSource,
    personalCreate,
    getOblast,
    getAllPersonal,
    staffPositionUpdate,
    staffPositionCreate,
    getPayrolList,
    staffPositions,
    staffDepartmentsUpdate,
    staffDepartmentsCreate,
    staffDepartments,
    CounteragentsContractAktSverkaPag,
    providerContractAktSverkaPag,
    createDailyAmortizationUnCalc,
    getProviderContractPayment,
    ProviderContractAktSverkaCreate,
    providerContractAktSverka,
    getGasEquipsDetails,
    providerContractAktSverkaID,
    providerContractDeliveredZayavkas,
    updateClientContAppen,
    KontragentContractAktSverkaCreate,
    kontragentContractAktSverkaID,
    kontragentContractAktSverka,
    countragentKontractsSverkaDate,
    countragentKontractsSverka,
    countragentKontractsPayments,
    countragentKontractFacturas,
    UpdateCountragentKontracts,
    deleteIlova,
    providerSearch,
    providerContractZayavka,
    getProviderContractById,
    deteleNomenklaturaGroup,
    updateNomenklaturaGroup,
    nomenklaturaGroup,
    createNomenklaturaGroup,
    updateMeasurements,
    updateTruck,
    deletTruck,
    truckListall,
    getstafflistall,
    measurementDelete,
    createPaymentStatements,
    getLicenceTypes,
    getmainassets,
    ownerUpdate,
    getAdvertPermitsDetails,
    getmainassetsDetails,
    mainAssetsDocPag,
    getAllDriversById,
    mainAssets,
    updateLicenses,
    deleteLicenses,
    getReAmor,
    createAkt,
    updateNomenklatura,
    getDriverPermitsDetails,
    getStaffEmployedListForMonth,
    changeTruckStatus,
    createLicenses,
    getDriverPermits,
    updateDriverPermits,
    createDrive,
    deleteNomenklatura,
    updateTechMonitor,
    getLicenses,
    createNomenklatura,
    getTotalSum,
    mainAssetsRemarksCreate,
    getNomenklatura,
    getNomenklaturaByGroup,
    getAllActRecontDetails,
    createMeasurements,
    linkWithBalansAccount,
    getMeasurements,
    searchKontragentsList,
    getCountragentKontractFacturas,
    getTechMonitor,
    getGeneralPaymentTypes,
    getMainExSubCategorySpecialTotal,
    getAllTransferListPagCash,
    mainAssetsRemarksDelete,
    mainAssetsMainDocDetails,
    getZayavkaDrivers,
    createCountragentStartingPoint,
    createProviderStartingPoint,
    getAllActRecontPag,
    getLicensesById,
    getNomenklaturaGroup,
    getAllActRecont,
    mainAssetsAdd,
    mainAssetsMainPart,
    transferEdit,
    // getClientPerPage,
    createTechMonitor,
    mainAssetsRemarks,
    getInsuarences,
    removeMainAssetsDocElement,
    tillDateList,
    getCountragentStartingPoint,
    getProviderStartingPoint,
    getIncomeList,
    getTransferDetails,
    getCategoryGroupNames,
    updateIncomeList,
    createProvodkaList,
    getProductCondition,
    getAllTransferListPagBank,
    deleteAdvertPermits,
    contractData,
    mainAssetsTaken,
    transactionData,
    getTechMonitorDetails,
    getInsuranceTruckDetails,
    getKontragentsContractsList,
    getTypeOfAction,
    deleteGasEquips,
    vipiskaFilterCashPerPage,
    mainAssetsDelete,
    getProductsList3,
    getDailyAmortizationMainAssets,
    getAllClientsName,
    getAllTransferList,
    createGasEquips,
    mainAssetsRemarksUpdate,
    updatePaymentStatemnts,
    getAllCities,
    getAllContractsPag,
    postTransferData,
    findSellMainAsset,
    getGoodsServicesImport,
    deleteDateElement,
    createIncome,
    getZaById,
    createStaffEmployedList,
    getBalansAccountList,
    getTruckListById,
    mainAssetTakenUpdate,
    updateZayavkaStatus,
    no_reload_updateZayavkaStatusLocally,
    getAllTnn,
    updateCountragentStartingPoint,
    getMainAssetRealizationFacturas,
    getTnnById,
    getPaymentSource,
    getAllMainExp,
    getDailyAmortizationUnCalc,
    contractsSearchPerPages,
    deleteTechMonitor,
    getAllDriversRevenues,
    createContractAppen,
    getIncomesFromService,
    getClientContract,
    getIncomeListDetails,
    createConCard,
    confirmZayavkaStatus,
    mainExpenditures,
    CreateTruckTypes,
    mainAssetsMainDocPart,
    getCorpCardsDetails,
    createGoodsservices2,
    getAllProvidersContract,
    getZayavkaDetails,
    getClientDetails,
    updategetInsuarences,
    getTruckLists,
    getAllUssers,
    createGoodsservices3,
    getAllBrands,
    deleteZayavka,
    counteragentContracts,
    getCategoryGroupData,
    getCountragentKontractsPayments,
    payrolCreate,
    createBrand,
    countragetContractUpdate,
    clientsSearchPerPages,
    CreateContragentContract,
    notLinkedTotruck,
    getProviderDetails,
    createDriver,
    createAdvertPermits,
    getAllTruckTypes,
    getAllUsersPerPage,
    updateGasEquips,
    getStaffEmployedList,
    getProvidersContractById,
    createContract,
    updateCorpCardAction,
    getReArmor,
    contractsSearch,
    refreshToken,
    truckListsSearch,
    getAllSkladList,
    vipiskaFilter,
    createZayavka,
    deletegetInsuarences,
    deleteCorpCard,
    getMainProfitSubCategorySpecial,
    getStaffList,
    counteragentsSearch,
    createGoodsservicesExport,
    clientFilter,
    getTypeStateTransfers,
    mainAssetTakenDetails,
    getCorpCardDetails,
    createGoodsservicesExport2,
    createGoodsservicesExportOptional,
    createKontragent,
    createSklad,
    mainAssetsCreate,
    deleteDriverPermits,
    getNds,
    getRealisations,
    getMainAssetDetailsList,
    zayavkaSearchPerPages,
    getKontragentsList,
    createProvider,
    getAllProviders,
    UpdateProviderContract,
    getProductsList2,
    getProviderContractdetail,
    getAllProvidersPag,
    getKontragentsContractsListWithoutPg,
    zayavkaSearch,
    zayavkaSearchCustom,
    getAllCorCards,
    getDoneWorks,
    getAllAdditionalContracts,
    getAllDrivers,
    getExpenditureDashboard,
    getCurrCompJob,
    allFacturasFilter,
    getAllFacturas,
    createTruck,
    addToMainAssets,
    productDetails,
    getAllGroups,
    getAllTruckBrands,
    getallCorpCardDetail,
    getGasEquips,
    getAllFacturasPage,
    getCorpCardsOper,
    getCorpCardsOperList,
    getAllProvidersWithoutPg,
    getOneClientContract,
    expenditureIncomeTotal,
    createConCardOper,
    factureBycontract,
    getFacturaDeatil,
    getProvodkaList,
    updateStartingPoint,
    updateProviderStartingPoint,
    createClient,
    getPerPage,
    changeDashboardTruckListValue,
    getNameOfContract,
    getOwnership,
    creategetInsuarences,
    createProviderContract,
    getSkladList,
    getPayrolListDetails,
    getRegions,
    getServiceCash,
    createMainAssetRealizationFacturas,
    getDeliveredOutfacture,
    getFacturaDisplay,
    getTruckEmptyById,
    getPositions,
    clientSearch,
    getAdvertPermits,
    getClientStatus,
    getOperByCard,
    updateZayavkaDetails,
    allFacturasSearchPerPages,
    getDoneWorksWithPage,
    getStoredproductsForExport,
    getAllZayavkaPage,
    getAllZayavkaPageWithParams,
    updateClient,
    getCountragentKontracts,
    annualRemark,
    getProductsList,
    getAllContracts,
    createStartingPoint,
    createDateList,
    updateContract,
    getStandartContAppen,
    deleteStartingPoint,
    deleteProviderStartingPoint,
    contractPayments,
    deleteProviderContract,
    deleteFaktura,
    deleteProductsCash,
    contractFactures,
    getAlltransactionsDaily,
    updateAdvertPermits,
    getTransferSalaryList,
    getmainassetsNotTaken,
    createGoodsservices,
    deleteCountragentStartingPoint,
    updateClientContAppenDirection,
    addTruckToContractAppendixById,
    getDriverDetails,
    getPaymentOrderTypes,
    TransactionsDailyDetails,
    updateCorpCard,
    editGoodsService3,
    setZayavkaItem,
    updateProductDetails
}
