function materialRepostCommit(state, payload) {
  state.materialrepost = []
  payload.filter((element) => {
    for (let prop in element.remain_reports) {
      element.remain_reports[prop] =
        element.remain_reports[prop].toLocaleString('es-US')
    }
  })
  state.materialrepost = [...payload]
}
function storeListCommit(state, payload) {
  state.storeList = {}
  state.storeList = payload
}

function materialReport2Commit(state, payload) {
  state.storeList2 = {}
  state.storeList2 = payload
  for (let i = 0; i < state.storeList2.length; i++) {
    state.storeList2[i].index = i + 1
  }

  for (let prop in state.storeList2) {
    for (let prop2 in state.storeList2[prop].reports_final) {
      for (let prop3 in state.storeList2[prop].reports_final[prop2]) {
        state.storeList2[prop].reports_final[prop2][prop3] =
          state.storeList2[prop].reports_final[prop2][prop3].toLocaleString(
            'es-US'
          )
      }
    }
  }
}
export default {
  storeListCommit,
  materialRepostCommit,
  materialReport2Commit
}
