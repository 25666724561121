import ApiService from '@/core/services/api.service'
import JwtService from '@/core/services/jwt.service'
import axios from 'axios'
import Vue from 'vue'
// import router from '@/router'
// action types
export const VERIFY_AUTH = 'verifyAuth'
export const LOGIN = 'login'
export const LOGOUT = 'logout'
export const REGISTER = 'register'
export const UPDATE_USER = 'updateUser'

// mutation types
export const PURGE_AUTH = 'logOut'
export const SET_AUTH = 'setUser'
export const SET_ERROR = 'setError'
const state = {
    errors: null,
    user: {},
    staff_permission: {},
    isAuthenticated: !!JwtService.getToken()
}
const getters = {
    currentUser(state) {
        return state.user
    },
    isAuthenticated(state) {
        return state.isAuthenticated
    }
}
const actions = {
    [LOGIN](context, data) {
        return axios
            .post('token/', data)
            .then((res) => {
                context.commit(SET_AUTH, res.data)
                return res
                // router.push({ name: 'dashboard' })
            })
            .catch((err) => {
                const data = (err && err.response && err.response.data) || {}
                // console.log('LOGIN', data)
                context.commit(SET_ERROR, data.detail)
                return data
            })
    },
    [LOGOUT](context) {
        context.commit(PURGE_AUTH)
    },
    [REGISTER](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post('users', {user: credentials})
                .then(({data}) => {
                    context.commit(SET_AUTH, data)
                    resolve(data)
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data.errors)
                    reject(response)
                })
        })
    },
    [VERIFY_AUTH](context) {
        if (JwtService.getToken()) {
            state.isAuthenticated = true
            ApiService.setHeader()
            const userObj = localStorage.getItem('user')
            if (userObj) {
                // localStorage.getItem('user')
                context.commit(SET_AUTH, JSON.parse(userObj))
            }
        } else {
            context.commit(PURGE_AUTH)
        }
    },
    [UPDATE_USER](context, payload) {
        const {email, username, password, image, bio} = payload
        const user = {email, username, bio, image}
        if (password) {
            user.password = password
        }

        return ApiService.put('user', user).then(({data}) => {
            context.commit(SET_AUTH, data)
            return data
        })
    }
}

const mutations = {
    [SET_ERROR](state, error) {
        console.log('state.errors', error)
    },
    [SET_AUTH](state, user) {
        try {
            state.isAuthenticated = true
            if (!JwtService.getToken()) {
                JwtService.saveToken(user.access)
            }
            localStorage.setItem('user', JSON.stringify(user))
            state.user = user
            if (user.staff_permission) Vue.prototype.$user = user.staff_permission
            state.permissions = user
            state.errors = {}
        } catch (err) {
            console.error('SET_AUTH', err)
        }
    },
    [PURGE_AUTH](state) {
        state.isAuthenticated = false
        state.user = {}
        state.errors = {}
        JwtService.destroyToken()
        localStorage.removeItem('user')
    }
}

export default {
    state,
    actions,
    mutations,
    getters
}
