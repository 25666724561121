import Vue from 'vue'
import VueI18n from 'vue-i18n'

// Localisation language list
import { locale as uz } from '@/core/config/i18n/uz'
// import { locale as ch } from '@/core/config/i18n/ch'
// import { locale as uz } from '@/core/config/i18n/uz'
// import { locale as es } from '@/core/config/i18n/es'
import { locale as ru } from '@/core/config/i18n/ru'
// import { locale as de } from '@/core/config/i18n/de'
// import { locale as fr } from '@/core/config/i18n/fr'

Vue.use(VueI18n)

let messages = {}
messages = { ...messages, uz, ru }

// get current selected language
// const arr = ["en", "ch"];
var lang = localStorage.getItem('language') || 'uz'

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: lang, // set locale
  messages // set locale messages
})
export default i18n
