// Russian
export const locale = {
  ACTION: {
    DETAILS: 'Детали',
    DELETE: 'Удалить',
    UPDATE: 'Изменить',
    CHANGE_STATUS: 'Изменить статус'
  },
  BUTTONS: {
    ADD_TOILOVA: 'Добавить'
  },
  FORM: {
    SUBMIT: 'Сохранить',
    CANCEL: 'Закрыть'
  },
  INPUTS: {
    CLIENT_TYPE_JI: 'Физическое лицо',
    CLIENT_TYPE_YU: 'Юридическое лицо',
    CLIENT_TYPE: 'Тип клиента',
    LOGIN: 'Логин',
    PASSPORT: 'Пароль',
    REPASSPORT: 'Подтвердите пароль',
    JI_INFO: 'Данные физического лица',
    YU_INFO: 'Данные юридического лица',
    CLIENT_NAME: 'Имя клиента',
    STATUS: 'Статус',
    PASSPORT_NUMBER: 'Номер паспорта',
    OBLAST: 'Область',
    REGION: 'Регион',
    ADDRESS: 'Адрес',
    NDS_NUMBER: 'НДС',
    MFO: 'МФО',
    BANK_NUMBER: 'Номер банковского счета',
    STREET: 'Улица',
    DOP_ADDRESS: 'Дополнительный адрес',
    CLIENT_FORM: 'Форма собственности',
    FIO: 'ФИО',
    PHONE_NUMBER: 'Номер телефона',
    DIRECTOR: 'Директор',
    RESPONSIBLE_STAFF: 'Ответственный сотрудник',
    POSITION: 'Должность',
    BANK_INFO: 'Реквизиты банка',
    CONTRACTS_INFO: 'Контактные данные',
    PAYMENT_TYPE: 'Тип оплаты',
    CONTRACT_AMOUNT: 'Сумма договора',
    CONTRACT_ENDDATE: 'Срок годности',
    CITIES: 'Города',
    CITY: 'Город',
    SELECT_TRUCK_TYPE: 'Тип грузовика',
    SUPPLIER: 'Поставщик',
    SELECT_TRUCKTYPE: 'Выберите грузовик',
    MAIN_DRIVER: 'Главный водитель',
    MAIN_DRIVER_SECOND: 'Помощник водителя',
    KG_WEIGHT: 'Вес груза - Кг',
    m3_WEIGHT: 'Грузоподъемность - м3',
    SUPPLIER_POS: 'Поставщик: Другие',
    SUPPLIER_POS_NUM: 'Номер автомобиля поставщика',
    PROVIDER_CONTRACTS: 'Контракты поставщиков',
    DRIVER_NAME: 'Имя водителя',
    SHIPPING_DATE: 'Дата отгрузки',
    SHIPPING_DATETIME: 'Время',
    DELIVERY_DATE: 'Дата доставки',
    ORDER_REGISTRATION: 'Оформление заявки',
    REPORT_START: 'Начало отчета',
    REPORT_END: 'Завершение отчета'
  },
  AKT_SVERKA: {
    REPORT_DATE: 'Дата отчета',
    REPORT_DATE_START: 'Начало отчета',
    REPORT_DATE_END: 'Конец отчета',
    BTS_DEBET_SALDO_START: 'БТС дебет салдо начало',
    BTS_KREDIT_SALDO_START: 'БТС кредит салдо начало ',
    CLIENT_DEBET_SALDO_START: 'Клиент дебет салдо начало ',
    CLIENT_KREDIT_SALDO_START: 'Клиент кредит салдо начало  ',
    BTS_DEBET_SALDO_END: 'БТС дебет салдо конец',
    BTS_KREDIT_SALDO_END: 'БТС кредит салдо конец ',
    CLIENT_DEBET_SALDO_END: 'Клиент дебет салдо конец',
    CLIENT_KREDIT_SALDO_END: 'Клиент кредит салдо конец',
    CONTRACT_NUMBER: 'Номер контракта',
    CREATED: 'Дата создания'
  },
  TABLE_HEADER: {
    CLIENT: 'Клиент',
    INN: 'ИНН',
    OKED: 'ОКЭД',
    DATE: 'Дата',
    REGION: 'Регион',
    PROVINCE: 'Область',
    TYPE: 'Тип',
    STATUS: 'Статус',
    CONTRACT: 'Договор',
    CONTRACT_NUMBER: 'Номер договора',
    PROVIDER_CONTRACT_NUMBER: 'Номер договора(Поставщика)',
    REG_NUMBER: 'Регистрационный номер',
    START_DATE: 'Дата (бошланиш)',
    END_DATE: 'Дата(тугаш)',
    AMOUNT: 'Сумма',
    CATEGORY: 'Категория',
    SHAPE: 'Форма',
    NAME: 'Название',
    NUMBER: 'Номер',
    CREATE_DATE: 'Дата создания',
    UPDATE_DATE: 'Дата изменения',
    PROVIDER: 'Поставщик',
    PRICE: 'Стоимость',
    ALLZAYAVKA_PRICE_WITH_NDS: 'Стоимость BTS(с НДС)',
    ALLZAYAVKA_PRICE_WITH_NDS_POSTAVSHIK: 'Стоимость ПОС(с НДС)',
    TRUCK_NUMBER: 'Номер машины',
    LOCATION: 'Место расположения',
    DIRECTION: 'Направление',
    NDS: 'НДС',
    ITEM: 'Шт',
    PRICE_WITH_NDS: 'Цена с учетом НДС',
    CONTRAGENT: 'Контрагент',
    CON_CONTRAGENT: 'Договор контрагента',
    EXPENDITURE: 'Расход',
    NDSAMOUNT: 'Сумма (НДС)',
    NDS_AMOUNT: 'Сумма с учетом НДС',
    SALER: 'Продавец',
    CARD_NUMBER: 'Номер карты',
    OPERATION_TYPE: 'Тип операции',
    BRAND: 'Бренд',
    MODEL: 'Модель',
    BOUGHT_DATE: 'Дата покупки',
    MAIN_ASSETS: 'ОС',
    DOCS: 'Документ',
    PRICE_PER_KM_MAINDRIVER: 'Asosiy haydovchi uchun km--',
    PRICE_PER_KM_ASSISTANT: 'Yordamchi uchun km--',
    MONITORED_BY: 'Tekshiruvchi--',
    EXPIRES: 'Amal qilish sanasi--',
    MONITORED: 'Tekshiruv sanasi---',
    LICENCE_TYPE: 'Тип сертификата',
    DRIVER: 'Водитель',
    COMMENT: 'Комментарий',
    STORE: 'Склад',
    YEAR: 'Год',
    TOTAL: 'Общее',
    FIRSTNAME: 'Имя',
    SURNAME: 'Фамилия',
    LASTNAME: 'Отечество',
    PASSPORT_NUMBER: 'Номер паспорта',
    PHONE_NUMBER: 'Номер телефона',
    INSP: 'ИНПС',
    PINFL: 'ПИНФЛ',
    MAIL_ADDRESS: 'Адрес',
    STAFF_DEPARTMENT: 'Департамент',
    EMPLOYED_FROM: 'Дата приема на работу',
    TABEL_NUMBER: 'Номер табаля',
    STAFF_OKLAD: 'Оклад',
    STAFF_POSITION: 'Должность',
    STAFF_NAME_SURNAME: 'Имя фамилия',
    PAYMENT_STATUS: 'Статус оплаты',
    DELIVERED_ZAYAVKAS_AMOUNT: 'Количество завершенных зававок',
    DELIVERED_ZAYAVKAS_PREMIYE_TOTAL: 'Премия завершенных зававок',
    AMOUNT_EXPENDITURE: 'Затраты',
    EXPENDITURE_TYPE: 'Тип затрат',
    PLAN_YEAR: 'План выручки на',
    JURIDIK_ADDRESS: 'Адрес',
    CONTRACT_TYPE: 'Тип договора',
    CODE: 'Код',
    MEASUREMENTS_NAME: 'Название измерения',
    NOMENCLATURE_NAME: 'Номенклатура',
    RESPONSIBLE_NAME: 'Ответственное имя',
    MAIN_INCOME: 'Основной доход',
    MAIN_INCOME_CAT: 'Категория',
    MAIN_INCOME_SUBCAT: 'Субкатегория'
  },
  DASHBOARD: {
    TRUCKS: {
      FREE: 'Автомашин',
      ORD: '',
      SER: '',
      BRO: ''
    }
  },
  PAGES: {
    HI: 'Добро пожаловать',
    GOBACK: 'Назад',
    CHECK: 'Проверить',
    DATE: 'Дата'
  },
  CREATE: {
    CARD: 'Карта',
    TRUCK: 'Автомашина'
  },
  BREADCRUMBS: {
    TO_EXCEL: 'Excel',
    BANK: 'Банк',
    CASH: 'Касса',
    SOLD: 'Продажа',
    HOME: 'Главная',
    CLIENT: 'Клиент',
    SEARCH: 'Поиск',
    CLIENTS: 'Клиенты',
    CONTRACT: 'Договора',
    CLEAN: 'Очистить',
    CONTRACTS: 'Договора',
    ILOVA: 'Стандарт приложение',
    TTN: 'ТТН',
    ORDER: 'Заявка',
    ACT_SVERKA: 'Акт сверка',
    ORDERS: 'Заявки',
    CLIENT_REG: 'Регистрация нового клиента',
    DONE_WORKS: 'Выполненные работы (без фактуры)',
    CONTRACT_REG: 'Регистрация договора'
  },
  REG_FORMS: {
    PASSWORD: 'Пароль',
    REPEAT_PASS: 'Подтвердите пароль'
  },
  MENU: {
    HOME: 'Главная',
    SALE: {
      TITLE: 'Продажа',
      CLIENTS: 'Клиенты',
      CONTRACTS: 'Договора',
      ILOVA_APP: 'Стандарт приложение',
      TTN: 'ТТН',
      ORDERS: 'Заявки',
      COMPLETED_JOBS: 'Выполненные работы',
      COMPLETED_JOBS_WITHOUT_FK: 'Выполненные работы(без сч-ф.)',
      ACCOUNT_INVESTMENT: 'Счёт фактуры(реализация)',
      ACT_SVERKA: 'Акт сверки',
      DEBT: 'Задолженности'
    },
    PURCHASE: {
      TITLE: 'Покупка',
      RECEIPT_GOODS_SERVICES: {
        TITLE: 'Поступление товаров и услуг',
        BANK: 'ПТУ(б)',
        KASSA: 'ПТУ(к)',
        CARD: 'ПТУ(кк)'
      },
      TAKEN: 'Покупка ОС(не принято в ОС)',
      NOTTAKEN: 'Покупка ОС(принято в ОС)'
    },
    BANK_KASSA: {
      TITLE: 'Банк-касса',
      VIPISKA: 'Выписка',
      CARDS: {
        TITLE: 'Корпоративная карта',
        CARD: 'Корпоративные карты',
        CARD_OPER: 'Операции'
      }
    },
    MAIN_ASSETS: {
      TITLE: 'Основные средства',
      TRANSPORT: {
        TITLE: 'Транспорт',
        ALL: 'Автомашины',
        TYPES: 'Марки авто',
        BRAND: 'Бренды авто'
      },
      TRANSPORT_DOC: {
        TITLE: 'Документы автомашин',
        TEXNIK_KORISH: 'Техосмотр',
        GBO: 'Документы ГБО',
        ADVERTISIN_PERMISSION: 'Разрешение для рекламы',
        LICENSE: 'Лицензия',
        INSURANCE: 'Страхование',
        CERTIFICATE: 'Доверенность'
      },
      MAIN_ASSETS_TITLE: 'ОС',
      REVALUATION_AV: 'Переоценка ОС'
    },
    STORE: {
      TITLE: 'Склад',
      WRITE_OFF: 'Списание',
      TRANSFER: 'Трансфер',
      REALIZATION: 'Продажа',
      SALE_MAIN_ASSETS: 'Продажа ОС'
    },
    STAFF: {
      TITLE: 'Кадры',
      JS: 'Физические лица',
      STAFF: 'Сотрудники',
      VACATION: 'Отпуск',
      ILNESS: 'Больничный',
      DRIVERS: 'Водители'
    },
    SALARY: {
      TITLE: 'Зарплата',
      CALC: 'Расчетная ведомость',
      PAY: 'Платежная ведомость',
      DRIVER_PREM: 'Премия водителей'
    },
    REPORT: {
      TITLE: 'Отчеты',
      FINANCE_REPORT: {
        TITLE: 'Финансовые отчеты',
        FINANCE_RESULT: 'Финансовый результат',
        FINANCE_RESULT_FORM30: 'Финансовый результат(Форма 30)',
        BALANS: 'Баланс',
        LAST_MATERIALS: 'Материальный отчет',
        INCOMES: 'Доходы',
        EXPENDITURE: 'Расходы',
        INCOMES_EXPENDITURE: 'Доходы и расходы',
        PROVODKA: 'Проводки'
      },
      PLAN_VIEW: {
        TITLE: 'Плановые показатели',
        SERVICE_PLAN: 'План дохода'
      }
    },
    ENTERPRICE: {
      TITLE: 'Предприятие',
      CONTRAGENT: {
        TITLE: 'Контрагенты',
        LIST: 'Контрагенты',
        CONTRACTS: 'Контракты кортрагентов'
      },
      PROVIDERS: {
        TITLE: 'Поставщики',
        LIST: 'Поставщики',
        CONTRACTS: 'Контракты поставщиков'
      },
      STORE: {
        TITLE: 'Склад',
        PRODUCT_CONDITION: 'Состояние товаров',
        MEASUREMENTS: 'Единицы измерения',
        NOMENCLATURE: 'Номенклатура',
        NOMENCLATURE_GROUP: 'Номенклатурные группы'
      },
      EXPENDITURE: {
        TITLE: 'Расходы',
        MAIN_ASSETS: 'Основные расходы',
        CATEGORY: 'Категории расходов',
        SUBCATEGORY: 'Субкатегории расходов',
        GENERAL_INFO: 'Общие сведения',
        PAYMENT_TYPE: 'Типы оплаты'
      },
      INCOMES: {
        TITLE: 'Доходы',
        MAIN: 'Основные доходы',
        CATEGORY: 'Категории доходов',
        SUBCATEGORY: 'Субкатегории доходов',
        GENERAL_INFO: 'Общие сведения'
      },
      STAFF: {
        TITLE: 'Кадры',
        POSITION: 'Должности',
        DEPARTMENTS: 'Подразделение организации'
      },
      CLIENTS: {
        TITLE: 'Клиенты',
        FORM: 'Форма собственности'
      },
      STORES: {
        TITLE: 'Склады'
      }
    },
    DASHBOARD: 'Главная',
    CLIENTS: 'Отдел клиентов',
    ALLCLIENTS: 'Клиенты',
    CONTRACTS: 'Отдел договоров',
    ALLCONTRACTS: 'Договора',
    STAFFDEPARTMENT: 'Кадры',
    PERSONAL: 'Физические лица',
    STANDART_APP: 'Стандарт приложение',
    ALLSTAFF: 'Сотрудники',
    ALLVACATION: 'Отпуск',
    ALLILLNESS: 'Больничный',
    ORDERS: {
      TITLE: 'Заявки',
      ALLORDERS: 'Заявки',
      ALLTTN: 'ТТН',
      DONEWORKS_WITHOUT_FACTURE: 'Выполненные работы(без сч-ф.)'
    },
    ACCOUNT_INVESTMENT: {
      TITLE: 'Счёт фактуры(реализация)',
      ALLACCOUNT_IMVERSTMENT: 'Счёт фактуры(реализация)',
      AKT_SVERKA: 'Акт сверки',
      ALLDEBT: 'Задолженности',
      ALLDONEWORK: 'Выполненные работы'
    },
    DRIVERDEPARTMENT: {
      TITLE: 'Водители',
      ALLDRIVERS: 'Водители',
      DRIVERSPREMIYA: 'Премия водителей'
    },
    TRANSPORT: {
      TITLE: 'Транспорт',
      ALLTRUCKS: 'Автомашины',
      TRUCKTYPES: 'Марки авто',
      TRUCKBRAND: 'Бренды авто',
      TRUCKDOCUMENTS: {
        TITLE: 'Документы автомашин',
        TECHNICAL_INSPECTION: 'Техосмотр',
        GAS_EQUIPMENT_DOCUMENTATION: 'Документы ГБО',
        ADVERTISIN_PERMISSION: 'Разрешение для рекламы',
        LICENSE: 'Лицензия',
        INSURANCE: 'Страхование',
        CERTIFICATE: 'Доверенность'
      }
    },
    FINANCE: {
      TITLE: 'Финансы',
      TAKEN: 'Покупка ОС(принято в ОС)',
      NOTTAKEN: 'Покупка ОС(не принято в ОС)',
      MAIN_ASSETS: 'Основные средства'
    },
    WAREHOUSE: {
      TITLE: 'Склад',
      ALLWAREHOUSE: 'Список складов',
      KIRIM: {
        TITLE: 'Поступление товаров и услуг (кирим)',
        BANK: '',
        CONT: '',
        CORCARDS: ''
      },
      CHIQIM: {
        TITLE: 'Поступление товаров и услуг (чиким)',
        WRITE_OFF: '',
        TRANSFER: '',
        REALIZATION: ''
      }
    },
    PROVIDERS: {
      TITLE: 'Поставщики',
      LIST: 'Список поставщиков',
      CONTRACTS: 'Контракт поставщиков',
      ACT_SVERKA: 'Акт сферка'
    },
    COINTERPARTY: {
      TITLE: 'Контрагент',
      LIST: 'Список контрагентов',
      CONTRACTS: 'Список контрактов',
      ACT_SVERKA: 'Акт сверка'
    },
    // REPORT: {
    //   TITLE: 'Отчеты',
    //   WAREHOUSE: {
    //     TITLE: 'Склад',
    //     MATERIAL_REPORT_LAST: 'Конец материального отчета'
    //   }
    // },
    GENERAL_SECTION: {
      TITLE: 'Общий раздел',
      WAREHOUSE: {
        TITLE: 'Склад',
        PRODUCT_CONDITION: 'Состояние товаров',
        MEASUREMENTS: 'Измерения',
        NOMENCLATURE: 'Номенклатура',
        NOMENCLATURE_GROUP: 'Номенклатура группа'
      },
      FINANCE: {
        TITLE: 'Финансы',
        COST: {
          TITLE: 'Расход',
          MAINCOST: 'Основной расход',
          CATEGORY: 'Категория',
          SUBCATEGORY: 'Суб-категория',
          GENERAL_INFO: 'Основная информация'
        },
        INCOME: {
          TITLE: 'Доход',
          MAIN_INCOME: 'Основной доход',
          CATEGORY: 'Категория',
          SUBCATEGORY: 'Суб-категория',
          ALL_INCOME: 'Весь доход'
        }
      },
      STAFF: {
        TITLE: 'Сотрудники',
        POSITION: 'Должности сотрудников',
        DEPARTMENT: 'Отдел кадров'
      },
      CLIENTS: {
        TITLE: 'Клиент',
        FORM_PROPERTY: 'Форма имущества'
      }
    }
  }
}
