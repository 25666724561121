import axios from 'axios'

async function getReports({ commit }, p) {
  // console.log('getReports', new Date().getTime())
  commit('SET_LOADER', true)
  return axios
    .get('reports/report' + p)
    .then((res) => {
      // console.log(-1, 'getReports', res)
      commit('SET_LOADER', false)
      if (res && res.data) {
        commit('SET_REPORTS', res.data)
        commit('SET_TOTAL', res.data.length)
      }
      return (res && res.data) || {}
    })
    .catch((err) => {
      console.log(err)
      commit('SET_LOADER', false)
      return err
    })
}

function getReportByNumber(
  { commit },
  { reportNumber, extraUrl = '', params = {} }
) {
  commit('SET_LOADER', true)
  return axios
    .get('reports/report' + reportNumber + extraUrl, {
      params
    })
    .then((res) => {
      if (res) {
        commit('SET_LOADER', false)
        commit('SET_REPORTS', res.data)
        commit('SET_TOTAL', res.data.length || res.data.count)
      }
      return res.data || {}
    })
    .catch((err) => {
      console.log(err)
      commit('SET_LOADER', false)
      return err
    })
}

function report29({ commit }, { endpoint, params }) {
  commit('SET_LOADER', true)
  return axios
    .get(`reports/report29/${endpoint}/`, {
      params
    })
    .then((res) => {
      commit('SET_REPORTS', res.data)
      commit('SET_LOADER', false)
      return res
    })
    .catch(() => {
      commit('SET_LOADER', false)
    })
}

function report30({ commit }, { params }) {
  commit('SET_LOADER', true)
  return axios
    .get(`reports/report30/`, {
      params
    })
    .then((res) => {
      commit('SET_REPORTS', res.data)
      commit('SET_LOADER', false)
      return res
    })
    .catch(() => {
      commit('SET_LOADER', false)
    })
}

function report31({ commit }, { params }) {
  commit('SET_LOADER', true)
  return axios
    .get(`reports/report31/`, {
      params
    })
    .then((res) => {
      commit('SET_REPORTS', res.data)
      commit('SET_LOADER', false)
      return res
    })
    .catch(() => {
      commit('SET_LOADER', false)
    })
}

function report33({ commit }, { params }) {
  commit('SET_LOADER', true)
  return axios
    .get(`reports/report33/`, {
      params
    })
    .then((res) => {
      commit('SET_REPORTS', res.data)
      commit('SET_LOADER', false)
      return res
    })
    .catch(() => {
      commit('SET_LOADER', false)
    })
}

export default {
  getReports,
  getReportByNumber,
  report29,
  report30,
  report31,
  report33
}
