import mutations from './mutations'
import actions from './actions'

export default {
  state: {
    financial_datas: [],
    orders_weight_counts: [],
    empty_way_ratio: [],
    orders_efficiency: [],
    weight_efficiency: [],
    clients_debet_credit: [],
    providers_debet_credit: [],
    top_drivers: [],
    top_trucks: [],
    top_loaners: []
  },
  mutations,
  actions
}
