import axios from 'axios'

const actions = {
  fetchTrucks(_, params) {
    return axios
      .get('trucks/list/', {
        params
      })
      .then((res) => {
        return res && res.data
      })
  },

  forReport5(_, params) {
    return axios
      .get('trucks/list/for_report5/', {
        params
      })
      .then((res) => res && res.data)
      .catch((err) => {
        console.error(err)
        return {}
      })
  },

  odometrUpdate(_, { truckId, data }) {
    return axios.put(`trucks/odometr/update/${truckId}/`, data)
  }
}

export { actions }
